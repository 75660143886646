import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Button from '../../components/Button'
import { generateAPIPrompt } from '../../store/api'
import Loader from '../../components/Loader'
import { Container, Section, SectionFixed } from '../../components/Container'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import { isEmpty } from 'lodash'
import {
  ComponentPickerMenuItem,
  ComponentPickerOption,
  ResultBox,
  htmlToText
} from '../../components/Lexical/plugins/AiGeneratePlugin'
import TextIncreaseIcon from '@mui/icons-material/TextIncrease'
import CloseIcon from '@mui/icons-material/Close'
import CopyButton from '../../components/CopyButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../config/eventMapping'
import Draggable from 'react-draggable'
import mixpanelEvents from '../../config/mixpanelEvents'
import { addContentToChat } from '@/store/Chat/Actions'
import { formatChatContext, triggerChatSend } from '@/utils/Chat'

const defaultPrompt = {
  label: 'Summarizing',
  name: 'Summarize',
  keywords: [
    'summarize',
    'sum up',
    'abridge',
    'condense',
    'encapsulate',
    'outline'
  ],
  prompt: ['Generate an outline for this topic:\n\n%prompt%'],
  placeholderText: ['Summerize this text'],
  placeholder: 'Summerize this text',
  promptType: 'select',
  followUpPrompt: [],
  button_text: 'Generate',
  help_text: 'Generate'
}

const AIGeneratePlugin = (props) => {
  const common = useSelector((userState) => userState?.common)
  const dispatch = useDispatch()

  const { prompts = [] } = common
  const { aiPlugin = {}, setAIPlugin, domain_id } = props
  const [queryString, setQueryString] = useState(null)
  const [anchor, setAnchor] = useState({ top: 0, left: 0 })
  const [generateType, setGenerateType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputText, setInputText] = useState('')
  const [inputPrompt, setInputPrompt] = useState('')
  const [resultText, setResultText] = useState('')
  const [selectPromptSelected, setSelectPromptSelected] = useState(null)
  const [followUpsPrompts, setFollowUpsPrompts] = useState([])
  const [isMenu, setIsMenu] = useState(true)
  const [selectedText, setSelectedText] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleClose = () => {
    setIsMenu(true)
    aiPlugin?.props?.cancel()
    setAnchor(null)
    setGenerateType(null)
    setInputText('')
    setResultText('')
    setInputPrompt('')
    setFollowUpsPrompts([])
    setSelectPromptSelected(null)
    setQueryString('')
    setAIPlugin(null)
  }

  useEffect(() => {}, [inputText])

  useEffect(() => {
    if (aiPlugin) {
      const { e, props } = aiPlugin
      const { clientX = 0, clientY = 0 } = e || {}
      setAnchor({ top: clientY, left: clientX })
      props?.toggle()
      if (props?.selectedText) setSelectedText(props.selectedText)
    }
  }, [aiPlugin])

  const options = useMemo(() => {
    let optionsArray = prompts
    if (optionsArray?.length === 0) optionsArray = [defaultPrompt]
    let optionsList = []
    const selectOptions = _.filter(optionsArray, { promptType: 'select' })
    optionsList = [...selectOptions]
    let baseOptions = []
    baseOptions = [
      ...optionsList.map(
        (gen) =>
          new ComponentPickerOption(gen.name, {
            icon: <PostAddIcon />,
            keywords: gen.keywords,
            onSelect: () => {
              let randomPlaceHolder =
                gen?.placeholderText[
                  Math.floor(Math.random() * gen?.placeholderText.length)
                ]
              randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
              const newGen = { ...gen, placeholder: randomPlaceHolder }
              setGenerateType(newGen)
              return newGen
            }
          })
      )
    ]

    const defaultQueryStr = queryString || 'Write your own prompt'
    const defaultpromptStr = `${queryString || ''} %prompt%`
    const defaultKeyword = queryString || ''

    const listDisplayOrder = [
      'Create Bullet Points',
      'Create Title',
      'Make Longer',
      'Make Shorter',
      'Rewrite',
      'Simplify',
      'Summarize',
      'Harmonize',
      'Remove Line Breaks'
    ]

    const typeOrderMap = new Map()
    listDisplayOrder.forEach((key, index) => {
      typeOrderMap.set(key, index)
    })

    const hiddenList = ['Improve Content', 'Grammer Check']
    // Sort array1 based on the order in array2
    baseOptions.sort((a, b) => {
      const orderA = typeOrderMap.get(a.key)
      const orderB = typeOrderMap.get(b.key)
      return orderA - orderB
    })

    baseOptions = baseOptions.filter((obj) =>
      listDisplayOrder.includes(obj.key)
    )

    baseOptions = [
      ...baseOptions,
      new ComponentPickerOption(defaultQueryStr, {
        icon: <TextIncreaseIcon />,
        keywords: [defaultKeyword],
        onSelect: () => {
          let randomPlaceHolder =
            defaultPrompt?.placeholderText[
              Math.floor(Math.random() * defaultPrompt?.placeholderText.length)
            ]
          randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
          const newGen = {
            ...defaultPrompt,
            prompt: [defaultpromptStr],
            placeholder: randomPlaceHolder
          }
          setGenerateType(newGen)
          return newGen
        }
      })
    ]
    return queryString
      ? [
          ...baseOptions.filter((option) => {
            return new RegExp(queryString, 'gi').exec(option.title) ||
              option.keywords != null
              ? option.keywords.some((keyword) =>
                  new RegExp(queryString, 'gi').exec(keyword)
                )
              : false
          })
        ]
      : baseOptions
  }, [queryString, aiPlugin])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      const textContent = e.target.value
      e.preventDefault()
      if (textContent) {
        handleGenerate(textContent || '', generateType)
      } else {
        setInputText(generateType?.placeholder || '')
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault()
      setInputText((prevText) => prevText + '\n')
    }
  }

  const handleInputChanges = (input) => {
    setInputText(input)
  }

  const handleKeyDownListViewer = (e) => {
    if (e.key === 'ArrowDown') {
      let selected =
        selectPromptSelected === null ? 0 : selectPromptSelected + 1
      if (selected > options.length - 1) {
        selected = 0
      }
      setSelectPromptSelected(selected)
      scrollIntoViewIfNeeded(selected)
    } else if (e.key === 'ArrowUp') {
      const lastIndex = options.length - 1
      const selected =
        selectPromptSelected === null || selectPromptSelected === 0
          ? lastIndex
          : selectPromptSelected - 1
      setSelectPromptSelected(selected)
      scrollIntoViewIfNeeded(selected)
    } else if (e.key === 'Enter') {
      if (selectPromptSelected !== null && selectPromptSelected > -1) {
        const textSelected = selectedText || ''
        setInputText(textSelected)
        setIsMenu(false)
        const option = options[selectPromptSelected]
        const genSelected = option.onSelect(null)

        handleGenerate(
          textSelected,
          genSelected,
          option.title !== 'Write your own prompt'
        )
        handleClose()
      }
    }
  }

  const scrollIntoViewIfNeeded = (index) => {
    const target = document.getElementById('typeahead-item-' + index)
    const container = document.getElementById('typeahead-menu')
    if (container && target) {
      const containerRect = container.getBoundingClientRect()
      const targetRect = target.getBoundingClientRect()
      if (targetRect.bottom > containerRect.bottom) {
        target.scrollIntoView(false)
      } else if (targetRect.top < containerRect.top) {
        target.scrollIntoView()
      }
    }
  }

  const handleGenerate = async (
    inputText,
    generateType,
    autoTrigger = false
  ) => {
    if (inputText && generateType) {
      const prompt =
        generateType?.prompt[
          Math.floor(Math.random() * generateType?.prompt.length)
        ]
      const userprompt = prompt
        ?.replace('%prompt%', formatChatContext(inputText))
        .trim()

      dispatch(addContentToChat(userprompt))
      const element = document.activeElement
      element && element?.blur()

      if (autoTrigger) {
        setTimeout(() => {
          triggerChatSend()
        }, 200)
      }
    }
  }

  // const handleGenerate = async (inputText, generateType) => {
  //   if (inputText && generateType) {
  //     setLoading(true)
  //     const prompt =
  //       generateType?.prompt[
  //         Math.floor(Math.random() * generateType?.prompt.length)
  //       ]
  //     const userprompt = prompt?.replace('%prompt%', inputText).trim()
  //     const strippedText = htmlToText(userprompt)
  //     const promptPrefix =
  //       inputPrompt === 'Write your own prompt'
  //         ? inputPrompt
  //         : generateType.label
  //     setInputPrompt(promptPrefix)
  //     const res = await generateAPIPrompt(strippedText, domain_id)
  //     if (res?.data?.status === 200) {
  //       let results = res?.data?.results?.results
  //         ? res.data.results.results
  //         : res.data.results
  //       if (results.includes('- ')) {
  //         results = results.replaceAll('- ', '• ')
  //       }
  //       if (results) {
  //         trackEvent(
  //           mixpanelEvents.AI_GENERATE_USED,
  //           'SUCCESS',
  //           {},
  //           { prompt: userprompt }
  //         )
  //         setResultText(results)
  //         if (generateType.followUpPrompt) {
  //           const fup = []
  //           generateType.followUpPrompt.forEach((i) => {
  //             const val = _.find(prompts, { id: i })
  //             if (val) fup.push(val)
  //           })
  //           if (!_.isEmpty(fup)) {
  //             setFollowUpsPrompts(fup)
  //           }
  //         }
  //       } else {
  //         trackEvent(
  //           mixpanelEvents.AI_GENERATE_USED,
  //           'FAILED',
  //           {},
  //           { prompt: userprompt }
  //         )
  //         setResultText(res?.data?.message || 'Generation Failed')
  //       }
  //     } else {
  //       trackEvent(
  //         mixpanelEvents.AI_GENERATE_USED,
  //         'FAILED',
  //         {},
  //         { prompt: userprompt }
  //       )
  //       setResultText(res?.data?.message || 'Generation Failed')
  //     }
  //     setLoading(false)
  //     const element = document.activeElement
  //     element && element?.blur()
  //   }
  // }

  const handleReGenerate = () => {
    handleGenerate(inputText, generateType)
  }

  useEffect(() => {
    if (isMenu) {
      document.addEventListener('keydown', handleKeyDownListViewer)
      return () => {
        document.removeEventListener('keydown', handleKeyDownListViewer)
      }
    }
  }, [aiPlugin, selectPromptSelected, isMenu, options])

  const editorElement = document.getElementsByClassName(
    'rpv-core__text-layer'
  )?.[0]
  const editorBounds = editorElement?.getBoundingClientRect()
  const { x = 0, width = 400, height = 0 } = editorBounds || {}

  let topOffset = 0
  let leftOffset = 0
  const { left, top } = anchor || {}
  if (top) topOffset = top
  if (left) leftOffset = x + 28

  if (topOffset + 530 > height) {
    topOffset = height - 500
  }

  const handleClickOut = () => {
    setShowDialog(false)
    handleClose()
  }

  const handleFollowUpPrompt = (type, newResultText) => {
    setGenerateType(type)
    setInputText(newResultText)
    handleGenerate(newResultText, type)
  }

  const updatedResultBox = useMemo(
    () => (
      <ResultBox
        inputText={inputText}
        setInputText={handleInputChanges}
        generateType={generateType}
        handleKeyDown={handleKeyDown}
        background={'#fff'}
        editState={true}
        focusOn={!loading}
        focusAuto={inputPrompt === 'Write your own prompt' ? 'start' : 'end'}
        expandState={true}
        hideExpandOnBlur
      />
    ),
    [inputText, loading]
  )

  return (
    <>
      {
        isMenu ? (
          <Popover
            open={isMenu}
            anchorReference="anchorPosition"
            anchorPosition={anchor}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box
              sx={{
                padding: '10px',
                width: '250px',
                background: 'white',
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                border: '1px solid lightgrey'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  color: 'black',
                  alignItems: 'center',
                  marginBottom: '10px',
                  justifyContent: 'space-between'
                }}
              >
                <Box>AI Generate</Box>
                <SvgIcon>
                  <Robot />
                </SvgIcon>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '8px' }}>
                <input
                  type="text"
                  placeholder="Search or Write a prompt"
                  value={queryString || ''}
                  onChange={(e) => setQueryString(e.target.value)}
                  className="twp flex h-8 w-full rounded-md border border-input bg-transparent px-2 py-1 shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 text-sm"
                />
              </Box>
              <Box
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  background: '#fff'
                }}
                id={'typeahead-menu'}
              >
                {options.map((option, i) => (
                  <ComponentPickerMenuItem
                    index={i}
                    isSelected={selectPromptSelected === i}
                    onClick={(e) => {
                      setIsMenu(false)
                      const textSelected = selectedText || ''
                      const genSelected = option.onSelect(null)

                      setInputText(textSelected)
                      handleGenerate(
                        textSelected,
                        genSelected,
                        option.title !== 'Write your own prompt'
                      )

                      handleClose()
                    }}
                    onMouseEnter={() => {
                      setSelectPromptSelected(i)
                    }}
                    key={option.key}
                    option={option}
                  />
                ))}
              </Box>
            </Box>
          </Popover>
        ) : (
          <>
            {showDialog && (
              <div
                style={{ position: 'absolute', width: '95%', height: '90%' }}
                onClick={() => {
                  handleClickOut()
                }}
              >
                <Draggable defaultPosition={{ x: 100, y: 0 }} bounds="body">
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    style={{
                      position: 'absolute',
                      left: position.x,
                      top: position.y,
                      cursor: 'all-scroll',
                      width: '65%',
                      zIndex: 10000,
                      borderRadius: '5px',
                      background: 'white',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px'
                    }}
                  >
                    <Box sx={{ padding: '10px', width: '98%' }}>
                      <Container>
                        <SectionFixed>
                          <Box
                            sx={{
                              marginBottom: '5px',
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <span
                              style={{
                                marginRight: '5px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <SvgIcon>
                                <Robot />
                              </SvgIcon>
                              {inputPrompt && (
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '3px'
                                  }}
                                >
                                  {inputPrompt}
                                </span>
                              )}
                            </span>
                            <div>
                              {/* {generateType?.help_text &&
                      <Tooltip sx={{ maxWidth: '300px' }} title={generateType.help_text} >
                        <HelpOutlineIcon />
                      </Tooltip>
                    } */}
                              <CloseIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleClose()}
                              />
                            </div>
                          </Box>
                        </SectionFixed>
                        <Section overFlow>
                          {updatedResultBox}
                          {loading ? (
                            <Box
                              sx={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                              }}
                            >
                              <Loader
                                loading={loading}
                                caption={'generating content'}
                              />
                            </Box>
                          ) : (
                            <>
                              {resultText && (
                                <ResultBox
                                  editState={false}
                                  animatedText
                                  expandState={true}
                                  inputText={resultText}
                                  setInputText={setResultText}
                                  handleKeyDown={() => {}}
                                  background={'#fff'}
                                />
                              )}
                              {followUpsPrompts.length > 0 && (
                                <Box sx={{ display: 'flex' }}>
                                  {followUpsPrompts.map((prm, i) => (
                                    <Box
                                      key={i}
                                      sx={{
                                        width: 'fit-content',
                                        background: '#eaeaea',
                                        border: '0.5px solid #949494',
                                        borderRadius: '8px',
                                        padding: '5px 12px',
                                        margin: '10px 5px',
                                        color: '#000000',
                                        fontStyle: 'italic',
                                        cursor: 'pointer',
                                        backdropFilter: 'blur(2px)',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                      onClick={() => {
                                        handleFollowUpPrompt(prm, resultText)
                                      }}
                                    >
                                      {prm.label}
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </>
                          )}
                        </Section>
                        <SectionFixed>
                          <Box
                            sx={{
                              marginTop: '10px',
                              display: 'flex',
                              justifyContent: 'start'
                            }}
                          >
                            <Button
                              disabled={loading || !inputText}
                              onClick={() => handleReGenerate()}
                            >
                              {resultText
                                ? 'Regenerate'
                                : generateType?.button_text
                                ? generateType?.button_text
                                : 'Generate'}
                            </Button>
                            {resultText && (
                              <CopyButton
                                style={{ marginLeft: '10px' }}
                                content={resultText}
                              />
                            )}
                          </Box>
                        </SectionFixed>
                      </Container>
                    </Box>
                  </div>
                </Draggable>
              </div>
            )}
          </>
        )
        /* :
        <Popover
          open={!isMenu}
          anchorReference="anchorPosition"
          anchorPosition={{ left: leftOffset, top: topOffset }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >

        </Popover> */
      }
    </>
  )
}

export default AIGeneratePlugin
