import React, { lazy, Suspense, useEffect, useState } from 'react'
// import Uppy from '../../utils/uppy/Uppy'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import AwsS3Multipart from '../../utils/uppy/aws-multipart'
import PropTypes from 'prop-types'
import './Uppy.css'
import { useSelector, useDispatch } from 'react-redux'
import { setUploader, resizeUploader } from '../../store/Uploader/Actions'
import { isEmpty, set } from 'lodash'
import GoogleDrive from '@uppy/google-drive'
import Box from '@uppy/box'
// import OneDrive from '@uppy/onedrive'
import AwsS3 from '@uppy/aws-s3'
import Dropbox from '@uppy/dropbox'
import GoogleDrivePicker from '@uppy/google-drive-picker'
import axiosinstance from '../../utils/axios'
import { Auth } from 'aws-amplify'
import { v4 as uuid } from 'uuid'
import { fetchAnalytics } from '../../store/Analytics/Actions'
import { fetchRFxDocuments } from '../../store/RFx/Actions'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'

const UploadModal = lazy(() => import('../UploadModel'))
const Upload = () => {
  const uploaderState = useSelector((state) => state.uploader.uploaderState)
  const dispatch = useDispatch()
  const {
    showUploader,
    uploaderType,
    fullScreen,
    callback = () => {}
  } = uploaderState
  const [uppy, setUppy] = useState({})

  const [currentSession, setCurrentSession] = useState()
  const auth = useSelector((state) => state?.authenticate)
  const domain = auth?.user?.domain
  const { domain_id } = domain || {}
  useEffect(() => {
    async function getSession() {
      const session = await Auth.currentSession()
      setCurrentSession(session)
    }
    getSession()
  }, [])
  const getUploadType = (uploadtype, file) => {
    if (uploadtype === 'resource') {
      if (['pptx', 'docx', 'doc', 'ppt', 'pdf'].includes(file.extension)) {
        uploadtype = 'proposal'
      } else {
        uploadtype = 'asset'
      }
    }
    return uploadtype
  }
  const assertServerError = (res, type) => {
    if (type === 'proposal')
      trackEvent(
        mixpanelEvents?.PROPOSAL_UPLOAD,
        !res.error ? 'SUCCESS' : 'FAILED',
        {},
        res
      )
    else if (type === 'rfx') {
      if (res?.compliance_id && res?.compliance_id !== '') {
        trackEvent(
          mixpanelEvents?.RFX_FILE_UPLOAD_FROM_COMPLIANCE,
          !res.error ? 'SUCCESS' : 'FAILED',
          {},
          res
        )
      } else {
        trackEvent(
          mixpanelEvents?.RFX_FILE_UPLOAD,
          !res.error ? 'SUCCESS' : 'FAILED',
          {},
          res
        )
      }
    } else if (type === 'resource')
      trackEvent(
        mixpanelEvents?.DOCUMENT_UPLOAD,
        !res.error ? 'SUCCESS' : 'FAILED',
        {},
        res
      )
    else if (type === 'analytics')
      trackEvent(
        mixpanelEvents?.ANALYTICS_FILE_UPLOAD,
        !res.error ? 'SUCCESS' : 'FAILED',
        {},
        res
      )
  }
  const getDocumentType = (file) => {
    const { meta, extension } = file
    const { document_type } = meta
    let type = 'document'
    if (['pdf', 'pptx', 'docx', 'doc', 'ppt'].includes(extension)) {
      if (document_type?.label?.toLowerCase() === 'proposal') {
        type = 'proposal'
      } else {
        type = 'document'
      }
    } else {
      type = 'asset'
    }
    return type
  }

  useEffect(() => {
    async function initalize() {
      if (showUploader) {
        const session = await Auth.currentSession()
        const newUppy = new Uppy({
          autoProceed: true,
          id: 'uppy1',
          restrictions: {
            allowedFileTypes: []
          },
          showSelectedFiles: true,
          debug: true,
          allowMultipleUploadBatches: true,
          allowMultipleUploads: true,
          locale: {
            strings: {
              pickFiles: 'Select files',
              pleaseWait: 'Please wait...'
            }
          }
        })
          .use(AwsS3, {
            companionUrl: process.env.REACT_APP_API_GATEWAY_URL,
            id: 'AwsS3',
            limit: 4,
            shouldUseMultipart: true,
            // getTemporarySecurityCredentials: true,
            companionHeaders: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
            },
            async createMultipartUpload(file) {
              const metadata = {}
              Object.keys(file.meta).forEach((key) => {
                if (file.meta[key] != null) {
                  metadata[key] = file.meta[key].toString()
                }
              })
              const filename = `${file.name}`
              const uploadtype = getUploadType(uploaderType, file)
              const collection_id = file?.meta?.collection_id
              let compliance_id = ''
              const document_tag = file?.meta?.document_tag
              if (metadata?.analytics_id) {
                compliance_id = uuid()
                file.meta.compliance_id = compliance_id
              }
              const session = await Auth.currentSession()
              const response = await fetch(
                `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                  },
                  body: JSON.stringify({
                    filename,
                    type: file.type,
                    extension: file.extension,
                    uploadtype,
                    name: metadata.name,
                    created_at: file?.data?.lastModified
                      ? new Date(file?.data?.lastModified)
                      : new Date(),
                    collection_id,
                    ...(compliance_id ? { compliance_id } : {}),
                    ...(metadata?.analytics_id
                      ? { analytics_id: metadata?.analytics_id }
                      : {}),
                    document_tag
                  })
                }
              )
              const data = await response.json()
              file.serverFileId = data.file_id
              file.s3Multipart = {
                key: data.key,
                uploadId: data.uploadId
              }
              // const waiting = await handleResponseUpdate(someMetaClone)
              return {
                uploadId: data.uploadId,
                key: data.key,
                parts: []
              }
            },
            async listParts(file, { key, uploadId }) {
              const filename = encodeURIComponent(key)
              const uploadtype = getUploadType(uploaderType, file)
              const session = await Auth.currentSession()
              const response = await fetch(
                `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadId}/list?key=${filename}&uploadtype=${uploadtype}`,
                {
                  method: 'get',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                  }
                }
              )
              const data = await response.json()
              return data
            },
            async signPart(file, opts) {
              const { uploadId, key, partNumber, body, signal } = opts
              if (!uploadId || !key) {
                console.log(file, opts)
                throw new Error(
                  "Oops! We couldn't retrieve the file. Please try uploading it again."
                )
              }
              const filename = encodeURIComponent(key)
              const uploadtype = getUploadType(uploaderType, file)
              const session = await Auth.currentSession()
              const response = await fetch(
                `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadId}/batch?key=${filename}&partNumbers=${partNumber}&uploadtype=${uploadtype}`,
                {
                  method: 'get',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                  }
                }
              )
              const data = await response.json()
              return {
                url: data.presignedUrls?.[partNumber],
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            },
            async abortMultipartUpload(file, { key, uploadId }) {
              if (uploadId) {
                const filename = encodeURIComponent(key)
                const uploadIdEnc = encodeURIComponent(uploadId)
                let uploadtype = getUploadType(uploaderType, file)
                uploadtype = encodeURIComponent(uploadtype)
                const session = await Auth.currentSession()
                const response = await fetch(
                  `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadIdEnc}?uploadtype=${uploadtype}&key=${filename}`,
                  {
                    method: 'delete',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                    }
                  }
                )
                return {}
              } else {
                return {}
              }
            },
            async completeMultipartUpload(file, { key, uploadId, parts }) {
              const metadata = {}
              const documentType = getDocumentType(file)
              Object.keys(file.meta).forEach((key) => {
                if (file.meta[key] != null) {
                  if (key.includes('_')) {
                    metadata[key] = file.meta[key].toString()
                  }
                }
              })
              const filename = encodeURIComponent(key)
              const uploadIdEnc = encodeURIComponent(uploadId)
              const uploadtype = getUploadType(uploaderType, file)
              parts = parts.map((part, index) => ({
                ETag: part?.ETag,
                PartNumber: part?.PartNumber
              }))
              const session = await Auth.currentSession()
              const response = await fetch(
                `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart/${uploadIdEnc}/complete?uploadtype=${uploadtype}&key=${filename}`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
                  },
                  body: JSON.stringify({
                    parts,
                    metadata,
                    document_type: documentType,
                    ...(file?.meta?.compliance_id
                      ? { compliance_id: file?.meta?.compliance_id }
                      : {})
                  })
                }
              )
              const trackEventRes = assertServerError(
                {
                  compliance_id: file?.meta?.compliance_id,
                  error: false
                },
                uploadtype
              )
              const data = await response.json()
              return data
            }
          })
          // .use(GoogleDrive, {
          //   companionUrl: 'https://companion.joist.ai/',
          //   id: 'GoogleDrive',
          //   target: uppy.Dashboard
          // })
          .use(GoogleDrivePicker, {
            companionUrl: 'https://companion.joist.ai',
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            appId: process.env.REACT_APP_GOOGLE_APP_ID
          })
          .use(Box, {
            companionUrl: 'https://companion.joist.ai/',
            id: 'Box',
            target: uppy.Dashboard
          })
          .use(Dropbox, {
            companionUrl: 'https://companion.joist.ai/',
            id: 'Dropbox',
            target: uppy.Dashboard
          })
        // .use(OneDrive, {
        //   companionUrl: 'https://companion.joist.ai/',
        //   id: 'OneDrive',
        //   target: uppy.Dashboard
        // })
        setUppy(newUppy)
      }
    }
    initalize()
  }, [showUploader])

  useEffect(() => {
    if (!isEmpty(uppy)) {
      uppy.on('file-added', async (file) => {
        // console.log('file added', file)
        if (file?.isRemote) {
          const newUUID = uuid()
          const newKey =
            'documents/' + domain_id + '/' + newUUID + '.' + file.extension
          file.serverFileId = newUUID
          file.s3Multipart = {
            key: newKey
          }
          uppy.setFileState(file.id, {
            s3Multipart: {
              key: newKey
            },
            serverFileId: newUUID
          })
          const filename = `${file.name}`
          const uploadtype = getUploadType(uploaderType, file)
          const metadata = {}
          const document_tag = file?.meta?.document_tag
          Object.keys(file.meta).forEach((key) => {
            if (file.meta[key] != null) {
              metadata[key] = file.meta[key].toString()
            }
          })
          const collection_id = file?.meta?.collection_id || []
          let compliance_id = ''
          if (metadata?.analytics_id) {
            compliance_id = uuid()
            file.meta.compliance_id = compliance_id
          }
          const dataForCreateMultipartUpload = {
            filename,
            type: file?.type,
            uploadtype,
            extension: file?.extension,
            name: file?.name,
            newUUID,
            created_at: file?.data?.lastModified
              ? new Date(file?.data?.lastModified)
              : new Date(),
            collection_id: collection_id || [],
            remote_upload: true,
            ...(compliance_id ? { compliance_id } : {}),
            ...(metadata?.analytics_id
              ? { analytics_id: metadata?.analytics_id }
              : {}),
            document_tag
          }
          const session = await Auth.currentSession()
          const response1 = await fetch(
            `${process.env.REACT_APP_API_GATEWAY_URL}s3/multipart`,
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              },
              body: JSON.stringify(dataForCreateMultipartUpload)
            }
          )
          const data = await response1.json()
          // console.log('createMultipartUpload Response - ', data)
        }
      })
      uppy.on('upload-success', async (file, response) => {
        // console.log('upload-success', file, response)
        if (file?.isRemote) {
          const uploadtype = getUploadType(uploaderType, file)
          const metadata = {}
          Object.keys(file.meta).forEach((key) => {
            if (file.meta[key] != null) {
              metadata[key] = file.meta[key].toString()
            }
          })
          const documentType = getDocumentType(file)
          const document_tag = file?.meta?.document_tag
          const dataForCompleteMultipartUpload = {
            parts: [],
            document_type: documentType,
            remote_upload: true,
            location_for_remote_files: response.uploadURL,
            old_key: response.body.Key,
            file_type: file?.type,
            file_extension: file?.extension,
            ...(file?.meta?.compliance_id
              ? { compliance_id: file?.meta?.compliance_id }
              : {}),
            ...(metadata?.analytics_id
              ? { analytics_id: metadata?.analytics_id }
              : {}),
            document_tag
          }
          const session = await Auth.currentSession()
          const response2 = await fetch(
            `${
              process.env.REACT_APP_API_GATEWAY_URL
            }s3/multipart/${'uploadIdEnc'}/complete?uploadtype=${uploadtype}&key=${
              file?.s3Multipart?.key
            }`,
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${session?.accessToken?.getJwtToken()}`
              },
              body: JSON.stringify(dataForCompleteMultipartUpload)
            }
          )
          const data1 = await response2.json()
          // console.log('completeMultipartUpload Response - ', data1)
          if (uploaderType === 'analytics') {
            dispatch(fetchAnalytics())
          } else if (uploaderType === 'rfx') {
            dispatch(fetchRFxDocuments())
          }
        }
      })
      uppy.on('upload-error', (file, error) => {
        // console.log('upload-error', file, error)
      })
      uppy.on('upload', (data) => {
        // console.log('upload', data)
      })
      uppy.on('upload-progress', (file, progress) => {
        // console.log('upload-progress', file, progress)
      })
      uppy.on('upload-started', (file) => {
        // console.log('upload-started', file)
      })
      uppy.on('start', (data) => {
        // console.log('start', data)
      })
    }
  }, [uppy])

  const handleUploaderClose = () => {
    setUppy({})
    dispatch(setUploader({ showUploader: false, uploaderType: '' }))
  }

  const handleFullScreen = (e) => {
    dispatch(resizeUploader(e))
  }

  return (
    !isEmpty(uppy) &&
    showUploader && (
      <Suspense fallback={null}>
        <UploadModal
          uppy={uppy}
          modalCallback={callback}
          fullScreen={fullScreen}
          showFullScreen={(val) => handleFullScreen(val)}
          onRequestClose={() => handleUploaderClose()}
          uploaderType={uploaderType}
          uploaderState={uploaderState}
        />
      </Suspense>
    )
  )
}

export default Upload
