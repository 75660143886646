import React, { useState } from 'react'
import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../config/theme'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

const useStyles = makeStyles({
  calendarInput: {
    '& .MuiOutlinedInput-root': {
      border: 'none'
      // padding: '3px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '3px',
      fontSize: '12px'
    },

    '& .MuiButtonBase-root': {
      display: 'none'
      // marginLeft: '3px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& . MuiInputBase-adornedEnd': {
      display: 'none'
    }
  }
})

export { useStyles }
const Calendar = (props) => {
  const {
    selectedDate,
    handleDateChange,
    label = '',
    adornmentPosition = 'end',
    disablePast = true,
    openCalender = false
  } = props
  const [open, setOpen] = useState(openCalender)
  const classes = useStyles()
  const [date, setDate] = useState(selectedDate)
  return (
    <div>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <DatePicker
            inputFormat="MMM dd, yyyy"
            disablePast={disablePast}
            showToolbar={false}
            label={null}
            open={open}
            onClose={() => setOpen(false)}
            value={date}
            toolbarPlaceholder="Select Date"
            allowKeyboardControl={true}
            InputAdornmentProps={{ style: { display: 'none' } }}
            onChange={(date) => {
              console.log('date', date)
              setDate(date)
              handleDateChange(date)
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                onClick={() => setOpen(true)}
                variant="outlined"
                helperText={null}
                className={classes.calendarInput}
              />
            )}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

export default Calendar
