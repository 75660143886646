import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Drawer, DrawerHeader, DrawerFooter, useStyles } from './styles'
import {
  menuItems,
  menuAnnotator,
  superUserTab,
  adminTab,
  annotatorTab,
  userTab
} from './menuItems'
import { Fade, Grow, Icon } from '@material-ui/core'
// import Logo from '../../assets/images/App_Logo.svg'
import Logo from '../../assets/images/JoistLogo.png'
import SLogo from '../../assets/images/slogo.png'
import Badge from '@mui/material/Badge'

import { useSelector, useDispatch } from 'react-redux'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import UserAvatar, { AvatarIcon } from '../../components/UserAvatar'
import {
  checkDomainAccess,
  checkUserRoleAdmin,
  checkUserRoleAnnotator,
  checkUserRoleSuperUser
} from '../../utils/User'
import clsx from 'clsx'
import { useColor } from '../../ThemeContext'
import SvgIcon from '@mui/material/SvgIcon'
import Button2 from '../Button/Button2'
import {
  ArrowTrio,
  CloseSidebar,
  FolderIcon,
  HamburgerMenuIcon,
  HomeIcon,
  OpenSidebar,
  PieIcon,
  RightIcon,
  SearchIcon,
  CircleHelpIcon,
  SparkIcon2
} from '../Icons/Icons'
import CommandBar from '../CommandBar/CommandBar'
import { styled } from '@mui/styles'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import {
  userRoleAdmin,
  userRoleAnnotator,
  userRoleSuperUser
} from '../../config/constants'
import { setSidebarActive } from '../../store/Common/Actions'
import { ROUTES } from '../../config/routes'
import { setChatState } from '../../store/Chat/Actions'
import Draggable from 'react-draggable'
import featureGroup from '@/config/feature-group'

const getDataFromLocal = () => {
  return localStorage.getItem('sidebarState')
}

const saveDataToLocal = (data) => {}

export default function MiniDrawer({ onSidebarStateChange = () => {} }) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(() => {
    const savedState = localStorage.getItem('sidebarState')
    return savedState !== null ? JSON.parse(savedState) : false
  })
  const [submenuOpen, setSubmenuOpen] = React.useState(false)
  const location = useLocation()
  const path = location?.pathname
  const classes = useStyles({ open })
  const dispatch = useDispatch()
  const commonState = useSelector((state) => state.common)
  const [commandOpen, setCommandOpen] = React.useState(false)

  const user = useSelector((state) => state.authenticate.user)
  const chat = useSelector((state) => state.chat)
  const { chatState } = chat || {}
  const { domain = {} } = user || {}
  const { domain_id, domain_name, email, user_role = 'user' } = domain
  const userName = user?.domain?.user_name ? user?.domain?.user_name : ' '
  const [collapsedNav, setCollapsedNav] = React.useState([])

  const [isDragging, setIsDragging] = React.useState(false)
  const [chatTooltipOpen, setChatTooltipOpen] = React.useState(false)

  const draggableEventControl = (event) => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true)
      setChatTooltipOpen(false)
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false)
      }, 100)
    }
  }

  useEffect(() => {
    console.log('collapsedNav', collapsedNav)
  }, [collapsedNav])

  useEffect(() => {
    localStorage.setItem('sidebarState', JSON.stringify(open))
    onSidebarStateChange(open)
  }, [open])

  const { sidebarActive } = commonState

  const {
    LIBRARY_PEOPLE_RESUME,
    LIBRARY_PROJECTS,
    LIBRARY_CLIENTS_RESUME,
    ANALYTICS_INSIGHTS,
    USER_INSIGHTS
  } = ROUTES

  const isAnnotator = checkUserRoleAnnotator()
  const isSuperUser = checkUserRoleSuperUser()
  const isAdmin = checkUserRoleAdmin()
  const getTab = (role = user?.domain?.user_role) => {
    switch (role) {
      case userRoleSuperUser:
        return superUserTab
      case userRoleAdmin:
        return adminTab
      case userRoleAnnotator:
        return annotatorTab
      default:
        return userTab
    }
  }

  const settings_submenu = getTab(user?.domain?.user_role)
  const showInsights = checkDomainAccess('higher_order_insights')
  const floatingChat = checkDomainAccess('chat_v2')
  const tempMenu = isAnnotator ? menuAnnotator : menuItems
  const [menu, setMenu] = React.useState(tempMenu)

  if (path === '/domain') {
    return null
  }

  const handleMenuClick = (route) => {
    if (route === '/chat/new') {
      dispatch(setChatState('open'))
      trackEvent(
        mixpanelEvents.CHAT_ICON_CLICKED_SIDEBAR,
        'SUCCESS',
        {},
        {
          'Feature Group': featureGroup.CHAT_V2
        }
      )
    } else {
      navigate(route, { replace: true })
    }
  }

  useEffect(() => {
    const keybindings = (e) => {
      // ctrl + k

      if (e.ctrlKey && e.key.toLowerCase() === 'k') {
        e.preventDefault()
        setCommandOpen(true)
      }

      // ctrl + m
      if (e.ctrlKey && e.key.toLowerCase() === 'm') {
        console.log(chatState)
        e.preventDefault()
        if (chatState === 'open') {
          dispatch(setChatState('closed'))
        } else {
          dispatch(setChatState('open'))
        }
      }

      // ctrl + /

      if (e.ctrlKey && e.key === '/') {
        e.preventDefault()
        trackEvent(
          mixpanelEvents.SIDEBAR_TOGGLE,
          !open ? 'OPEN' : 'CLOSE',
          {},
          {
            route: path
          }
        )
        setOpen(!open)
        setCollapsedNav([])
        dispatch(setSidebarActive(!open))
      }

      // escape

      if (e.key === 'Escape') {
        setCommandOpen(false)
      }
    }
    document.addEventListener('keydown', keybindings)

    return () => {
      document.removeEventListener('keydown', keybindings)
    }
  }, [open, chatState])

  useEffect(() => {
    const menuTemp = tempMenu.map((item) => {
      if (item.name === 'Settings') {
        return {
          ...item,
          submenu: settings_submenu
        }
      }

      if (item.name === 'Library') {
        const submenu = item.submenu
        submenu.push({
          name: 'People',
          route: LIBRARY_PEOPLE_RESUME,
          toolTip: 'Bucket'
        })
        submenu.push({
          name: 'Projects',
          route: LIBRARY_PROJECTS,
          toolTip: 'Knowledge'
        })
        submenu.push({
          name: 'Companies',
          route: LIBRARY_CLIENTS_RESUME,
          toolTip: 'Bucket'
        })
        return {
          ...item,
          submenu
        }
      }

      if (item.name === 'Analytics') {
        const submenu = item.submenu

        if ((showInsights && isAdmin) || isSuperUser) {
          submenu.push({
            name: 'Insights',
            route: ANALYTICS_INSIGHTS,
            toolTip: 'Insights'
          })
        }
      }

      return item
    })

    menuTemp.splice(menuTemp.length - 1, 0, {
      name: 'Account Insights',
      icon: <PieIcon className="size-4" />,
      route: USER_INSIGHTS,
      toolTip: 'Account Insights'
    })

    setMenu(menuTemp)
  }, [])

  const { logoImage, navigationColor } = useColor()

  return (
    <div className={classes.sideBarWrapper}>
      <Box className={classes.box}>
        <Drawer variant="permanent" open={open}>
          {/* <DrawerHeader>
              <Box className={classes.headerLogo}>
                <ListItemIcon className={classes.listIcon}>
                  <Icon className={classes.icon}>
                    {logoImage ? (
                      <img
                        style={{ height: '39px', width: '39px' }}
                        src={logoImage}
                        alt="applogo"
                      />
                    ) : (
                      <img
                        src={Logo}
                        alt="applogo"
                        style={{
                          width: '39px',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                  </Icon>
                </ListItemIcon>
              </Box>
            </DrawerHeader> */}
          <div
            style={{
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: open ? 'row' : 'column',
              justifyContent: open ? 'space-between' : 'center',
              gap: '10px'
            }}
          >
            {open ? (
              <>
                {logoImage === null || logoImage ? (
                  logoImage ? (
                    <img
                      style={{
                        height: '39px',
                        width: '50px',
                        objectFit: 'contain'
                      }}
                      src={logoImage}
                      alt="applogo"
                      key="customlogo"
                    />
                  ) : (
                    <Box></Box>
                  )
                ) : (
                  <img
                    src={Logo}
                    alt="applogo"
                    style={{
                      width: '39px',
                      objectFit: 'contain'
                    }}
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative'
                }}
                className={classes.headerBar}
              >
                {/* <img
                    src={SLogo}
                    alt="applogo"
                    style={{
                      width: '20px',
                      objectFit: 'contain',
                      cursor: 'pointer'
                    }}
                  /> */}
                <HamburgerMenuIcon
                  onClick={() => {
                    setOpen(!open)
                    dispatch(setSidebarActive(!open))
                  }}
                  // strokeWidth={2}
                  style={{
                    cursor: 'pointer',
                    color: 'var(--grey-300)',
                    width: '16px',
                    height: '16px'
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: open ? 'row' : 'column',
                alignItems: 'center',
                gap: '7px'
              }}
            >
              {open && (
                <CustomTooltip
                  placement="bottom"
                  title="Toggle Sidebar ( Ctrl + / )"
                >
                  <Button2
                    icon={true}
                    style={{
                      padding: '5px'
                    }}
                    onClick={() => {
                      setOpen(!open)
                      setCollapsedNav([])
                      dispatch(setSidebarActive(!open))
                    }}
                  >
                    <CloseSidebar width={16} height={16} strokeWidth={0.5} />
                  </Button2>
                </CustomTooltip>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '0px 10px',
              maxHeight: 'calc(100% - 175px)',
              overflow: 'auto'
            }}
          >
            {menu.map((item, indexList) => {
              if (item.submenu) {
                return (
                  <div key={indexList}>
                    <HtmlTooltip
                      placement="right"
                      trigger="click"
                      open={submenuOpen === indexList}
                      onClose={() => {
                        setSubmenuOpen(null)
                      }}
                      onOpen={() => {
                        setSubmenuOpen(indexList)
                      }}
                      disableFocusListener={!open}
                      disableHoverListener={open}
                      title={
                        open ? (
                          item.name
                        ) : (
                          <div
                            style={{
                              padding: '10px',
                              flexDirection: 'column',
                              gap: '3px',
                              display: 'flex'
                            }}
                          >
                            {item.submenu.map((subItem, index) => (
                              <Button2
                                key={index}
                                isActive={path.includes(
                                  subItem.route.toLowerCase()
                                )}
                                icon={true}
                                onClick={() => {
                                  setSubmenuOpen(null)
                                  handleMenuClick(subItem.route)
                                }}
                                style={{
                                  width: '100%',
                                  fontSize: '12px',
                                  color: 'white',
                                  textAlign: 'left',
                                  display: 'flex',
                                  justifyContent: 'start'
                                }}
                              >
                                {/* {subItem.icon} */}

                                <span>
                                  {' '}
                                  {subItem.name ? subItem.name : subItem.label}
                                </span>
                              </Button2>

                              // <p
                              //   key={index}
                              //   style={{
                              //     margin: '0'
                              //   }}
                              //   onClick={() => {
                              //     handleMenuClick(subItem.route)
                              //   }}
                              // >
                              //   {subItem.name}
                              // </p>
                            ))}
                          </div>
                        )
                      }
                    >
                      <Button2
                        icon={!open}
                        isActive={path.includes(item.name.toLowerCase())}
                        onClick={() => {
                          // handleMenuClick(item.route)
                          if (open) {
                            setCollapsedNav((prev) =>
                              prev.includes(item.id)
                                ? prev.filter((id) => id !== item.id)
                                : [...prev, item.id]
                            )
                          }
                        }}
                        style={{
                          width: '100%',
                          color: path.includes(item.name.toLowerCase())
                            ? 'var(--light-primary)'
                            : 'var(--grey-300)'
                        }}
                      >
                        {item.icon}

                        {open && <span>{item.name}</span>}
                        {open && (
                          <ArrowTrio
                            height={6}
                            width={6}
                            style={{
                              marginLeft: 'auto',
                              transition: 'all 0.2s ease-in-out',
                              rotate: collapsedNav.includes(item.id)
                                ? '90deg'
                                : '0deg'
                            }}
                          />
                        )}
                      </Button2>
                    </HtmlTooltip>
                    <Fade
                      in={collapsedNav.includes(item.id)}
                      {...(collapsedNav.includes(item.id)
                        ? { timeout: 600 }
                        : {})}
                    >
                      <div
                        style={{
                          marginTop: '10px',
                          marginLeft: '17px',
                          padding: '0 10px',
                          paddingRight: '15px',
                          borderLeft: '1px solid var(--bg-dark-primary-2)',
                          flexDirection: 'column',
                          gap: '3px',
                          display: collapsedNav.includes(item.id)
                            ? 'flex'
                            : 'none'
                        }}
                      >
                        {item.submenu.map((subItem, index) => (
                          <CustomTooltip
                            key={index}
                            placement="right"
                            title={subItem.name}
                            disableHoverListener={open}
                          >
                            <Button2
                              isActive={path.includes(
                                subItem?.route?.toLowerCase()
                              )}
                              icon={!open}
                              onClick={() => {
                                handleMenuClick(subItem.route)
                              }}
                              style={{
                                width: '100%',
                                fontSize: '12px',
                                color: path.includes(
                                  subItem?.route?.toLowerCase()
                                )
                                  ? 'var(--light-primary)'
                                  : 'var(--grey-300)'
                              }}
                            >
                              {subItem.icon}

                              {open && (
                                <span>
                                  {subItem.name ? subItem.name : subItem.label}
                                </span>
                              )}
                            </Button2>
                          </CustomTooltip>
                        ))}
                      </div>
                    </Fade>
                  </div>
                )
              }
              return (
                <CustomTooltip
                  key={indexList}
                  placement="right"
                  title={item.toolTip ?? item.name}
                  disableHoverListener={open}
                >
                  <Button2
                    isActive={path.includes(item.route.toLowerCase())}
                    icon={!open}
                    onClick={() => {
                      handleMenuClick(item.route)
                    }}
                    style={{
                      width: '100%',
                      color: path.includes(item.name.toLowerCase())
                        ? 'var(--light-primary)'
                        : 'var(--grey-300)'
                    }}
                  >
                    {item.route === 'chat' && ['open'].includes(chatState) ? (
                      <Badge badgeContent={''} color="secondary" variant="dot">
                        {item.icon}
                      </Badge>
                    ) : (
                      item.icon
                    )}
                    {open && <span>{item.name}</span>}
                  </Button2>
                </CustomTooltip>
              )
            })}
            {/* {menu.map((item, indexList) => (
                <ListItem
                  key={indexList}
                  disablePadding
                  className={classes.listItem}
                >
                  <ListItemButton
                    className={classes.button}
                    onClick={() => {
                      handleMenuClick(item.route)
                    }}
                  >
                    {open ? (
                      <ListItemIcon className={classes.listIcon}>
                        <Icon className={classes.icon}>
                          <SvgIcon>{item.icon}</SvgIcon>
                        </Icon>
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon className={classes.listIcon}>
                        <Tooltip title={item.name} placement="right" arrow>
                          <Icon className={classes.icon}>
                            <SvgIcon>{item.icon}</SvgIcon>
                          </Icon>
                        </Tooltip>
                      </ListItemIcon>
                    )}
                    {open && (
                      <ListItemText
                        primary={item.name}
                        className={classes.listText}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))} */}
          </div>

          <DrawerFooter>
            <div
              style={{
                padding: '10px'
              }}
            >
              <CustomTooltip
                placement={open ? 'bottom' : 'right'}
                title="Help"
                disableHoverListener={open}
              >
                <Button2
                  style={{
                    width: '100%',
                    color: 'var(--grey-400)',
                    backgroundColor: 'var(--bg-dark-primary)'
                  }}
                  icon={!open}
                  id="intercom-trigger"
                >
                  <CircleHelpIcon
                    style={{
                      width: '18px',
                      height: '18px'
                    }}
                  />
                  {open && <span>Help</span>}
                  {open && (
                    <span
                      style={{
                        marginLeft: 'auto',
                        fontSize: '10px'
                      }}
                    >
                      Chat with us
                    </span>
                  )}
                </Button2>
              </CustomTooltip>
            </div>
            <div
              style={{
                padding: '5px 10px'
              }}
            >
              <CustomTooltip
                placement={open ? 'bottom' : 'right'}
                title="Search ( Ctrl + k )"
                disableHoverListener={open}
              >
                <Button2
                  style={{
                    width: '100%',
                    color: 'var(--grey-400)',
                    backgroundColor: 'var(--bg-dark-primary)'
                  }}
                  icon={!open}
                  onClick={() => setCommandOpen(true)}
                >
                  <SearchIcon
                    style={{
                      width: '18px',
                      height: '18px'
                    }}
                  />
                  {open && <span>Search</span>}

                  {open && (
                    <span
                      style={{
                        marginLeft: 'auto',
                        fontSize: '10px'
                      }}
                    >
                      Ctrl + k
                    </span>
                  )}
                </Button2>
              </CustomTooltip>
            </div>
            <UserAvatar sideBarOpen={open} />
          </DrawerFooter>
        </Drawer>

        <div
          className="absolute right-0 flex flex-col justify-center"
          style={{
            top: '16px',
            bottom: '16px',
            visibility: !['open'].includes(chatState) ? 'visible' : 'hidden'
          }}
        >
          <Draggable
            axis="y"
            bounds="parent"
            onDrag={draggableEventControl}
            onStop={draggableEventControl}
          >
            <CustomTooltip
              placement="left"
              title={
                <div className="p-2 mr-2 text-lg ">
                  <p className="m-0 text-sm font-medium">Your AI assistant.</p>
                  <p className="m-0 text-xs text-grey-300">
                    Get instant answers to your questions
                  </p>
                </div>
              }
              open={chatTooltipOpen}
              onOpen={() => setChatTooltipOpen(true)}
              onClose={() => setChatTooltipOpen(false)}
            >
              <div
                className={
                  'rounded-l-lg p-3 grid place-content-center shadow-xl cursor-pointer bg-white z-100 ' +
                  classes.iconAiFab
                }
                onClick={() => {
                  !isDragging && dispatch(setChatState('open'))
                  trackEvent(
                    mixpanelEvents.CHAT_ICON_CLICKED,
                    'SUCCESS',
                    {},
                    {
                      'Feature Group': featureGroup.CHAT_V2
                    }
                  )
                }}
              >
                <div className="grid place-content-center">
                  <SparkIcon2 className="size-5 chatIcon" />
                </div>
              </div>
            </CustomTooltip>
          </Draggable>
        </div>

        <CommandBar
          open={commandOpen}
          setOpen={setCommandOpen}
          key={commandOpen}
        />
      </Box>
    </div>
  )
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Fade}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--dark-text)'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--dark-text)'
  }
}))

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0',
    maxWidth: '200px',
    backgroundColor: '#131516',
    color: 'white',
    textAlign: 'left',
    width: '100%',
    height: '100%',
    // border: '1px solid #dadde9',
    gap: '3px',
    display: 'flex',
    flexDirection: 'column'
  }
}))
