import { useState, useEffect } from 'react'
import { useStyles } from './styles'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Loader from '../../components/Loader'
import _ from 'lodash'
import TextResult from './TextResult'
import { Container, Section, SectionFixed } from '../../components/Container'
import PDFViewer from '../../components/PDFViewer'
import { getSignedUrlHead, checkExpiry } from '../../utils/AWS'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as BackImg } from '../../assets/images/back.svg'
import ImageGallery from '../../components/ImageGallery'
import { getImageMeta } from '../../utils/AWS/getSignedUrl'
import {
  fetchLibraryVariations,
  getSimilarity,
  removeLibrarySimilarity
} from '../../store/api'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { UPDATE_LIBRARY_VARIAITONS } from '../../store/actionTypes'

const Variations = (props) => {
  const {
    domain_id = '',
    variationData = {},
    wrapperPadding = '20px',
    setShowVariations,
    showVariations,
    s3Obj,
    ...rest
  } = props
  const {
    variationsInResult = [],
    variationsFromApiKnowledge = [],
    similarity = []
  } = variationData
  const classes = useStyles()
  const { variationWrapper } = classes
  const [selectedScreenshots, setSelectedScreenshots] = useState([])
  const [selectedScreenshotIndex, setSelectedScreenshotIndex] = useState(0)
  const [variationsFromApi, setVariationsFromApi] = useState([])
  const [loading, setLoading] = useState(false)
  const [variationsUpdating, setVariationsUpdating] = useState(false)

  useEffect(() => {
    if (showVariations && !_.isEmpty(similarity)) {
      fetchSimilarity(similarity)
    }
  }, [similarity, showVariations])

  const fetchSimilarity = async (similarity) => {
    setLoading(true)
    const data = {
      domain_id,
      content_type: 'section',
      content_ids: similarity
    }
    const response = await getSimilarity(data)
    if (response?.status === 200) {
      setVariationsFromApi(response?.data.data)
    }
    setLoading(false)
  }

  const handleShowImage = async (screenshots, index = 0) => {
    await Promise.all(
      screenshots.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        screenshots[index].src = signedSrc
      })
    )
    setSelectedScreenshots(screenshots)
    setSelectedScreenshotIndex(index)
  }

  const variations = [
    ...variationsFromApi,
    ...variationsInResult,
    ...variationsFromApiKnowledge
  ]

  const dispatch = useDispatch()

  const handleRemoveVariation = async (variationId) => {
    setVariationsUpdating(true)
    if (variationId) {
      const req = {
        similarity_id: variationId,
        library_id: variationData?.id
      }

      const loadingToast = toast.loading('Removing variation...')

      const res = await removeLibrarySimilarity(req)
      if (res.status === 200) {
        const newVariations = [...variations]
        const index = newVariations.findIndex((v) => v.id === variationId)
        newVariations.splice(index, 1)
        dispatch({
          type: UPDATE_LIBRARY_VARIAITONS,
          payload: {
            [variationData?.id]: newVariations
          }
        })
        toast.dismiss(loadingToast)
        toast.success('Variation removed successfully')
      } else {
        toast.dismiss(loadingToast)
        toast.error('Failed to remove variation')
      }
    }
    setVariationsUpdating(false)
  }

  return (
    <>
      {loading ? (
        <Box
          sx={{ margin: '10px 0px', display: 'flex', justifyContent: 'center' }}
        >
          <Loader loading={loading} caption={''} />
        </Box>
      ) : (
        <Box className={variationWrapper} sx={{ paddingLeft: wrapperPadding }}>
          {variations &&
            variations?.map((val, index) => (
              <Result
                key={index}
                res={val}
                s3Obj={s3Obj}
                domain_id={domain_id}
                {...rest}
                handleShowImage={handleShowImage}
                handleRemoveVariation={handleRemoveVariation}
                variationsLoading={variationsUpdating}
              />
            ))}
          {_.isEmpty(variations) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p>No variations found</p>
            </Box>
          )}
          {selectedScreenshots.length > 0 && (
            <ImageGallery
              images={selectedScreenshots}
              onClose={() => setSelectedScreenshots([])}
              options={{ initialViewIndex: selectedScreenshotIndex }}
            />
          )}
        </Box>
      )}
    </>
  )
}

const Result = (props) => {
  const {
    domain_id,
    res,
    key,
    disableEdit = false,
    s3Obj = {},
    handleRemoveVariation = () => {},
    ...rest
  } = props
  const [signedScreenshots, setSignedScreenshots] = useState([])
  const { data = {}, screenshots = [], thumbnail_location = '' } = res
  const { content, title } = data
  async function signScreenshots(screenshots) {
    const promises = screenshots.map(async (e, i) => {
      let { src, width, height } = { ...e }
      const signedSrc = await getSignedUrlHead(src, s3Obj)
      if (!width || !height) {
        const img = await getImageMeta(signedSrc)
        width = img.width
        height = img.height
      }
      screenshots[i] = { ...e, src: signedSrc, width, height }
    })
    await Promise.all(promises)
    return screenshots
  }

  useEffect(() => {
    async function initalizeData() {
      try {
        let combinedScreenshots = [...screenshots]
        if (_.isEmpty(combinedScreenshots)) {
          if (thumbnail_location) {
            combinedScreenshots.push({ src: thumbnail_location })
          } else {
            const { proposal_id, page_number } = res
            if (process.env.REACT_APP_IMAGE_BUCKET) {
              const potentialLocation = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/${page_number}.png`
              combinedScreenshots.push({ src: potentialLocation })
            }
          }
        }
        combinedScreenshots = await signScreenshots(combinedScreenshots)
        setSignedScreenshots(combinedScreenshots)
      } catch (e) {
        // Catch for Non-Error promise rejection captured with value: undefined
      }
    }
    initalizeData()
  }, [res])

  return (
    <>
      <TextResult
        keyId={key}
        result={res}
        s3Obj={s3Obj}
        hideTextLength={300}
        optionHiddenOpen={false}
        enableSave={false}
        searchType={'variations'}
        screenshots={signedScreenshots}
        enableAddtoNarratives={!disableEdit}
        disableEdit={disableEdit}
        handleRemoveVariation={handleRemoveVariation}
        {...rest}
      />
    </>
  )
}
export default Variations
