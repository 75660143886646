import SvgIcon from '@material-ui/core/SvgIcon'
import CloseIcon from '@material-ui/icons/Close'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import PreviewIcon from '@mui/icons-material/Preview'
import SaveIcon from '@mui/icons-material/Save'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Rating
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import _ from 'lodash'
import markdownit from 'markdown-it'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import Lottie from 'react-lottie'
import ReactPullToRefresh from 'react-pull-to-refresh'
import { useDispatch, useSelector } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuid } from 'uuid'
import AiLoader from '../../../assets/lottie/ai.json'
import { ReactComponent as ChatStatusLoading } from '../../../assets/svg/loading.svg'
import { ReactComponent as ChatStatusLoadingDone } from '../../../assets/svg/loadingDone.svg'
import ServerDown from '../../../assets/svg/ServerDown.svg'
import {
  Button,
  ImageGallery,
  TagFilter,
  EllipsisTooltip
} from '../../../components'
import DocumentAdd from '../../../components/TagFilters/DocumentAdd'
import ButtonSelect from '../../../components/ButtonSelect'
import { Container, Section, SectionFixed } from '../../../components/Container'
import Error from '../../../components/Error/Error'
import ImageRender from '../../../components/ImageRender'
import useModal from '../../../components/Lexical/hooks/useModal'
import { InsertResumeDailog } from '../../../components/Lexical/plugins/ResumePlugin'
import Loader from '../../../components/Loader'
import Tab from '../../../components/Tabs'
import TextLineLimiter from '../../../components/TextLineLimiter'
import { AvatarIcon } from '../../../components/UserAvatar'
import eventMapping from '../../../config/eventMapping'
import mixpanelEvents from '../../../config/mixpanelEvents'
import { ROUTES } from '../../../config/routes'
import {
  DOMAIN_CONFIG_UPDATE,
  REVERT_FAVPROMPT_DOMAIN_CONFIG
} from '../../../store/actionTypes'
import {
  chatFeedback,
  chatStop,
  generateImages,
  getChatHistory,
  putUserDomain,
  saveImagetoAsset
} from '../../../store/api'
import { fetchPrompts } from '../../../store/Common/Actions'
import { getSignedUrl } from '../../../utils/AWS'
import { htmlToText, textToHtml } from '../../../utils/CopyHTML'
import { initalizeDownload } from '../../../utils/DownloadFromS3/DownloadFromS3'
import trackEvent from '../../../utils/TrackEvent/TrackEvent'
import { checkUserRoleAdmin, checkUserRoleSuperUser } from '../../../utils/User'
import { useStyles } from './styles'

import ContactPageIcon from '@mui/icons-material/ContactPage'
import ImageIcon from '@mui/icons-material/Image'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import Button2 from '../../../components/Button/Button2'
import {
  AudioLinesIcon,
  ComponentPromptIcon,
  CrossIcon,
  FeedbackHeartIcon,
  LibraryIcon2,
  PlusIcon,
  SettingsIcon,
  UpArrowIcon,
  AttachIcon,
  CopyIcon,
  LikeIcon,
  DislikeIcon
} from '../../../components/Icons/Icons'
import { attachAlertToChat } from '../../../store/Chat/Actions/filter'
import DocumentAttachLabel from './DocumentAttachLabel'
import { cn } from '@/lib/utils'
import featureGroup from '@/config/feature-group'

const TimeStamp = (props) => {
  const { message } = props || {}
  const { created_at } = message || {}
  const classes = useStyles()
  return (
    created_at && (
      <Box className={classes.chatTimeStamp}>
        {moment.utc(created_at).local().format('lll')}
      </Box>
    )
  )
}
const DocumentCard = ({ fileData, onClear, onClick }) => {
  const fileName = fileData?.document_name
  const type = fileData?.document_type
  return (
    fileName && (
      <div
        className="flex items-center gap-1 p-1 px-2 rounded-lg bg-grey-50 parent-card "
        style={{
          border: '1px solid var(--grey-200)',
          borderBottom: 0,
          borderRadius: '10px 10px 0 0'
        }}
      >
        {type && (
          <div
            onClick={onClick}
            className="grid font-medium text-white rounded-md bg-grey-800 place-content-center"
            style={{
              fontSize: '9px',
              cursor: 'pointer',
              padding: '1.5px 5px'
            }}
          >
            {_.toUpper(type)}
          </div>
        )}
        <div
          className="flex items-center justify-between"
          style={{
            flex: 1,
            overflow: 'hidden'
          }}
        >
          {fileName && (
            <EllipsisTooltip
              className="m-0 text-xs font-medium text-grey-700"
              text={fileName}
            />
          )}

          <div className="ml-2 cursor-pointer " onClick={onClear}>
            <CrossIcon className="size-3" />
          </div>
        </div>
      </div>
    )
  )
}

const SortLibrary = (props) => {
  const { filterBy = [], setFilterBy = () => {}, disabled = false } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterOptions = [
    {
      key: 'title',
      order: 'asc',
      label: 'Name (A-Z)'
    },
    {
      key: 'title',
      order: 'desc',
      label: 'Name (Z-A)'
    },
    {
      key: 'created_at',
      order: 'desc',
      label: 'Created Date (Newest)'
    },
    {
      key: 'created_at',
      order: 'asc',
      label: 'Created Date (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'asc',
      label: 'Last Modified (Oldest)'
    },
    {
      key: 'updated_at',
      order: 'desc',
      label: 'Last Modified (Newest)'
    }
  ]

  const handleFilter = (e, filter) => {
    e.stopPropagation()
    e.preventDefault()
    setFilterBy(filter)
    handleClose()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <IconButton
        className={classes.iconButton}
        disableRipple
        onClick={handleClick}
        disabled={disabled}
      >
        <SwapVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
        slotProps={{
          root: {
            style: {
              zIndex: 1302
            },
            'data-dialog-parent': 'chat'
          }
        }}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={
              filterBy.key === option.key && filterBy.order === option.order
            }
            onClick={(e) =>
              handleFilter(e, { key: option.key, order: option.order })
            }
          >
            <Box className={classes.hideMenuListText}>{option.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

const LibraryPrompt = (props) => {
  const {
    prompts = [],
    promptSearch = '',
    promptFilter = {},
    handlePromptSelection = () => {},
    showCreateNew = false,
    createNew = () => {},
    favPrompts = [],
    handlePromptFav = () => {},
    promptType = '',
    isAdmin = false
  } = props

  const [tagOptions, setTagOptions] = useState({})
  const [selectedTag, setSelectedTag] = useState({
    key: 'All',
    value: '@default'
  })
  const [filteredPrompts, setFilteredPrompts] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const tags = {}
    prompts.forEach((item) => {
      Object.keys(item.tags || {}).forEach((tag) => {
        if (!tags[tag]) {
          tags[tag] = []
        }
        tags[tag] = [...new Set([...tags[tag], ...item.tags[tag]])]
      })
    })
    setTagOptions(tags)
  }, [prompts])

  const sortData = (data) => {
    return data.sort((a, b) => {
      const key = promptFilter.key
      let order = promptFilter.order
      let comparisonA = a[key]
      let comparisonB = b[key]
      if (key === 'created_at' || key === 'updated_at') {
        comparisonA = new Date(a[key])
        comparisonB = new Date(b[key])
        if (comparisonA.getTime() === comparisonB.getTime()) {
          comparisonA = a.title?.toLowerCase()
          comparisonB = b.title?.toLowerCase()
          order = 'asc'
        }
      } else if (key === 'title') {
        comparisonA = a[key]?.toLowerCase()
        comparisonB = b[key]?.toLowerCase()
      }
      if (comparisonA < comparisonB) {
        return order === 'desc' ? 1 : -1
      }
      if (comparisonA > comparisonB) {
        return order === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  useEffect(() => {
    let filteredPrompts = prompts.filter((item) => {
      if (selectedTag.key === 'All' && selectedTag.value === '@default') {
        return true
      } else if (selectedTag.value === '@tag') {
        return Object.keys(item.tags || {}).includes(selectedTag.key)
      } else {
        return (
          Object.keys(item.tags || {}).includes(selectedTag.key) &&
          item.tags[selectedTag.key].includes(selectedTag.value)
        )
      }
    })

    filteredPrompts = filteredPrompts.filter((item) => {
      if (promptSearch === '') {
        return true
      }
      return (
        item.title.toLowerCase().includes(promptSearch.toLowerCase()) ||
        item.prompt.toLowerCase().includes(promptSearch.toLowerCase())
      )
    })

    filteredPrompts = sortData(filteredPrompts)

    setFilteredPrompts(filteredPrompts)
  }, [promptSearch, promptFilter, selectedTag, prompts])

  return (
    <Box className={classes.promptLibraryWrapper}>
      <List sx={{ padding: '0px' }} className={classes.promptTags}>
        <ListItem disablePadding>
          <ListItemButton
            selected={
              selectedTag.key === 'All' && selectedTag.value === '@default'
            }
            onClick={() =>
              setSelectedTag({
                key: 'All',
                value: '@default'
              })
            }
          >
            <ListItemText
              primary={
                <Box className={classes.promptTag} sx={{ fontSize: '16px' }}>
                  All
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
        {Object.keys(tagOptions).map((tag, index) => {
          return (
            <Box key={index} sx={{ marginTop: '5px' }}>
              <ListItem disablePaddin>
                <ListItemButton
                  selected={
                    selectedTag.key === tag && selectedTag.value === '@tag'
                  }
                  onClick={() =>
                    setSelectedTag({
                      key: tag,
                      value: '@tag'
                    })
                  }
                >
                  <ListItemText
                    primary={<Box className={classes.promptTag}>{tag}</Box>}
                  />
                </ListItemButton>
              </ListItem>
              {tagOptions[tag].map((item, index) => {
                return (
                  <ListItem disablePadding key={index}>
                    <ListItemButton
                      selected={
                        selectedTag.key === tag && selectedTag.value === item
                      }
                      onClick={() =>
                        setSelectedTag({
                          key: tag,
                          value: item
                        })
                      }
                    >
                      <ListItemText
                        primary={
                          <Box className={classes.promptTagValue}>{item}</Box>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </Box>
          )
        })}
      </List>
      <Box className={classes.promptContent}>
        <Container>
          <Section overFlow>
            <Box sx={{ marginTop: '15px' }}>
              {_.isEmpty(filteredPrompts) ? (
                <Box
                  className={classes.promptContainer}
                  sx={{ textAlign: 'center' }}
                >
                  <Box className={classes.noPrompt}>No prompts found</Box>
                  {showCreateNew && isAdmin && (
                    <Box sx={{ marginTop: '15px' }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          createNew()
                        }}
                      >
                        Create Prompts
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                filteredPrompts.map((item, index) => {
                  const {
                    title,
                    description = '',
                    prompt = '',
                    id,
                    disabled = false
                  } = item
                  return (
                    <Box
                      className={
                        disabled
                          ? clsx(
                              classes.promptContainer,
                              classes.promptContainerDisabled
                            )
                          : classes.promptContainer
                      }
                      key={id}
                    >
                      <Box className={classes.promptWrapperOver}>
                        <Box sx={{ flex: 1 }}>
                          <Box className={classes.promptTitle}>{title}</Box>
                          <Box className={classes.prompt}>
                            <TextLineLimiter
                              content={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: textToHtml(prompt)
                                  }}
                                />
                              }
                              limit={3}
                              wordBreak={'break-word'}
                              indicatorStyle={{
                                color: 'blue',
                                cursor: 'pointer',
                                fontStyle: 'normal',
                                fontWeight: '100',
                                fontSize: '12px'
                              }}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <CopyButton text={prompt} isPrompt />
                        </Box>
                        {isAdmin && (
                          <Box
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                          >
                            <Tooltip
                              title={
                                favPrompts.includes(item.id)
                                  ? 'Remove from favorites'
                                  : 'Add to favorites'
                              }
                            >
                              <IconButton
                                className={classes.favIconWrapper}
                                disabled={disabled}
                                disableRipple
                                onClick={() => handlePromptFav(id, promptType)}
                              >
                                {favPrompts.includes(item.id) ? (
                                  <StarIcon />
                                ) : (
                                  <StarBorderIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <Box>
                          <Box
                            onClick={() => {
                              handlePromptSelection(item)
                            }}
                            className={classes.promptButton}
                          >
                            <Box className={classes.usePrompt}>Use Prompt</Box>
                          </Box>
                        </Box>
                      </Box>

                      <Divider />
                    </Box>
                  )
                })
              )}
            </Box>
          </Section>
        </Container>
      </Box>
    </Box>
  )
}

const findNode = (id, nestedObject) => {
  function recursiveSearch(nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        return nodes[i]
      }
      if (nodes[i].children) {
        const result = recursiveSearch(nodes[i].children)
        if (result) {
          return result
        }
      }
    }
    return null
  }
  return recursiveSearch(nestedObject)
}

const ChatFilterTag = (props) => {
  const { message, voiceConfigs = [] } = props || {}
  const [dataMap, setDataMap] = useState({})
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const collectionState = useSelector((state) => state.resource?.collections)
  useEffect(() => {
    const { data } = message || {}
    const { filter } = data || {}
    const { filter_options = {} } = filter || {}
    let {
      collections = {},
      tags = [],
      tag_dates = [],
      index_type = {},
      voice_id
    } = filter_options || {}
    tags = tags || []
    tag_dates = tag_dates || []
    collections = collections || {}
    index_type = index_type || {}

    const map = {}
    if (voice_id) {
      const voice = voiceConfigs?.find((item) => item.id === voice_id)
      if (voice) {
        map.Voice = {
          condition: ':',
          values: [voice?.title]
        }
      }
    }

    if (!_.isArray(index_type) && !_.isEmpty(index_type)) {
      let type = index_type?.values
      type = type || []
      type = type.map((item) => {
        return _.startCase(item)
      })
      let { condition } = index_type || {}
      condition = condition || 'is'
      map.source_type = {
        condition,
        values: type
      }
    }

    tag_dates?.forEach((item, index) => {
      const { key, start, end } = item || {}
      let { condition } = item || {}
      condition = condition || 'is'
      condition = condition === 'is' ? 'is between' : 'is not between'
      map[key] = {
        condition,
        values: [`${moment(start).format('ll')} - ${moment(end).format('ll')}`]
      }
    })
    const tags_values = {}
    tags.forEach((item) => {
      const { key, condition, values, labels } = item
      if (key) {
        tags_values[key] = {
          condition: condition || 'is',
          tags: values,
          labels
        }
      }
    })
    Object.keys(tagsCenterStateTags || {}).forEach((k, index) => {
      const { data = [] } = tagsCenterStateTags[k] || {}
      data.forEach((element) => {
        if (tags_values[k]?.tags?.includes(element.id) && element.value) {
          if (tags_values[k].values) {
            tags_values[k].values.push(element.value)
          } else {
            tags_values[k].values = [element.value]
          }
        }
      })
    })

    Object.keys(tags_values || {}).forEach((key, index) => {
      map[key] = {
        condition: tags_values[key].condition,
        values: tags_values[key].labels
          ? tags_values[key].labels
          : tags_values[key].values
      }
    })
    if (!_.isArray(collections) && !_.isEmpty(collections)) {
      let collection_values = []
      const { condition, values, labels } = collections || {}
      if (!_.isEmpty(labels)) {
        collection_values = labels
      } else {
        values?.forEach((item) => {
          const node = findNode(item, collectionState)
          if (node) {
            collection_values.push(node.name)
          }
        })
      }
    }
    setDataMap(map)
  }, [message, tagsCenterStateTags])

  const classes = useStyles()
  return (
    <RestrictContentOverFlow>
      {Object.keys(dataMap || {}).map((key, index) => {
        return (
          <>
            <Box key={index} className={classes.chatFiltersText}>
              {key.includes('_') ? _.startCase(key) : key}{' '}
              {dataMap[key].condition}{' '}
              {dataMap[key]?.values?.map(
                (item, index) =>
                  index === 0 && (
                    <span key={index}>
                      {item}
                      {dataMap[key]?.values.length > 1 && (
                        <Tooltip
                          arrow
                          title={dataMap[key]?.values.slice(1).join(', ')}
                        >
                          <span> +{dataMap[key]?.values.length - 1}</span>
                        </Tooltip>
                      )}
                    </span>
                  )
              )}
            </Box>
          </>
        )
      })}
    </RestrictContentOverFlow>
  )
}

const CopyButton = ({ text, isPrompt = false }) => {
  const [isCopying, setIsCopying] = useState(false)
  const handleCopy = (e) => {
    if (isPrompt) {
      trackEvent(mixpanelEvents.CHAT_PROMPT_COPIED, 'SUCCESS', {}, {})
    } else {
      trackEvent(mixpanelEvents.CHAT_CONTENT_COPIED, 'SUCCESS', {}, {})
    }
    e.stopPropagation()
    e.preventDefault()
    if (!isCopying) {
      text = text.replaceAll('\n', ' ')
      text = text.replaceAll('<br>', '\n')
      const lastIndex = text.lastIndexOf('References')
      if (lastIndex !== -1) {
        text = text.substring(0, lastIndex)
      }
      text = htmlToText(text)
      navigator.clipboard.writeText(text)
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 500)
    }
  }

  return (
    <Tooltip title={'Copy Text'}>
      <div
        className="grid p-1 rounded-md cursor-pointer hover:bg-zinc-50 place-content-center"
        disabled={isCopying}
        onClick={(e) => handleCopy(e)}
      >
        {isCopying ? (
          <DoneOutlinedIcon className="size-3.5" />
        ) : (
          <CopyIcon className="size-3.5" />
        )}
      </div>
    </Tooltip>
  )
}

const SelectButtonCustom = (props) => {
  return (
    <ButtonSelect
      v2={true}
      buttonGroupProps={{
        sx: {
          boxShadow: 'none',
          textTransform: 'uppercase'
        }
      }}
      buttonProps={{
        variant: 'outlined',
        className: 'rounded-md text-grey-700',
        sx: {
          border: 'None !important',
          padding: '5px'
        }
      }}
      menuProps={{
        sx: {
          zIndex: 9999,
          '& .MuiMenuItem-root': {
            fontSize: '13px'
          }
        }
      }}
      {...props}
    />
  )
}

export const SystemPrompt = ({ text, convertToHTML }) => {
  const classes = useStyles()
  const [showPrompt, setShowPrompt] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const handleCopy = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!isCopying) {
      navigator.clipboard.writeText(htmlToText(text.replaceAll('**', '')))
      setIsCopying(true)
      setTimeout(() => {
        setIsCopying(false)
      }, 500)
    }
  }

  return (
    text && (
      <Box className={classes.showPromptWrapper}>
        <span
          onClick={() => setShowPrompt(!showPrompt)}
          className={classes.showPrompt}
        >
          {showPrompt ? 'Hide' : 'Show'} Prompt
        </span>
        {showPrompt && (
          <span onClick={(e) => handleCopy(e)} className={classes.showPrompt}>
            {isCopying ? 'Copied' : 'Copy Prompt'}
          </span>
        )}
        {showPrompt && (
          <div
            className={classes.messageText}
            dangerouslySetInnerHTML={{ __html: convertToHTML(text) }}
            style={{ background: '#ececec' }}
          />
        )}
      </Box>
    )
  )
}

const RestrictContentOverFlow = (props) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const containerRef = useRef(null)
  const classes = useStyles()
  useEffect(() => {
    const checkOverflow = () => {
      if (!containerRef.current) return
      const { scrollHeight, clientHeight } = containerRef.current
      setIsOverflowing(scrollHeight > clientHeight)
    }

    setTimeout(checkOverflow, 100)
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  return (
    <Box>
      <Box
        ref={containerRef}
        className={
          isExpanded
            ? clsx(classes.chatFilters, classes.chatFiltersExpanded)
            : classes.chatFilters
        }
      >
        {props.children}
      </Box>
      {isOverflowing && (
        <Box className={classes.showPromptWrapper}>
          <Box
            className={classes.showPrompt}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Show Less Tags' : 'Show More Tags'}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ChatBot = (props) => {
  const {
    historyProps = {},
    chatType = 'session',
    trackerId,
    initalPrompt = '',
    relayLoading = () => {},
    handleChatAddition = () => {},
    showEmptyChatElements = false,
    prefixElements = () => {},
    textBoxPlaceholder = '',
    imageTextBoxPlaceHolder = '',
    updateFeedbackCallback = () => {},
    showHistoryByDefault = false,
    chatTextBox = '',
    showFilter = false,
    proposalIds = [],
    enableTextSelect = false,
    s3Obj = {},
    fullScreen = false,
    chatInstructions = null,
    setChatInstructions = () => {},
    messageList = [],
    setMessageList = () => {},
    history = [],
    setHistory = () => {},
    textBox = '',
    setTextBox = () => {},
    socketStatus = '',
    messageListRef = null,
    socket = null,
    streamLoadingRef = null,
    currentTrackerRef = null,
    historyRef = null,
    listRef = null,
    scrollToBottom = () => {},
    currentChatIdRef = null,
    scrollLock = false,
    voiceConfigs = null,
    setVoiceConfigs = () => {},
    domainPrompts = null,
    setDomainPrompts = () => {},
    randomPrompts = [],
    setRandomPrompts = () => {},
    voiceOptions = [],
    setVoiceOptions = () => {},
    selectedVoice = null,
    setSelectedVoice = () => {},
    defaultVoiceIndex = 0,
    setDefaultVoiceIndex = () => {},
    selectedTags = null,
    setSelectedTags = () => {},
    documentAttached = null,
    setDocumentAttached = () => {},
    chatMode = 'chat',
    setChatMode = () => {},
    selectedImage = null,
    setSelectedImage = () => {},
    imageData = '',
    setImageData = () => {},
    signerObj = {}
  } = props

  const isSuperUser = checkUserRoleSuperUser()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { SETTINGS_CHAT } = ROUTES
  // Signer

  // Controller Ref
  const spanRef = useRef(null)
  const prevContentRef = useRef('')

  // History
  const [stateLoading, setStateLoading] = useState(false)
  const [loadingHistory, setLoadingHistory] = useState(false)
  const [page, setPage] = useState(null)
  const [showHistory, setShowHistory] = useState(showHistoryByDefault)
  const [hasMoreHistory, setHasMoreHistory] = useState(true)
  const prevHistoryCountRef = useRef()

  // Disclaimer
  const disclaimer = chatInstructions?.disclaimer || ''

  // Feedback
  const [feedbackObj, setFeedbackObj] = useState({
    reaction: '',
    feedback: '',
    id: ''
  })
  const [openFeedback, setOpenFeedback] = useState(false)
  const [newFeedback, setNewFeedback] = useState('')
  const [starsValue, setStarsValue] = React.useState(0)
  const [starsHover, setStarsHover] = React.useState(-1)
  const [openChatFeedback, setOpenChatFeedback] = useState(false)

  // Tags and Filters
  const selectedTagsRef = useRef(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const selectedDocumentsRef = useRef(null)

  const loading = streamLoadingRef?.current?.[trackerId] || false

  // Text Select
  const [anchorEl, setAnchorEl] = useState(null)

  const [showPromptLibrary, setShowPromptLibrary] = useState(false)

  const selectedVoiceRef = useRef(null)

  const textBoxRef = useRef(null)

  // Resume Generate
  const [modal, showModal] = useModal()

  // Prompt Library
  const [promptSearch, setPromptSearch] = useState('')
  const [promptFilter, setPromptFilter] = useState({
    key: 'created_at',
    order: 'desc'
  })

  const [stopChatLoading, setStopChatLoading] = useState({})

  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { user_name = '', domain_id, domain_config } = domain || {}
  const promptFav = domain_config?.prompt_fav || {}
  const {
    domain_prompts: domain_prompts_fav = [],
    default_prompts: default_prompts_fav = []
  } = promptFav

  const common = useSelector((userState) => userState?.common)
  const collectionState = useSelector((state) => state.resource?.collections)
  const chatState = useSelector((state) => state.chat)
  const { documentAlert = {} } = chatState

  const { prompts: generatePrompts = [] } = common
  const [selectPrompts, setSelectPrompts] = useState([])

  const [settingsOpen, setSettingsOpen] = useState(false)
  const [showDocumentSelector, setShowDocumentSelector] = useState(false)
  const isAdmin = checkUserRoleAdmin()

  const [enableDeepSearch, setEnableDeepSearch] = useState(false)

  const enableDeepSearchRef = useRef(enableDeepSearch)

  useEffect(() => {
    enableDeepSearchRef.current = enableDeepSearch
  }, [enableDeepSearch])

  useEffect(() => {
    if (!generatePrompts) {
      dispatch(fetchPrompts())
    } else {
      const prompts = generatePrompts.filter(
        (item) => item.promptType === 'chat'
      )
      setSelectPrompts(prompts)
    }
  }, [generatePrompts])

  const handleSelect = (event, message, index, isMessage) => {
    if (!enableTextSelect) return
    const selection = window.getSelection()
    const selectedText = selection.toString().trim()
    if (selectedText !== '') {
      const range = selection.getRangeAt(0)
      const boundingRect = range.getBoundingClientRect()
      const position = {
        top: boundingRect.bottom + window.scrollY,
        left: boundingRect.left + window.scrollX + boundingRect.width / 2
      }
      setAnchorEl({
        position,
        selectedText,
        edit: false,
        message,
        index,
        isMessage
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const md = markdownit({
    html: true
  })

  const preprocessMarkdown = (markdownText) => {
    return markdownText.replace(/<br>/g, '  \n')
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    markdownText = preprocessMarkdown(markdownText)
    return md.render(markdownText)
  }

  useEffect(() => {
    currentTrackerRef.current = trackerId
    setShowHistory(showHistoryByDefault)
    setMessageList([])
    setStateLoading(true)
    scrollToBottom(true)
  }, [trackerId])

  useEffect(() => {
    messageListRef.current = messageList
  }, [messageList])

  useEffect(() => {
    historyRef.current = history
  }, [history])

  useEffect(() => {
    selectedTagsRef.current = selectedTags
  }, [selectedTags])

  useEffect(() => {
    selectedDocumentsRef.current = documentAttached
  }, [documentAttached])

  useEffect(() => {
    selectedVoiceRef.current = selectedVoice
  }, [selectedVoice])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const { data = [], type, ...rest } = tagsCenterStateTags[key] || {}
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })
    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  const setPrevFilters = (chats) => {
    const lastChat = chats[chats.length - 1]
    const { data = {} } = lastChat || {}
    const { filter = {} } = data || {}
    const { filter_options = {} } = filter || {}
    let {
      tags = [],
      tag_dates = [],
      collections = {},
      index_type = {},
      voice_id
    } = filter_options || {}
    const selectedTags = {}
    const tag_values = []
    tags.forEach((item) => {
      const { key, condition, values } = item
      const options = tagsOptions[key]?.values || []
      const valuesArray = []
      values?.forEach((item) => {
        const option = options.find((option) => option.value === item)
        if (option) {
          valuesArray.push(option)
        }
      })
      if (key) {
        tag_values.push({
          key,
          condition: condition || 'is',
          values: valuesArray
        })
      }
    })
    tag_dates.forEach((item) => {
      const { key } = item
      let { condition } = item
      condition = condition || 'is'
      condition = condition === 'is' ? 'is between' : 'is not between'
      tag_values.push({
        key,
        condition,
        values: [{ ...item }]
      })
    })
    if (!_.isArray(collections) && !_.isEmpty(collections)) {
      const collection_values = []
      const { condition, values } = collections || {}
      values?.forEach((item) => {
        const node = findNode(item, collectionState)
        if (node) {
          collection_values.push({
            label: node.name,
            name: node.name,
            id: item
          })
        }
      })
    }
    if (!_.isArray(index_type) && !_.isEmpty(index_type)) {
      let type = index_type?.values
      type = type || []
      type = type.map((item) => {
        return {
          value: item,
          label: _.startCase(item)
        }
      })
      let { condition } = index_type || {}
      condition = condition || 'is'
      index_type = {
        condition,
        values: type
      }
    }
    tag_values.forEach((item) => {
      const { key, condition, values } = item
      if (key) {
        selectedTags[key] = {
          condition,
          values
        }
      }
    })

    if (index_type && !_.isEmpty(index_type)) {
      selectedTags.source_type = index_type
    }
    setSelectedTags(selectedTags)
    if (voice_id && voiceConfigs) {
      const findIndex = voiceOptions.findIndex(
        (item) => item.value === voice_id
      )
      if (findIndex !== -1) {
        setDefaultVoiceIndex(findIndex)
        setSelectedVoice(voiceOptions[findIndex])
      }
    }
  }

  const addDocumentNotifications = (messages) => {
    if (!Array.isArray(messages)) return messages

    const markedMessages = [...messages]
    markedMessages.forEach((message, index) => {
      // Get document_attached object or create it if it doesn't exist
      const filter = message?.data?.filter?.filter_options || {}
      const document_attached = filter.document_attached || {}

      // Clear any existing markers first
      delete document_attached.document_start
      delete document_attached.document_end

      const currentDoc = document_attached?.document_id
      const prevDoc =
        index > 0
          ? markedMessages[index - 1]?.data?.filter?.filter_options
              ?.document_attached?.document_id
          : null
      const nextDoc =
        index < markedMessages.length - 1
          ? markedMessages[index + 1]?.data?.filter?.filter_options
              ?.document_attached?.document_id
          : null

      if (currentDoc && currentDoc !== prevDoc) {
        document_attached.document_start = true
      }

      const isLastHistory = index === markedMessages.length - 1
      const hasNextMessage = messageListRef.current[index + 1]
      const isLastMessageInChat = isLastHistory && !hasNextMessage

      // Only add document_end if:
      // 1. Current message has a document AND
      // 2. Next message has a different document
      // 3. Current message is not the last message in the chat
      if (currentDoc && currentDoc !== nextDoc && !isLastMessageInChat) {
        document_attached.document_end = true
      }

      // Update the document_attached object in the message
      if (!message.data) message.data = {}
      if (!message.data.filter) message.data.filter = {}
      if (!message.data.filter.filter_options)
        message.data.filter.filter_options = {}
      message.data.filter.filter_options.document_attached = document_attached
    })

    return markedMessages
  }

  useEffect(() => {
    if (stateLoading) {
      if (historyProps && !_.isEmpty(historyProps)) {
        const { chat } = historyProps
        if (_.isEmpty(chat)) {
          const callback = () => {
            // setTextBox('')
            setImageData({
              imageType: 'Real',
              imageSize: 512,
              imageNumber: 4
            })
            setStateLoading(false)
          }
          fetchHistory(1, callback)
        } else {
          const { chats, page_num, has_next_page } = chat
          const chatWithNotifications = addDocumentNotifications(chats)
          setHistory(_.cloneDeep(chatWithNotifications))
          setPrevFilters(chatWithNotifications)
          setHasMoreHistory(has_next_page)
          setPage(page_num)
          setTextBox('')
          setImageData({
            imageType: 'Real',
            imageSize: 512,
            imageNumber: 4
          })
          setStateLoading(false)
        }
      } else {
        setStateLoading(false)
        setHasMoreHistory(false)
        setHistory([])
        setTextBox('')
        setImageData({
          imageType: 'Real',
          imageSize: 512,
          imageNumber: 4
        })
      }
    }
  }, [stateLoading, trackerId])

  useEffect(() => {
    if (chatTextBox) {
      setTextBox(chatTextBox)
    }
  }, [chatTextBox])

  const fetchHistory = async (page, callback) => {
    setLoadingHistory(true)
    if (!page) {
      setLoadingHistory(false)
      return
    }
    try {
      const data = {
        id: trackerId,
        page_num: page,
        page_size: 10,
        type: 'session',
        time: new Date().toISOString()
      }
      const response = await getChatHistory(data)
      if (response.status === 200) {
        const { data = [] } = response
        const { chats = [], has_next_page, page_num } = data
        const signChats = chats
        const promiseChats = chats.map(async (item, index) => {
          const { data = {}, type, author } = item || {}
          const { text, error } = data || {}
          if (
            type === 'image' &&
            author === 'Them' &&
            !error &&
            text !== 'Generation Stopped by User'
          ) {
            if (!_.isEmpty(s3Obj) && text) {
              const imageUrls = text?.split(',')
              const signedUrls = []
              const promiseImages = imageUrls.map(async (url) => {
                const signed = await getSignedUrl(url, s3Obj)
                signedUrls.push(signed)
              })
              await Promise.all(promiseImages)
              const obj = {
                ...item,
                data: {
                  ...data,
                  imageUrls: signedUrls
                }
              }
              signChats[index] = obj
            } else {
              const obj = {
                ...item,
                data: {
                  ...data,
                  text: 'Unable to load images'
                }
              }
              signChats[index] = obj
            }
          }
        })
        await Promise.all(promiseChats)
        if (currentTrackerRef?.current === trackerId) {
          setHasMoreHistory(has_next_page)
          if (page_num === 1) {
            const chatWithNotifications = addDocumentNotifications(signChats)
            setHistory(chatWithNotifications)
            setPrevFilters(chatWithNotifications)
          } else {
            setHistory((prev) => {
              const newHistory = [...signChats, ...prev]
              const chatWithNotifications = addDocumentNotifications(newHistory)
              return chatWithNotifications
            })
          }
          setPage(page)
        }
        handleChatAddition({ ...data, chats: signChats })
        callback && callback()
      } else {
        toast.error('Unable to fetch chat history')
        setHasMoreHistory(false)
      }
    } catch (error) {
      setHasMoreHistory(false)
    }
    setLoadingHistory(false)
  }

  const { regenerateTitle = false } = historyProps || {}

  const trackChatInit = (id, chatId, newMessage, trackerId) => {
    let initType = ''
    if (chatType === 'session') {
      if (trackerId === 'new') {
        if (initalPrompt) {
          initType = eventMapping.CHAT_SESSION_SEARCH
        } else {
          initType = eventMapping.CHAT_NEW_SESSION
        }
      } else {
        initType = eventMapping.CHAT_EXISTING_SESSION
      }
    }
    const additionalParams = {
      chat_id: chatId,
      chat_type: 'session',
      chat_prompt: newMessage,
      chat_tracker_id: id
    }
    if (initType) {
      trackEvent(initType, 'SUCCESS', {}, additionalParams)
    }
  }

  const removeBraces = (text) => {
    return text.replace(/[{()}]/g, '')
  }

  function extractJSONObjects(input) {
    const jsonObjects = []
    const regex = /(\{.*?\})/g

    let match
    while ((match = regex.exec(input)) !== null) {
      try {
        jsonObjects.push(JSON.parse(match[1]))
      } catch (error) {
        console.error('Invalid JSON:', match[1])
      }
    }

    return jsonObjects
  }

  const handleChatInit = async (
    newMessage,
    trackerId,
    chatMode = 'chat',
    imageData = null,
    resumeData = {}
  ) => {
    const newId = uuid()
    const chatId = uuid()
    currentChatIdRef.current = chatId
    const id = trackerId === 'new' ? newId : trackerId
    const msg = removeBraces(newMessage)
    const newMessageHtml = msg
    newMessage = htmlToText(msg)
    trackChatInit(id, chatId, newMessage, trackerId)
    const tag_values = []
    const tag_dates = []
    let index_type = {}
    const selectedTags = selectedTagsRef.current
    const selectedVoice = selectedVoiceRef.current
    const selectedDocument = selectedDocumentsRef.current

    Object.keys(selectedTags || {}).forEach((key, index) => {
      if (key === 'source_type') {
        const { condition, values = [] } = selectedTags[key] || {}
        const value = values.map((item) => item.value)
        index_type = { condition: condition || 'is', values: value }
      } else {
        const tags = []
        const labels = []
        let { condition, values = [] } = selectedTags[key] || {}
        values = values || []
        values.forEach((item) => {
          if (item?.type?.includes('date')) {
            tag_dates.push({ ...item, condition })
          } else {
            tags.push(item.value)
          }
        })
        if (tags.length > 0) {
          tag_values.push({
            key,
            condition,
            values: tags,
            labels
          })
        }
      }
    })

    let document_start = false
    if (!_.isEmpty(selectedDocument)) {
      // Check if this document is different from the last message's document
      const lastMessage =
        messageListRef.current[messageListRef.current.length - 1] ||
        history[history.length - 1]
      const lastDocId =
        lastMessage?.data?.filter?.filter_options?.document_attached
          ?.document_id
      const newDocId = selectedDocument.document_id

      // Only add document_start if:
      // 1. This is a new document (different from last message)
      // 2. OR there is no last document
      if (!lastDocId || lastDocId !== newDocId) {
        document_start = true
      }
    }

    const filter = {
      tags: tag_values,
      tag_dates,
      index_type,
      document_attached: selectedDocument || {},
      voice_id: selectedVoice?.value || null
    }
    if (selectedDocument?.document_type === 'collection') {
      const collectionIds = {
        condition: 'is',
        values: [selectedDocument?.document_id]
      }
      filter.collections = collectionIds
    }
    if (
      !_.isEmpty(tag_values) ||
      !_.isEmpty(tag_dates) ||
      !_.isEmpty(index_type) ||
      !_.isEmpty(selectedDocument)
    ) {
      trackEvent(
        mixpanelEvents.CHAT_FILTER_APPLIED,
        'SUCCESS',
        {},
        { ...filter, chat_id: chatId, session_id: id }
      )
    }
    if (selectedVoice?.value) {
      trackEvent(
        mixpanelEvents.CHAT_VOICE_APPLIED,
        'SUCCESS',
        {},
        {
          voice_id: selectedVoice?.value,
          chat_id: chatId,
          session_id: id,
          'Feature Group': featureGroup.CHAT_V2
        }
      )
    }
    if (newMessage.trim()) {
      const newMessageList = [...messageList]
      let objReq = {}
      let objReqIndex = -1

      const previousMessage =
        newMessageList[newMessageList?.length - 1] ||
        history[history?.length - 1]
      if (previousMessage) {
        const previousDocId =
          previousMessage?.data?.filter?.filter_options?.document_attached
            ?.document_id
        const currentDocId = selectedDocument?.document_id

        // Check if previous message has a different document_id or no current document_id
        if (previousDocId !== currentDocId || !currentDocId) {
          // Helper function to update document attached properties
          const updateDocumentAttached = (message, docEnd) => {
            if (!message?.data?.filter?.filter_options?.document_attached)
              return message

            return {
              ...message,
              data: {
                ...message.data,
                filter: {
                  ...message.data.filter,
                  filter_options: {
                    ...message.data.filter.filter_options,
                    document_attached: {
                      ...message.data.filter.filter_options.document_attached,
                      document_end: docEnd
                    }
                  }
                }
              }
            }
          }

          // Update document_end flags in reverse order
          for (let i = newMessageList.length - 1; i >= 0; i--) {
            const msg = newMessageList[i]
            const docAttached =
              msg?.data?.filter?.filter_options?.document_attached

            if (docAttached?.document_end) {
              newMessageList[i] = updateDocumentAttached(msg, false)
            }

            if (
              docAttached?.document_id === previousDocId ||
              !docAttached?.document_id ||
              docAttached?.document_start
            ) {
              break
            }
          }

          // Set document_end on previous message
          newMessageList[newMessageList.length - 1] = updateDocumentAttached(
            previousMessage,
            true
          )
        }
      }

      if (currentTrackerRef.current === trackerId) {
        objReq = {
          id: chatId,
          type: 'text',
          author: 'Me',
          data: {
            text: newMessageHtml,
            filter: showFilter
              ? {
                  filter_options: {
                    ...filter,
                    document_attached: {
                      ...filter?.document_attached,
                      document_start
                    }
                  }
                }
              : null
          },
          created_at: new Date().toUTCString()
        }
        objReqIndex = newMessageList.length
        if (trackerId !== 'new') {
          newMessageList.push(objReq)
          setMessageList(newMessageList)
          scrollToBottom(false, 100)
        }
      }
      let updateData = { chats: [objReq], count: 1, id }
      let regTitle = regenerateTitle
      if (trackerId === 'new') {
        const tag_values = []
        const tag_dates = []
        let index_type = {}
        Object.keys(selectedTags || {}).forEach((key, index) => {
          if (key === 'source_type') {
            const { condition, values = [] } = selectedTags[key] || {}
            const value = values.map((item) => item.value)
            index_type = { condition: condition || 'is', values: value }
          } else {
            const tags = []
            let { condition, values = [] } = selectedTags[key] || {}
            values = values || []
            values.forEach((item) => {
              if (item?.type?.includes('date')) {
                tag_dates.push({ ...item, condition })
              } else {
                tags.push(item.value)
              }
            })
            if (tags.length > 0) {
              tag_values.push({
                key,
                condition,
                values: tags
              })
            }
          }
        })
        updateData = {
          data: {
            [id]: {
              id,
              title: newMessage,
              created_at: new Date().toISOString(),
              filter: {
                tags: tag_values,
                tag_dates,
                index_type
              },
              chat: {
                chats: [objReq],
                count: 1,
                id
              }
            }
          },
          id: trackerId,
          newId: id
        }
        regTitle = false
      }
      handleChatAddition(updateData, regTitle)
      scrollToBottom(scrollLock.current)
      const referrer_id = trackerId === 'new' ? newId : trackerId
      try {
        streamLoadingRef.current = {
          ...streamLoadingRef.current,
          [id]: chatId
        }

        if (chatMode === 'image') {
          let { imageType, imageSize, imageNumber } = imageData
          imageType = imageType?.value || 'Real'
          imageSize = imageSize?.value || 512
          imageNumber = imageNumber?.value || 4
          const payload = {
            chat_id: chatId,
            chat_type: 'session',
            referrer_id,
            prompt: newMessage,
            resolution: imageSize,
            num_images: imageNumber,
            image_type: imageType
          }
          const obj = {
            id: chatId,
            type: 'image',
            author: 'Them',
            created_at: new Date().toUTCString(),
            loading: true,
            data: {}
          }
          newMessageList.push(obj)
          const messageIndex = newMessageList.length - 1
          const res = await generateImages(payload)
          const currentObj = messageListRef?.current?.[messageIndex]
          if (!currentObj?.userstopped) {
            if (res.status === 200) {
              trackEvent(mixpanelEvents.CHAT_IMAGE_GENERATED, 'SUCCESS', {}, {})
              const { image_url, system_prompt } = res?.data || {}
              const obj = {
                id: chatId,
                type: 'image',
                author: 'Them',
                created_at: new Date().toUTCString(),
                loading: false,
                data: {
                  text: image_url,
                  imageUrls: image_url.split(','),
                  systemPrompt: system_prompt,
                  inputText: newMessage,
                  filter: showFilter ? { filter_options: { ...filter } } : null
                }
              }
              newMessageList[messageIndex] = obj
              const data = { chats: [obj], count: 1, id }
              handleChatAddition(data)
              if (currentTrackerRef.current === id) {
                setMessageList(newMessageList)
                scrollToBottom(false, 100)
                setTextBox('')
              }
              streamLoadingRef.current = {
                ...streamLoadingRef.current,
                [id]: false
              }
            } else {
              trackEvent(mixpanelEvents.CHAT_IMAGE_GENERATED, 'FAILED', {}, {})
              const { exception } = res?.response?.data || {}
              const text =
                'Error during Image Generation\nSomething went wrong! Please try again.'
              console.log(exception)
              const obj = {
                id: chatId,
                type: 'text',
                author: 'Them',
                created_at: new Date().toUTCString(),
                data: {
                  text,
                  error: exception,
                  inputText: newMessage,
                  filter: showFilter ? { filter_options: { ...filter } } : null
                }
              }
              newMessageList.push(obj)
              const data = { chats: [obj], count: 1, id }
              handleChatAddition(data)

              if (currentTrackerRef.current === id) {
                setMessageList(newMessageList)
                setTextBox('')
              }
              streamLoadingRef.current = {
                ...streamLoadingRef.current,
                [id]: false
              }
            }
          }
        } else if (chatMode === 'resume') {
          let prompt = ''
          let remainingText = ''
          let completeText = ''
          const req = {
            referrer_id,
            chat_type: 'session',
            chat_id: chatId,
            user_message: newMessage,
            forwardPayload: resumeData.data,
            is_resume: true,
            prompt: '',
            document_id: '',
            is_analytics: false,
            requestType:
              resumeData.type === 'people' ? 'resume' : 'projectGenerate'
          }
          const url = process.env.REACT_APP_CHAT_URL || ''
          const apiUrl = new URL(url)
          const currentSession = await Auth.currentSession()
          const token = currentSession?.getAccessToken()?.getJwtToken()
          const signedUrl = await signerObj.sign({
            method: 'POST',
            hostname: apiUrl.host,
            path: apiUrl.pathname,
            body: JSON.stringify(req),
            protocol: apiUrl.protocol,
            headers: {
              'Content-Type': 'application/json',
              host: apiUrl.hostname,
              userAuth: 'Bearer ' + token
            }
          })
          const obj = {
            id: chatId,
            type: 'text',
            author: 'Them',
            loading: true,
            created_at: new Date().toUTCString(),
            data: {
              text: ' ',
              systemPrompt: '',
              inputText: newMessage,
              startedChat: false,
              filter: showFilter ? { filter_options: { ...filter } } : null
            }
          }
          handleChatAddition(
            {
              session_id: id,
              chat_id: chatId,
              message: ' ',
              type: 'chat'
            },
            false,
            true
          )
          newMessageList.push(obj)
          const messageIndex = newMessageList.length - 1
          setMessageList(newMessageList)
          const response = await fetch(`${url}`, { ...signedUrl })
          let isMessageEnd = false
          if (response.status === 200 && response.body) {
            const reader = response.body.getReader()
            while (true) {
              const currentObj = messageListRef?.current?.[messageIndex]
              if (currentObj?.userstopped) {
                break
              }
              const { done, value } = await reader.read()
              if (done) {
                break
              }
              let text = new TextDecoder().decode(value)
              if (text.includes('__END_OF_CHAT__')) {
                isMessageEnd = true
                remainingText = text.split('__END_OF_CHAT__')[0]
              }
              if (!isMessageEnd) {
                if (text.includes('\n')) {
                  const newlineMatches = text.match(/\n/g)
                  const newLines =
                    newlineMatches.length === 1 ? '<br>' : '<br><br>'
                  text = text.replace('\n', newLines)
                }
                completeText = completeText + text
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: convertToHTML(completeText),
                    type: 'chat_full'
                  },
                  false,
                  true
                )
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: '__START_OF_CHAT__',
                    type: 'chat_status'
                  },
                  false,
                  true
                )
                if (currentTrackerRef.current === id) {
                  newMessageList[messageIndex].data.text =
                    convertToHTML(completeText)
                  setMessageList(newMessageList)
                }
                scrollToBottom(false, 100)
              } else {
                if (remainingText) {
                  if (remainingText.includes('\n')) {
                    const newlineMatches = remainingText.match(/\n/g)
                    const newLines =
                      newlineMatches.length === 1 ? '<br>' : '<br><br>'
                    remainingText = remainingText.replace('\n', newLines)
                  }
                  completeText = completeText + remainingText
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: convertToHTML(completeText),
                      type: 'chat_full'
                    },
                    false,
                    true
                  )
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.text =
                      convertToHTML(completeText)
                    newMessageList[messageIndex].loading = false
                    setMessageList(newMessageList)
                  }
                  scrollToBottom(false, 100)
                }
                if (text.includes('__END_OF_CHAT__')) {
                  prompt = prompt + text.split('__END_OF_CHAT__')[1]
                } else {
                  prompt = prompt + text
                }
                if (prompt) {
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: prompt,
                      type: 'prompt'
                    },
                    false,
                    true
                  )
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.systemPrompt = prompt
                    setMessageList(newMessageList)
                  }
                }
                handleChatAddition(
                  {
                    session_id: id,
                    chat_id: chatId,
                    message: '__END_OF_CHAT__',
                    type: 'chat_status'
                  },
                  false,
                  true
                )
                scrollToBottom(false, 100)
              }
            }
            streamLoadingRef.current = {
              ...streamLoadingRef.current,
              [id]: false
            }
          }
        } else if (enableDeepSearchRef.current) {
          streamLoadingRef.current = {
            ...streamLoadingRef.current,
            [id]: true
          }
          const currentSession = await Auth.currentSession()
          const token = currentSession?.getAccessToken()?.getJwtToken()
          const req = {
            message: newMessage,
            stream_tokens: true,
            thread_id: id,
            token,
            prompt: newMessage,
            referrer_id,
            chat_type: 'session',
            chat_id: chatId,
            proposal_ids: proposalIds,
            filter_options: filter,
            input_type: 'text'
          }
          const url =
            'https://cdr5whotyiw2ssjj2xvaflvdha0lvhtd.lambda-url.us-east-1.on.aws/stream'
          const apiUrl = new URL(url)
          const signedUrl = await signerObj.sign({
            method: 'POST',
            hostname: apiUrl.host,
            path: apiUrl.pathname,
            body: JSON.stringify(req),
            protocol: apiUrl.protocol,
            headers: {
              'Content-Type': 'application/json',
              host: apiUrl.hostname,
              userAuth: 'Bearer ' + token
            }
          })
          const obj = {
            id: chatId,
            type: 'text',
            author: 'Them',
            loading: true,
            created_at: new Date().toUTCString(),
            data: {
              text: ' ',
              systemPrompt: '',
              status: [],
              inputText: newMessage,
              startedChat: false,
              filter: showFilter ? { filter_options: { ...filter } } : null
            }
          }
          handleChatAddition(
            {
              session_id: id,
              chat_id: chatId,
              message: ' ',
              type: 'chat'
            },
            false,
            true
          )
          newMessageList.push(obj)
          const messageIndex = newMessageList.length - 1
          setMessageList(newMessageList)
          const response = await fetch(`${url}`, { ...signedUrl })
          if (response.status === 200 && response.body) {
            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let text = ''
            while (true) {
              const currentObj = messageListRef?.current?.[messageIndex]
              if (currentObj?.userstopped) {
                break
              }
              const { done, value } = await reader.read()
              if (done) {
                break
              }
              const streamedData = decoder.decode(value, { stream: true })
              const parsedData = extractJSONObjects(streamedData)
              parsedData.forEach((data) => {
                const { type, content } = data || {}
                if (type === 'chat_status') {
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: content,
                      type: 'chat_status'
                    },
                    false,
                    true
                  )
                  if (content.includes('__START_OF_CHAT__')) {
                    handleChatAddition(
                      {
                        session_id: id,
                        chat_id: chatId,
                        message: 'Thinking...',
                        type: 'status'
                      },
                      false,
                      true
                    )
                    if (currentTrackerRef.current === id) {
                      newMessageList[messageIndex].data.status = ['Thinking...']
                      setMessageList(newMessageList)
                    }
                  } else if (content.includes('__END_OF_CHAT__')) {
                    newMessageList[messageIndex].loading = false
                    setMessageList(newMessageList)
                    streamLoadingRef.current = {
                      ...streamLoadingRef.current,
                      [id]: false
                    }
                    setTextBox('')
                  } else {
                    newMessageList[messageIndex].data.status.push(message)
                    setMessageList(newMessageList)
                  }
                } else if (type === 'chat') {
                  text = text + content
                  handleChatAddition(
                    {
                      session_id: id,
                      chat_id: chatId,
                      message: convertToHTML(content),
                      type: 'chat'
                    },
                    false,
                    true
                  )
                  if (currentTrackerRef.current === id) {
                    newMessageList[messageIndex].data.startedChat = true
                    newMessageList[messageIndex].data.text = convertToHTML(text)
                    setMessageList(newMessageList)
                  }
                  scrollToBottom(false, 100)
                }
              })
              handleChatAddition(
                {
                  session_id: id,
                  chat_id: chatId,
                  message: convertToHTML(text),
                  type: 'chat_full'
                },
                false,
                true
              )
              if (currentTrackerRef.current === id) {
                newMessageList[messageIndex].data.text = convertToHTML(text)
                setMessageList(newMessageList)
              }
              scrollToBottom(false, 100)
            }
            streamLoadingRef.current = {
              ...streamLoadingRef.current,
              [id]: false
            }
          }
        } else {
          const data = {
            prompt: newMessage,
            referrer_id,
            chat_type: 'session',
            chat_id: chatId,
            proposal_ids: proposalIds,
            filter_options: filter,
            input_type: 'text'
          }
          trackEvent(
            mixpanelEvents.CHAT_MESSAGE_SENT,
            'SUCCESS',
            {},
            { message: newMessage, chat_id: chatId, session_id: id }
          )
          const query = {
            action: 'stream',
            query: data
          }
          if (!socket) {
            return
          }
          socket.send(JSON.stringify(query))
          const obj = {
            id: chatId,
            type: 'text',
            author: 'Them',
            created_at: new Date().toUTCString(),
            loading: true,
            data: {
              text: ' ',
              systemPrompt: '',
              inputText: '',
              error: '',
              status: [],
              startedChat: false,
              filter: showFilter ? { filter_options: { ...filter } } : null
            }
          }
          newMessageList.push(obj)
          handleChatAddition(
            {
              session_id: id,
              chat_id: chatId,
              message: showFilter ? { filter_options: { ...filter } } : null,
              type: 'filter'
            },
            false,
            true
          )
          handleChatAddition(
            {
              session_id: id,
              chat_id: chatId,
              message: ' ',
              type: 'chat'
            },
            false,
            true
          )
          if (currentTrackerRef.current === id) {
            setMessageList(newMessageList)
          }
        }
      } catch (error) {
        console.log('error', error)
        const obj = {
          id: chatId,
          type: 'text',
          author: 'Them',
          created_at: new Date().toUTCString(),
          data: {
            text: 'Error\nSomething went wrong! Please try again.',
            error,
            inputText: newMessage,
            filter: showFilter ? { filter_options: { ...filter } } : null
          }
        }
        newMessageList.push(obj)
        const data = { chats: [obj], count: 1, id }
        handleChatAddition(data)
        if (currentTrackerRef.current === id) {
          setMessageList(newMessageList)
          setTextBox('')
        }
        streamLoadingRef.current = {
          ...streamLoadingRef.current,
          [id]: false
        }
      }
    }
  }

  useEffect(() => {
    const prevHistoryCount = prevHistoryCountRef.current
    const count = history.length
    const focusIndex = count - prevHistoryCount
    if (focusIndex < 1 || page === 1) {
      const elementId = 'message' + 0
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView()
      } else {
        scrollToBottom(true)
      }
    } else {
      const elementId = 'history' + focusIndex
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView()
      }
    }
    prevHistoryCountRef.current = count
  }, [history, showHistory])

  const handlePull = () => {
    setShowHistory(true)
    fetchHistory(1)
  }

  const handleStopGeneration = async () => {
    const chat_id = currentChatIdRef.current
    let partial_text = 'Generation Stopped by User'
    setStopChatLoading((prev) => {
      return {
        ...prev,
        [trackerId]: true
      }
    })
    const messageIndex = messageList.findIndex(
      (item) => item.id === chat_id && item.author === 'Them'
    )
    if (messageIndex > -1) {
      partial_text = messageList[messageIndex].data.text
      const newMessageList = [...messageList]
      newMessageList[messageIndex].loading = false
      newMessageList[messageIndex].userstopped = true
      newMessageList[messageIndex].data.partialText = partial_text
      setMessageList(newMessageList)
      handleChatAddition(
        {
          session_id: trackerId,
          chat_id,
          message: true,
          type: 'userstopped'
        },
        false,
        true
      )
    }
    partial_text = partial_text || 'Generation Stopped by User'

    const payload = {
      id: chat_id,
      partial_text
    }
    try {
      chatStop(payload)
    } catch (error) {
      console.log('error', error)
    }
    setTimeout(() => {
      streamLoadingRef.current = {
        ...streamLoadingRef.current,
        [trackerId]: false
      }
      setStopChatLoading((prev) => {
        return {
          ...prev,
          [trackerId]: false
        }
      })
    }, 500)
  }

  useEffect(() => {
    relayLoading(loading)
  }, [loading])

  const convertFromBraces = (text) => {
    if (!text) {
      return ''
    }
    const regex = /{([^}]+)}/g
    let position = 0
    const newText = text.replace(regex, (match, p1) => {
      const result = `<span class="chat-variable-span" tabindex=${position}>${p1}</span>&nbsp;`
      position++
      return result
    })
    return newText
  }

  useEffect(() => {
    const handleSpanClick = (event) => {
      if (event.target.classList.contains('chat-variable-span')) {
        prevContentRef.current = event.target.innerText
        event.target.innerHTML = '\u00A0'
        spanRef.current = event.target
        event.target.focus()
        event.target.addEventListener('blur', handleSpanBlur)
        event.target.addEventListener('keydown', handleSpanKeydown)
      }
    }

    const handleSpanBlur = (event) => {
      if (event.target.innerText.replace(/\u00A0/g, '').trim() === '') {
        event.target.innerText = prevContentRef.current
      }
      event.target.removeEventListener('blur', handleSpanBlur)
      event.target.removeEventListener('keydown', handleSpanKeydown)
    }

    const handleSpanKeydown = (event) => {
      if (event.target.innerHTML === '&nbsp;') {
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          event.target.innerText = event.key
          event.preventDefault()
          const range = document.createRange()
          range.selectNodeContents(event.target)
          range.collapse(false)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    }

    document.addEventListener('click', handleSpanClick)

    return () => {
      document.removeEventListener('click', handleSpanClick)
      if (spanRef.current) {
        spanRef.current.removeEventListener('blur', handleSpanBlur)
        spanRef.current.removeEventListener('keydown', handleSpanKeydown)
      }
    }
  }, [])

  const convertToBraces = (html) => {
    const regex = /<span class="chat-variable-span"[^>]*>(.*?)<\/span>&nbsp;/g
    let newText = html.replace(regex, (match, p1) => `{${p1}}`)
    newText = newText === '<br>' ? '' : newText
    return newText
  }

  const replaceAtIndex = (text, elementIndex, chatVariable) => {
    const regex = /{([^}]+)}/g
    let index = 0
    const newText = text.replace(regex, (match) => {
      if (index === elementIndex) {
        const replacement = `{${chatVariable}}`
        index++
        return replacement
      }
      index++
      return match
    })
    return newText
  }

  useEffect(() => {
    const responses = messageList.filter((item) => item.author === 'Them')
    const responsesLength = responses.length
    if (responsesLength !== 0 && responsesLength === 3 && !loading) {
      setOpenFeedback(true)
    }
  }, [messageList.length, loading])

  useEffect(() => {
    setOpenFeedback(false)
  }, [trackerId])

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current
    if (scrollTop === 0 && hasMoreHistory && !loadingHistory && showHistory) {
      fetchHistory(page + 1)
    }
    const bottomThreshold = 5
    const isAtBottom = scrollHeight - scrollTop - clientHeight < bottomThreshold
    if (isAtBottom) {
      scrollLock.current = true
    } else {
      scrollLock.current = false
    }
  }

  const handleFeedbackSet = async (icon, index, id, isMessageList) => {
    setFeedbackObj({
      reaction: icon,
      feedback: '',
      id,
      index
    })
    setOpenChatFeedback(true)
  }

  const handleFeedbackClose = () => {
    setOpenChatFeedback(false)
  }

  useEffect(() => {
    if (!openChatFeedback) {
      setFeedbackObj('')
    }
  }, [openChatFeedback])

  const handleSubmitFeedback = async (
    isMessageList,
    icon = '',
    id = '',
    idx = ''
  ) => {
    if (starsValue === 0) {
      toast.error('Please provide a rating before submitting feedback')
      return
    }
    const feedbackText = newFeedback || feedbackObj.feedback
    const data = {
      reaction: starsValue,
      feedback: feedbackText || '',
      id: id || feedbackObj.id
    }
    const obj = { ...feedbackObj }
    const { index = idx } = obj
    handleFeedbackClose()
    const res = await chatFeedback(data)
    setStarsValue(0)
    if (res.status !== 200) {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'FAILURE',
        {},
        { details: { reaction: icon, feedback: newFeedback } }
      )
    } else {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'SUCCESS',
        {},
        { details: { reaction: icon, feedback: newFeedback } }
      )
      if (!isMessageList) {
        const newHistory = _.cloneDeep(history)
        newHistory[index].data = {
          ...newHistory[index]?.data,
          reaction: starsValue,
          feedback: feedbackText
        }
        setHistory(newHistory)
      } else {
        const newMessageList = _.cloneDeep(messageList)
        newMessageList[index].data = {
          ...newMessageList[index]?.data,
          reaction: starsValue,
          feedback: feedbackText || ''
        }
        setMessageList(newMessageList)
      }
      const relativeIndex = isMessageList ? history.length + index : index
      updateFeedbackCallback({
        reaction: feedbackObj.reaction,
        feedback: feedbackText,
        sessionId: trackerId,
        chatIndex: relativeIndex,
        chatType: 'session'
      })
    }
  }

  const handleFeedbackChange = (event) => {
    setNewFeedback(event.target.value)
  }

  const handleCloseSessionFeedback = () => {
    setOpenFeedback(false)
    setNewFeedback('')
  }

  const handleSubmitSessionFeedback = async () => {
    const payload = {
      id: trackerId,
      feedback: newFeedback,
      reaction: starsValue,
      type: 'session'
    }
    const res = await chatFeedback(payload)
    if (res.status === 200) {
      trackEvent(
        mixpanelEvents.CHAT_FEEDBACK_SUBMITTED,
        'SUCCESS',
        {},
        { details: { reaction: starsValue, feedback: newFeedback } }
      )
    }
    setNewFeedback('')
    setOpenFeedback(false)
    setStarsValue(0)
    setStarsHover(-1)
  }

  const renderFeedback = (message, index, isMessageList) => {
    const { data, author, id } = message || {}
    if (author !== 'Them') {
      return null
    }
    const { feedback = '', reaction = '' } = data || {}

    return (
      <div className="flex gap-1">
        {!reaction && (
          <>
            <Tooltip title="Positive Feedback">
              <div
                className="grid p-1 rounded-md button-base place-content-center hover:bg-zinc-50"
                onClick={() => {
                  handleFeedbackSet('', index, id, isMessageList)
                  trackEvent(
                    mixpanelEvents.CHAT_POSITIVE_FEEDBACK_OPENED,
                    'SUCCESS',
                    {},
                    {}
                  )
                }}
              >
                <LikeIcon className="size-3.5" />
              </div>
            </Tooltip>
            <Tooltip title="Negative Feedback">
              <div
                className="grid p-1 rounded-md button-base place-content-center hover:bg-zinc-50"
                onClick={() => {
                  handleFeedbackSet('', index, id, isMessageList)
                  trackEvent(
                    mixpanelEvents.CHAT_NEGATIVE_FEEDBACK_OPENED,
                    'SUCCESS',
                    {},
                    {}
                  )
                }}
              >
                <DislikeIcon className="size-3.5" />
              </div>
            </Tooltip>
          </>
        )}

        <Dialog
          data-dialog-parent="chat"
          open={openChatFeedback}
          onClose={handleFeedbackClose}
          fullWidth
          maxWidth="md"
          className="shadow-2xl rounded-xl border-1 border-grey-400"
          sx={{
            zIndex: 1302,
            '& .MuiDialog-paper': {
              borderRadius: '1rem'
            }
          }}
        >
          <DialogTitle className="flex flex-col p-4">
            <div className="flex items-center justify-between">
              <div className="mb-1 text-base text-dark font-poppins">
                Provide additional feedback
              </div>
              <IconButton
                disableRipple
                edge="end"
                color="inherit"
                onClick={handleFeedbackClose}
                aria-label="close"
              >
                <CloseIcon className="size-4" />
              </IconButton>
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent className="p-4">
            <div className="flex items-center">
              <Rating
                name="hover-feedback"
                value={starsValue}
                onChange={(event, newValue) => {
                  // setValue(newValue)
                  setStarsValue(newValue)
                }}
                onChangeActive={(event, newHover) => {
                  // setHover(newHover)
                  setStarsHover(newHover)
                }}
                emptyIcon={
                  <StarIcon
                    style={{
                      opacity: 0.55,
                      color: 'var(--grey-400)'
                    }}
                    fontSize="32px"
                  />
                }
              />
            </div>
            <TextField
              placeholder="(Optional) You can add any specific feedback/details here"
              variant="outlined"
              fullWidth
              defaultValue={feedback}
              onChange={handleFeedbackChange}
              style={{ margin: '20px 0' }}
              multiline={true}
              minRows={3}
              maxRows={6}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0.5rem',
                  fontSize: '0.875rem'
                }
              }}
            />
            <Button2
              primary
              onClick={() => {
                handleSubmitFeedback(isMessageList)
              }}
              style={{ float: 'right' }}
            >
              Submit
            </Button2>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmitFeedback(isMessageList)}
                style={{ float: 'right' }}
              >
                Submit
              </Button> */}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  const handleToggleChatMode = (event, mode) => {
    if (mode) {
      setChatMode(mode)
    }
  }

  const handleImageOptionsSelect = (e, id) => {
    setImageData({
      ...imageData,
      [id]: e.value
    })
  }

  const handleAssetSave = async (message, url) => {
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Saving Image Into Assets'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )

    const { data } = message || {}
    const { systemPrompt = '' } = data || {}
    const payload = {
      image_url: url,
      prompt: systemPrompt
    }
    const res = await saveImagetoAsset(payload)
    toast.dismiss(toastId)
    if (res.status === 200) {
      trackEvent(mixpanelEvents.CHAT_IMAGE_SAVE_INTO_ASSETS, 'SUCCESS', {}, {})
      toast.success('Image saved to asset')
    } else {
      trackEvent(mixpanelEvents.CHAT_IMAGE_SAVE_INTO_ASSETS, 'FAILED', {}, {})
      toast.error('Error while saving image to asset')
    }
  }

  const handleDownloadImage = async (message, url, isS3Obj = false) => {
    const { id } = message || {}
    const fileName = id + '.png'
    if (isS3Obj) {
      initalizeDownload(url, fileName, s3Obj)
    } else {
      window.open(url, '_blank')
    }
  }

  const isShowEmpty =
    _.isEmpty(messageList) &&
    trackerId === 'new' &&
    !loading &&
    showEmptyChatElements

  const placeholderText =
    chatMode === 'chat' ? textBoxPlaceholder : imageTextBoxPlaceHolder

  function safeJSONParse(text) {
    try {
      return JSON.parse(text)
    } catch (e) {
      return text
    }
  }

  const renderMessage = (message, index, isMessage = false) => {
    const {
      data = {},
      type,
      author,
      id,
      loading: loadingMessage = false
    } = message || {}
    let {
      partialText,
      text,
      error,
      imageUrls = [],
      startedChat = false,
      status
    } = data || {}
    text = safeJSONParse(text)

    const { document_attached: document } = data?.filter?.filter_options || {}
    const errortext =
      type === 'image'
        ? 'Error during Image Generation\nSomething went wrong! Please try again.'
        : 'Error\nSomething went wrong! Please try again.'
    const textToRender = error
      ? errortext
      : partialText
      ? convertToHTML(partialText)
      : convertToHTML(text)
    const isThem = author === 'Them'
    if (!isMessage && isThem && text.trim() === '') {
      return null
    }
    return (
      <ListItem
        id={isMessage ? 'message' : 'history' + index}
        key={index}
        className={classes.message + ' ' + classes[`message${message.author}`]}
      >
        {document?.document_id && document?.document_start && (
          <DocumentAttachLabel documentName={document?.document_name} />
        )}
        <div
          className={cn(
            'p-2 rounded-xl text-sm px-3.5 group',
            isThem ? 'bg-white' : 'bg-grey-100 max-w-[80%] ml-auto'
            // fullScreen && isThem ? 'max-w-[80%]' : 'max-w-[calc(100%-1px)]'
          )}
        >
          <div className="">
            {isThem && loadingMessage && (
              <div className="rounded-full bg-zinc-800 animate-pulse size-2.5"></div>
            )}

            <div className="relative parent-card">
              {type === 'image' && !_.isEmpty(imageUrls) ? (
                <div className={classes.imageContainer}>
                  {imageUrls.map((url, idx) => {
                    return (
                      <Box key={idx} className={classes.imageWrapper}>
                        <ImageRender
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                            overflow: 'hidden'
                          }}
                          id={id + author + idx}
                          effect="blur"
                          src={url}
                          s3Obj={s3Obj}
                          showLoader={false}
                          overlay={
                            <Box className="overlayButtons">
                              <Tooltip title="View Image">
                                <IconButton
                                  disableRipple
                                  onClick={() =>
                                    setSelectedImage([{ src: url }])
                                  }
                                >
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Save to Asset"
                                onClick={() => handleAssetSave(message, url)}
                              >
                                <IconButton disableRipple>
                                  <SaveIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Download"
                                onClick={() =>
                                  handleDownloadImage(message, url, isMessage)
                                }
                              >
                                <IconButton disableRipple>
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          }
                        />
                      </Box>
                    )
                  })}
                </div>
              ) : (
                <div className={classes.messageText}>
                  {!startedChat &&
                    status &&
                    isThem &&
                    status.map((item, index) => {
                      return (
                        <Box key={index} className={classes.chatStatus}>
                          {item}
                        </Box>
                      )
                    })}
                  <div
                    onDoubleClick={(e) =>
                      handleSelect(e, message, index, isMessage)
                    }
                    onMouseUp={(e) =>
                      handleSelect(e, message, index, isMessage)
                    }
                    style={{ userSelect: 'text' }}
                    dangerouslySetInnerHTML={{
                      __html: `<div style="font-family: 'PoppinsRegular', sans-serif;">${textToRender}</div>`
                    }}
                  />
                </div>
              )}
              {message?.data?.partialText && (
                <Box className="mb-2 text-xs text-red-500">
                  Generation Stopped by User
                </Box>
              )}
            </div>
          </div>

          {isThem && (
            <div
              className={cn(
                'flex items-center gap-1',
                (!isMessage && index < history.length - 1) || loadingMessage
                  ? 'opacity-0 group-hover:opacity-100 group-hover:translate-y-0 translate-y-2 transition-all duration-300'
                  : 'opacity-100'
              )}
            >
              <CopyButton text={textToRender} />
              {renderFeedback(message, index, isMessage)}
            </div>
          )}
        </div>

        {document?.document_id && document?.document_end && (
          <DocumentAttachLabel
            documentName={document?.document_name}
            type="end"
          />
        )}
      </ListItem>
    )
  }

  const handleTagsValueChange = (key, value, mode) => {
    if ((value ?? []).length > 0) {
      trackEvent(
        mixpanelEvents.CHAT_FILTER_APPLIED,
        'SUCCESS',
        {},
        { 'Feature Group': featureGroup.CHAT_V2 }
      )
    }
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  const imageTypeOptions = [
    {
      value: 'Real',
      label: 'Photo - Realistic'
    },
    {
      value: 'Graphical',
      label: 'Graphical'
    },
    {
      value: 'Logo',
      label: 'Logo'
    }
  ]
  const imageSizeOptions = [
    {
      value: 256,
      label: '256x256'
    },
    {
      value: 512,
      label: '512x512'
    },
    {
      value: 1024,
      label: '1024x1024'
    }
  ]
  const imageCountOptions = [
    {
      value: 1,
      label: '1'
    },
    {
      value: 2,
      label: '2'
    },
    {
      value: 3,
      label: '3'
    },
    {
      value: 4,
      label: '4'
    }
  ]

  const renderEmptyChat = () => {
    return fullScreen ? (
      <Box className={classes.emptyWrapper}>
        <Box className={classes.emptyTitle}>
          {user_name && (
            <Box>
              <Box className={clsx(classes.gradientTextStyle)}>
                Hello, {user_name}
              </Box>
            </Box>
          )}
          <Box>
            <Box className={clsx(classes.gradientTextStyle)}>
              How can I help you today?
            </Box>
          </Box>
        </Box>
        <Box className={classes.emptyContent}>
          Chat with this AI to generate text for your projects, delve deeper
          into your library, or ask any lingering questions.
        </Box>
        <Box className={classes.promptWrapper}>
          {randomPrompts &&
            randomPrompts.map((item, index) => {
              return (
                <Box
                  className={classes.promptbtn}
                  key={index}
                  onClick={() => {
                    setTextBox(item?.prompt)
                  }}
                >
                  {item?.title}
                </Box>
              )
            })}
        </Box>
      </Box>
    ) : (
      <Box className={classes.emptyWrapperMin}>
        <Box className={classes.emptyTitleMin}>
          {user_name && (
            <Box>
              <Box className={clsx(classes.gradientTextStyle)}>
                Hello, {user_name}
              </Box>
            </Box>
          )}
          <Box>
            <Box className={clsx(classes.gradientTextStyle)}>
              How can I help you today?
            </Box>
          </Box>
          <Box className={classes.emptyContentMin}>
            Chat with this AI to generate text for your projects, delve deeper
            into your library, or ask any lingering questions.
          </Box>
          <div className="flex flex-wrap gap-1 mt-3">
            {randomPrompts &&
              randomPrompts.map((item, index) => {
                return (
                  <div
                    className={classes.promptbtnMin}
                    style={{
                      width: 'fit'
                      // height: '50px'
                      // width: '100px'
                    }}
                    key={index}
                    onClick={() => {
                      setTextBox(item?.prompt)
                      trackEvent(
                        mixpanelEvents.CHAT_PROMPT_USED,
                        'SUCCESS',
                        {},
                        {
                          item,
                          'Feature Group': featureGroup.CHAT_V2
                        }
                      )
                    }}
                  >
                    <ComponentPromptIcon className="size-3 text-grey-700" />
                    {item?.title}
                  </div>
                )
              })}
          </div>
        </Box>
      </Box>
    )
  }

  const handleMangePrompts = () => {
    window.open(SETTINGS_CHAT, '_blank')
  }

  const handlePromptSelection = (item) => {
    const { prompt } = item
    trackEvent(mixpanelEvents.CHAT_PROMPT_USED, 'SUCCESS', {}, { item })
    setTextBox(prompt)
    setShowPromptLibrary(false)
  }

  const setItemLoading = (id, promptType, val) => {
    if (promptType === 'domain') {
      setDomainPrompts((prevState) => {
        return {
          ...prevState,
          domain_prompts: prevState.domain_prompts.map((item) =>
            item.id === id ? { ...item, disabled: val } : item
          )
        }
      })
    } else if (promptType === 'default') {
      setDomainPrompts((prevState) => {
        return {
          ...prevState,
          default_prompts: prevState.default_prompts.map((item) =>
            item.id === id ? { ...item, disabled: val } : item
          )
        }
      })
    }
  }

  const handlePromptFavorite = async (id, promptType) => {
    setItemLoading(id, promptType, true)
    const req = {
      domain_id
    }
    if (promptType === 'domain') {
      const fav = domain_prompts_fav.includes(id)
        ? domain_prompts_fav.filter((item) => item !== id)
        : [...domain_prompts_fav, id]
      req.domain_config = {
        ...domain_config,
        prompt_fav: {
          ...promptFav,
          domain_prompts: fav
        }
      }
    } else if (promptType === 'default') {
      const fav = default_prompts_fav.includes(id)
        ? default_prompts_fav.filter((item) => item !== id)
        : [...default_prompts_fav, id]
      req.domain_config = {
        ...domain_config,
        prompt_fav: {
          ...promptFav,
          default_prompts: fav
        }
      }
    }
    dispatch({
      type: DOMAIN_CONFIG_UPDATE,
      payload: { domain_config: req.domain_config }
    })
    const res = await putUserDomain(req)
    if (res.status !== 200) {
      toast.error('Unable to update favorites')
      dispatch({
        type: REVERT_FAVPROMPT_DOMAIN_CONFIG,
        payload: {
          id,
          key: promptType === 'default' ? 'default_prompts' : 'domain_prompts'
        }
      })
    }
    setItemLoading(id, promptType, false)
  }

  const renderPromptLibrary = () => {
    const { domain_prompts = [], default_prompts = [] } = domainPrompts || {}
    const tabs = [
      {
        label: 'Shared Prompts',
        children: (
          <LibraryPrompt
            prompts={domain_prompts}
            handlePromptSelection={handlePromptSelection}
            promptSearch={promptSearch}
            promptFilter={promptFilter}
            showCreateNew={true}
            createNew={handleMangePrompts}
            favPrompts={domain_prompts_fav}
            handlePromptFav={handlePromptFavorite}
            promptType="domain"
            isAdmin={isAdmin}
          />
        )
      },
      {
        label: 'Joist Prompts',
        children: (
          <LibraryPrompt
            prompts={default_prompts}
            title={'Joist Prompts'}
            handlePromptSelection={handlePromptSelection}
            promptSearch={promptSearch}
            promptFilter={promptFilter}
            favPrompts={default_prompts_fav}
            handlePromptFav={handlePromptFavorite}
            promptType="default"
            isAdmin={isAdmin}
          />
        )
      }
    ]
    return (
      <Dialog
        data-dialog-parent="chat"
        fullScreen
        open={showPromptLibrary}
        onClose={() => setShowPromptLibrary(false)}
        sx={{
          zIndex: 1302,
          margin: '5vh'
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: 1302
          }
        }}
      >
        <Box className={classes.dialogWrapper}>
          <Container>
            <SectionFixed>
              <Box className={classes.dialogTitleWrapper}>
                <Box>
                  <Box
                    className={clsx(
                      classes.titleText,
                      classes.gradientTextStyle
                    )}
                  >
                    PROMPT LIBRARY
                  </Box>
                </Box>
                <Box className={classes.dialogButtonWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowPromptLibrary(false)
                    }}
                  >
                    Cancel
                  </Button>
                  {isAdmin && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleMangePrompts()
                      }}
                    >
                      Manage Prompts
                    </Button>
                  )}
                </Box>
              </Box>
            </SectionFixed>
            <Section overFlow>
              <Tab
                sectionOverFlow
                data={tabs}
                isFlex={true}
                extraContent={
                  <Box className={classes.promptTitleWrapper}>
                    <Box className={classes.promptSearchWrapper}>
                      <TextField
                        placeholder="Search"
                        value={promptSearch}
                        onChange={(e) => setPromptSearch(e.target.value)}
                      />
                      <SortLibrary
                        filterBy={promptFilter}
                        setFilterBy={setPromptFilter}
                      />
                    </Box>
                  </Box>
                }
              />
            </Section>
          </Container>
        </Box>
      </Dialog>
    )
  }

  const chatSettingsPopup = () => {
    return (
      <Dialog
        data-dialog-parent="chat"
        fullWidth
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        sx={{
          zIndex: 1302,
          margin: '5vh'
        }}
        maxWidth="xs"
        PaperProps={{
          sx: {
            zIndex: 1302
          }
        }}
      >
        <DialogTitle className="flex items-center justify-between">
          <Box sx={{ padding: '10px 20px' }}>Chat Settings</Box>
          <button
            className="rounded-md icon-btn text-grey-700"
            onClick={() => setSettingsOpen(false)}
          >
            <CloseIcon className="text-4" />
          </button>
        </DialogTitle>
        <DialogContent>
          <Box
            className="text-sm font-poppins text-grey-700"
            sx={{
              display: 'flex',
              gap: '10px',
              padding: '10px 20px',
              justifyContent: 'space-between'
            }}
          >
            <Box>Chat Mode</Box>
            <ToggleButtonGroup
              value={chatMode}
              exclusive
              onChange={handleToggleChatMode}
              color="primary"
              size="small"
              disabled={loading}
              className="text-xs"
              sx={{
                '& .MuiToggleButtonGroup-grouped': {
                  padding: '2px 6px',
                  textTransform: 'none'
                }
              }}
            >
              <Tooltip title="Text Mode">
                <ToggleButton value="chat" aria-label="text mode">
                  <TextSnippetIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Image Mode">
                <ToggleButton value="image" aria-label="image mode">
                  <ImageIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {chatMode === 'chat' && (
              <Box
                className="text-sm font-poppins text-grey-700"
                sx={{
                  display: 'flex',
                  padding: '10px 20px',
                  justifyContent: 'space-between'
                }}
              >
                Generate Resume
                <button
                  className="rounded-md icon-btn text-grey-700"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    setSettingsOpen(false)
                    !loading &&
                      showModal(
                        'Generative AI',
                        (onClose) => (
                          <InsertResumeDailog
                            activeEditor={null}
                            onClose={onClose}
                            source="chat"
                            handleChatInit={handleChatInit}
                            trackerId={trackerId}
                          />
                        ),
                        true,
                        false,
                        'chat'
                      )
                  }}
                >
                  <ContactPageIcon />
                </button>
              </Box>
            )}
            {chatMode === 'image' && (
              <Box
                className="text-sm font-poppins text-grey-700"
                sx={{
                  display: 'flex',
                  padding: '10px 20px',
                  justifyContent: 'space-between'
                }}
              >
                Image Type
                <SelectButtonCustom
                  disabled={loading}
                  options={imageTypeOptions}
                  id="imageType"
                  handleOptionClick={(e) => {
                    handleImageOptionsSelect(e, 'imageType')
                  }}
                />
              </Box>
            )}
            {chatMode === 'image' && (
              <Box
                className="text-sm font-poppins text-grey-700"
                sx={{
                  display: 'flex',
                  padding: '10px 20px',
                  justifyContent: 'space-between'
                }}
              >
                Image Size
                <SelectButtonCustom
                  disabled={loading}
                  options={imageSizeOptions}
                  defaultSelectedIndex={1}
                  id="imageSize"
                  handleOptionClick={(e) => {
                    handleImageOptionsSelect(e, 'imageSize')
                  }}
                />
              </Box>
            )}
            {chatMode === 'image' && (
              <Box
                className="text-sm font-poppins text-grey-700"
                sx={{
                  display: 'flex',
                  padding: '10px 20px',
                  justifyContent: 'space-between'
                }}
              >
                Number of Images
                <SelectButtonCustom
                  disabled={loading}
                  options={imageCountOptions}
                  defaultSelectedIndex={3}
                  id="imageNumber"
                  handleOptionClick={(e) => {
                    handleImageOptionsSelect(e, 'imageNumber')
                  }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const handleRegeneration = async (prompt) => {
    const { selectedText, message } = { ...anchorEl }
    prompt = prompt[Math.floor(Math.random() * prompt.length)]
    let promptFormatted = prompt
    if (prompt.includes('%selectedText%')) {
      promptFormatted = promptFormatted.replace('%selectedText%', selectedText)
    } else {
      promptFormatted = promptFormatted + '\n' + selectedText
    }
    const { data, id } = message || {}
    const { text = '', inputText } = data || {}
    if (prompt.includes('%prevResponse%')) {
      promptFormatted = promptFormatted.replace('%prevResponse%', text)
    }
    if (prompt.includes('%prevQuestion%')) {
      promptFormatted = promptFormatted.replace('%prevQuestion%', inputText)
    }
    handleClose()
  }

  const handleDocumentAttach = (doc, trigger = 'chat') => {
    if (doc) {
      const { document_id, document_name, document_type } = doc || {}
      setDocumentAttached({
        document_id,
        document_name,
        document_type
      })
      toast.success(`${document_name} attached to the chat`, {
        style: {
          fontSize: '12px'
        }
      })
      trackEvent(
        mixpanelEvents.CHAT_FILE_ATTACHED,
        'SUCCESS',
        {},
        {
          'Feature Group': featureGroup.CHAT_V2,
          document_type,
          trigger
        }
      )
    } else {
      setDocumentAttached(null)
      toast.success(`Document removed from the chat`, {
        style: {
          fontSize: '12px'
        }
      })
    }
  }

  const isSocketConnected = socketStatus === 'connected'

  const loadingChat = stateLoading || !historyProps
  return socketStatus === 'errored' ? (
    <Error
      errorMessage="Error in establishing connection with the server"
      errorLogoSrc={ServerDown}
    />
  ) : loadingChat ? (
    <Loader loading={loadingChat} caption={'loading your chat'} flex />
  ) : (
    <ReactResizeDetector handleHeight>
      {({ height }) => {
        const maxTextBoxHeight = height * 0.25 + 'px'
        return (
          <Container>
            <Section overFlow parentRef={listRef} onScroll={handleScroll}>
              {isShowEmpty ? (
                renderEmptyChat()
              ) : (
                <List className={classes.messageList} id="historyList">
                  {prefixElements()}
                  {!showHistory ? (
                    <ReactPullToRefresh onRefresh={() => handlePull()}>
                      <div
                        className={classes.pullWrapper}
                        onClick={() => handlePull()}
                      >
                        <div>Pull down or click here to load history</div>
                        <ArrowDownwardIcon />
                      </div>
                    </ReactPullToRefresh>
                  ) : (
                    loadingHistory && (
                      <div id="chat_loading" style={{ margin: '10px 0px' }}>
                        <div className={classes.dotsWrapper}>
                          <div className="dot-flashing" />
                        </div>
                      </div>
                    )
                  )}
                  {(!_.isEmpty(messageList) ||
                    loading ||
                    !_.isEmpty(history)) && (
                    <>
                      {showHistory &&
                        history.map((message, index) =>
                          renderMessage(message, index, false)
                        )}
                      {messageList.map((message, index) =>
                        renderMessage(message, index, true)
                      )}
                    </>
                  )}
                </List>
              )}
              <Popover
                open={Boolean(anchorEl)}
                anchorReference="anchorPosition"
                anchorPosition={anchorEl?.position}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Box>
                  {anchorEl?.edit ? (
                    <MenuList>
                      <MenuItem>
                        <TextField
                          placeholder="Modify with prompt"
                          variant="outlined"
                          size="small"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              const prompt = event.target.value
                              handleRegeneration(prompt)
                            }
                            event.stopPropagation()
                          }}
                        />
                      </MenuItem>
                      {selectPrompts &&
                        selectPrompts.map((item, index) => {
                          const { label, prompt } = item
                          return (
                            <MenuItem
                              sx={{ display: 'flex', gap: '3px' }}
                              key={index}
                              onClick={() => {
                                handleRegeneration(prompt)
                              }}
                            >
                              <AutoAwesomeIcon />
                              {label}
                            </MenuItem>
                          )
                        })}
                    </MenuList>
                  ) : (
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setAnchorEl((prev) => {
                          return { ...prev, edit: true }
                        })
                      }}
                    >
                      <AutoAwesomeIcon />
                    </IconButton>
                  )}
                </Box>
              </Popover>
            </Section>
            <SectionFixed>
              <div
                style={{
                  position: 'relative'
                }}
                className={
                  showFilter
                    ? fullScreen
                      ? classes.inputContainerSession
                      : classes.inputContainerSessionMin
                    : classes.inputContainer
                }
              >
                {documentAlert?.show && (
                  <div className="m-0 mb-1 overflow-hidden rounded-lg">
                    {/* <div className="flex items-center justify-end"></div> */}

                    <div
                      className="flex items-center gap-2 text-xs text-left bg-grey-50"
                      style={{
                        padding: '6px 10px'
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          overflow: 'hidden'
                        }}
                      >
                        <p className="m-0 text-xxs text-grey-700">
                          Do you want to attach ?{' '}
                        </p>
                        <EllipsisTooltip
                          className="m-0 text-xs font-medium text-grey-700"
                          text={documentAlert.document_name}
                        />
                      </Box>

                      <Button2
                        primary
                        onClick={() => {
                          handleDocumentAttach(documentAlert, 'suggestion')
                          dispatch(
                            attachAlertToChat({
                              ...documentAlert,
                              show: false
                            })
                          )
                        }}
                        style={{
                          fontSize: '10px',
                          padding: '4px 8px',
                          marginLeft: 'auto'
                        }}
                      >
                        Attach
                      </Button2>
                      <div
                        className="grid place-content-center"
                        onClick={() => {
                          dispatch(
                            attachAlertToChat({
                              ...documentAlert,
                              show: false
                            })
                          )
                        }}
                      >
                        <CrossIcon className="size-3 text-grey-800" />
                      </div>
                    </div>
                  </div>
                )}
                {showFilter &&
                  chatMode === 'chat' &&
                  !_.isEmpty(selectedTags) && (
                    <>
                      <Box className={classes.filterContainer}>
                        <Box
                          className={
                            classes.tagsWrapper + ' hideScrollbar w-full'
                          }
                        >
                          <TagFilter
                            parent="chat"
                            showButton={false}
                            showAddButton={false}
                            showTags
                            tags={{
                              source_type: {
                                values: [
                                  {
                                    label: 'Documents',
                                    value: 'documents'
                                  },
                                  {
                                    label: 'Knowledge',
                                    value: 'knowledge'
                                  }
                                ],
                                type: 'default',
                                editable: false
                              },
                              ...tagsOptions
                            }}
                            disabled={loading}
                            selectedTags={selectedTags}
                            onChange={handleTagsValueChange}
                          />
                        </Box>
                      </Box>
                    </>
                  )}

                {openFeedback && (
                  <div
                    style={{
                      width: '97%',
                      position: 'absolute',
                      background: 'var(--grey-700)',
                      zIndex: '99',
                      padding: '12px',
                      color: 'white',
                      borderRadius: '8px',
                      boxSizing: 'border-box'
                    }}
                    className={
                      loading || !isSocketConnected
                        ? clsx(
                            classes.contentEditable,
                            classes.contentEditableDisabled
                          )
                        : classes.contentEditable
                    }
                  >
                    <div className="flex justify-between">
                      <div className="text-sm font-medium">Help us Improve</div>
                      <IconButton
                        onClick={() => {
                          handleCloseSessionFeedback()
                        }}
                        style={{
                          height: '24px',
                          width: '24px',
                          color: 'white'
                        }}
                      >
                        <CloseIcon className="size-4" color="red" />
                      </IconButton>
                    </div>
                    <div className="flex items-center mt-2 mb-2">
                      <Rating
                        name="hover-feedback"
                        value={starsValue}
                        onChange={(event, newValue) => {
                          // setValue(newValue)
                          setStarsValue(newValue)
                        }}
                        onChangeActive={(event, newHover) => {
                          // setHover(newHover)
                          setStarsHover(newHover)
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{
                              opacity: 0.55,
                              color: 'var(--grey-400)'
                            }}
                            fontSize="32px"
                          />
                        }
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        placeholder="(Optional) You can add any specific feedback/details here"
                        className="w-full p-2 text-sm text-white rounded-md border-box bg-grey-600 font-poppins input-base"
                        defaultValue={''}
                        onChange={handleFeedbackChange}
                        style={{ margin: '4px 0' }}
                        // multiline={true}
                        // rows={2}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                      />

                      <Button2
                        secondary
                        dark
                        style={{
                          width: '100px',
                          justifyContent: 'center'
                        }}
                        onClick={() => {
                          handleSubmitSessionFeedback()
                        }}
                      >
                        Submit
                      </Button2>
                    </div>
                  </div>
                )}
                {!_.isEmpty(documentAttached) && (
                  <DocumentCard
                    onClick={() => {
                      setShowDocumentSelector(true)
                    }}
                    fileData={documentAttached}
                    onClear={() => handleDocumentAttach(null)}
                  />
                )}
                <Box
                  sx={{
                    maxHeight: maxTextBoxHeight,
                    overflow: 'auto',
                    display: 'flex',
                    borderRadius: _.isEmpty(documentAttached)
                      ? '8px 8px 0px 0px !important'
                      : '0px !important'
                  }}
                  className={
                    loading || !isSocketConnected
                      ? clsx(
                          classes.contentWrapper,
                          classes.contentEditableDisabled
                        )
                      : classes.contentWrapper
                  }
                >
                  <Box
                    className={
                      loading || !isSocketConnected
                        ? clsx(
                            classes.contentEditableWrapper,
                            classes.contentEditableDisabled
                          )
                        : classes.contentEditableWrapper
                    }
                  >
                    <ContentEditable
                      id="chat-input-box"
                      disabled={loading || !isSocketConnected}
                      multiline
                      ref={textBoxRef}
                      tabIndex={0}
                      html={
                        textBox
                          ? convertFromBraces(textBox)
                          : loading || !isSocketConnected
                          ? '...'
                          : ''
                      }
                      placeholder={placeholderText}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.shiftKey === false) {
                          const html = textBoxRef.current.lastHtml
                          let newText = convertToBraces(html)
                          if (newText) {
                            newText = htmlToText(newText)
                            handleChatInit(
                              newText,
                              trackerId,
                              chatMode,
                              imageData
                            )
                          }
                        }
                      }}
                      onChange={(e) => {
                        const html = e.currentTarget.innerHTML
                        const newText = html === '<br>' ? '' : html
                        setTextBox(newText)
                      }}
                      // style={{
                      //   maxHeight: '100%'
                      // }}
                      className={
                        loading || !isSocketConnected
                          ? clsx(
                              classes.contentEditable,
                              classes.contentEditableDisabled
                            )
                          : classes.contentEditable
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1.5px',
                      border: '1px solid var(--grey-200)',
                      borderTop: 'none',
                      borderRadius: '0px 0px 8px 8px',
                      padding: '3px 8px 3px 2px',
                      background: 'var(--grey-50)',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center'
                      }}
                    >
                      {chatMode === 'chat' && (
                        <>
                          <TagFilter
                            parent="chat"
                            showButton
                            showTags={false}
                            tags={{
                              source_type: {
                                values: [
                                  { label: 'Documents', value: 'documents' },
                                  { label: 'Knowledge', value: 'knowledge' }
                                ],
                                type: 'default',
                                editable: false
                              },
                              ...tagsOptions
                            }}
                            disabled={loading}
                            selectedTags={selectedTags}
                            onChange={handleTagsValueChange}
                            filterIcon={
                              <button
                                className="rounded-md icon-btn text-grey-700"
                                onClick={() => {
                                  trackEvent(
                                    mixpanelEvents.CHAT_FILTER_CLICKED,
                                    'SUCCESS',
                                    {},
                                    {
                                      'Feature Group': featureGroup.CHAT_V2
                                    }
                                  )
                                }}
                              >
                                <Tooltip title={'Filter'}>
                                  <PlusIcon className="size-4" />
                                </Tooltip>
                              </button>
                            }
                            clearFilter={<></>}
                          />
                        </>
                      )}
                      {chatMode === 'chat' && (
                        <Tooltip title="Attach">
                          <button
                            className="rounded-md icon-btn text-grey-700"
                            onClick={() => {
                              setShowDocumentSelector(true)
                              trackEvent(
                                mixpanelEvents.CHAT_ATTACH_FILE_CLICKED,
                                'SUCCESS',
                                {},
                                {
                                  'Feature Group': featureGroup.CHAT_V2
                                }
                              )
                            }}
                            style={{
                              padding: '5px'
                            }}
                          >
                            <AttachIcon className="size-4 text-grey-700" />
                          </button>
                        </Tooltip>
                      )}

                      {chatMode === 'chat' && (
                        <Box>
                          <Tooltip title={'Prompts Library'}>
                            <button
                              className="rounded-md icon-btn text-grey-700"
                              style={{
                                padding: '5px'
                              }}
                              disabled={loading}
                              onClick={() => {
                                setShowPromptLibrary(true)
                                trackEvent(
                                  mixpanelEvents.CHAT_PROMPT_LIBRARY_OPENED,
                                  'SUCCESS',
                                  {},
                                  {}
                                )
                              }}
                            >
                              <LibraryIcon2 className="size-4" />
                            </button>
                          </Tooltip>
                        </Box>
                      )}
                      {chatMode === 'chat' && (
                        <Box>
                          {_.isEmpty(voiceOptions) ? (
                            <Tooltip title={'Create Voice'}>
                              <button
                                className="rounded-md icon-btn text-grey-700"
                                style={{
                                  padding: '5px'
                                }}
                                disabled={loading}
                                onClick={() => {
                                  handleMangePrompts()
                                }}
                              >
                                <AudioLinesIcon className="size-4" />
                              </button>
                            </Tooltip>
                          ) : (
                            <SelectButtonCustom
                              disabled={loading}
                              options={voiceOptions}
                              defaultSelectedIndex={defaultVoiceIndex}
                              style={{}}
                              tooltip={
                                selectedVoice?.label
                                  ? 'Voice - ' + selectedVoice?.label
                                  : 'Voice Config'
                              }
                              id="voiceConfig"
                              hideOptionLabel={true}
                              title={
                                <AudioLinesIcon className="size-4 text-grey-700" />
                              }
                              handleOptionClick={(value) => {
                                setSelectedVoice(value)
                              }}
                            />
                          )}
                        </Box>
                      )}
                      {chatMode === 'chat' && (
                        <Box>
                          <Button2
                            style={{
                              padding: '5px',
                              color: enableDeepSearch
                                ? 'var(--grey-100)'
                                : 'var(--grey-700)'
                            }}
                            disabled={loading}
                            noOutline
                            secondary={!enableDeepSearch}
                            primary={enableDeepSearch}
                            onClick={() => {
                              setEnableDeepSearch(!enableDeepSearch)
                            }}
                          >
                            DeepSearch
                          </Button2>
                        </Box>
                      )}
                    </Box>
                    <div className="flex items-center gap-2">
                      <Box>
                        <Tooltip title={'Chat Settings'}>
                          <button
                            className="rounded-md icon-btn text-grey-700"
                            style={{
                              padding: '5px'
                            }}
                            disabled={loading}
                            onClick={() => {
                              setSettingsOpen(true)
                              trackEvent(
                                mixpanelEvents.CHAT_SETTINGS_OPENED,
                                'SUCCESS',
                                {},
                                {
                                  'Feature Group': featureGroup.CHAT_V2
                                }
                              )
                            }}
                          >
                            <SettingsIcon className="size-4" />
                          </button>
                        </Tooltip>
                      </Box>
                      <Tooltip title={'Send'}>
                        <Box>
                          <button
                            // disabled={loading}
                            id="chat-send-button"
                            className="rounded-full icon-btn text-grey-100 bg-grey-800 "
                            style={{
                              padding: '6px'
                            }}
                            onClick={() => {
                              if (loading) {
                                handleStopGeneration()
                              }

                              const html = textBoxRef.current.lastHtml
                              let newText = convertToBraces(html)
                              if (newText) {
                                newText = htmlToText(newText)
                                handleChatInit(
                                  newText,
                                  trackerId,
                                  chatMode,
                                  imageData
                                )
                              }
                            }}
                          >
                            {loading ? (
                              <div
                                className="m-1 bg-white size-2"
                                id="stop-icon"
                              ></div>
                            ) : (
                              <UpArrowIcon className="size-4" strokeWidth={2} />
                            )}
                          </button>
                        </Box>
                      </Tooltip>
                    </div>
                  </Box>
                </Box>
                {disclaimer && (
                  <Tooltip
                    title={disclaimer}
                    enterDelay={1000}
                    enterNextDelay={1000}
                  >
                    <Box className={classes.disclaimer}>
                      <TextLineLimiter
                        content={disclaimer}
                        limit={fullScreen ? 3 : 2}
                        wordBreak={'break-word'}
                        indicatorStyle={{
                          color: 'grey',
                          cursor: 'pointer',
                          fontStyle: 'normal',
                          fontWeight: '100',
                          fontSize: '10px'
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
                {selectedImage?.length > 0 && (
                  <ImageGallery
                    images={selectedImage}
                    onClose={() => setSelectedImage([])}
                  />
                )}
              </div>
              {modal}
              {renderPromptLibrary()}
              {chatSettingsPopup()}
              {showDocumentSelector && (
                <DocumentAdd
                  documentAlert={documentAlert}
                  showCollectionSelect
                  isOpen={showDocumentSelector}
                  handleClose={() => setShowDocumentSelector(false)}
                  actionButtons={[
                    {
                      label: 'Attach',
                      action: (e) => handleDocumentAttach(e)
                    }
                  ]}
                  prevSelectedCollections={documentAttached}
                  headerText={'Attach Files'}
                  parent={'chat'}
                />
              )}
            </SectionFixed>
          </Container>
        )
      }}
    </ReactResizeDetector>
  )
}

export default ChatBot
