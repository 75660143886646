import { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import _, { isEmpty, capitalize, set } from 'lodash'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Container, Section, SectionFixed } from '../../components/Container'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import Box from '@mui/material/Box'
import { Button, Dialog } from '../../components'
import SimilarityViewer from './SimilarityViewer'
import moment from 'moment'
import colors from '../../components/react-image-annotate/colors'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import ImageIcon from '@mui/icons-material/Image'
import VideoPlayer from '../../components/VideoPlayer'
import Annotator from '../../components/react-image-annotate/Annotator'
import Tooltip from '@mui/material/Tooltip'
import { checkExpiry, extractDimensions } from '../../utils/AWS'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ReactComponent as CloseArrow } from '../../assets/images/close-arrow.svg'
import { ReactComponent as OpenArrow } from '../../assets/images/open-arrow.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import InputTags from '../../components/InputTags'
import { useStyles } from './styles'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ImageGallery from '../../components/ImageGallery'
import { checkUserRoleAdmin, checkUserRoleSuperUser } from '../../utils/User'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DeleteIcon from '@mui/icons-material/Delete'
import Checkbox from '@mui/material/Checkbox'
import {
  getSimilarImages,
  getAsset,
  hideAsset,
  postAssets,
  deleteMultiAsset
} from '../../store/api'
import axios from 'axios'
import clsx from 'clsx'
import HideImages from './HideImages'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { toast } from 'react-toastify'

const reformatTime = (time) => {
  const [hours, minutes, seconds, milliseconds] = time.split(':')
  if (hours !== '00') {
    return hours + ':' + minutes + ':' + seconds
  } else {
    return minutes + ':' + seconds
  }
}

const convertToSeconds = (time) => {
  if (time) {
    const [hours, minutes, seconds, milliseconds] = time.split(':')
    return Math.floor(
      parseInt(hours) * 60 * 60 +
        parseInt(minutes) * 60 +
        parseInt(seconds) +
        milliseconds / 100
    )
  }
  return 'N/A'
}

const AssetTypeMin = ({
  asset,
  onClickImage = () => {},
  playerRef,
  triggerRender,
  setTriggerRender
}) => {
  const {
    assetType,
    originalFile,
    filetype,
    markers = [],
    fileName,
    segment
  } = asset
  const { start_time = 0 } = segment || {}
  useEffect(() => {
    if (playerRef?.current && start_time) {
      playerRef.current.currentTime(convertToSeconds(start_time))
    }
  }, [playerRef, triggerRender])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh'
      }}
    >
      {assetType?.includes('video') && originalFile ? (
        <VideoPlayer
          setTriggerRender={setTriggerRender}
          playerRef={playerRef}
          url={originalFile}
          type={filetype}
          markers={markers}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            '& span': {
              height: '100%',
              width: '100%',
              textAlign: 'center',
              background: '#E8F0FE',
              display: 'flex !important',
              justifyContent: 'center'
            },
            '& img': {
              objectFit: 'contain',
              maxHeight: '100%',
              maxWidth: '100%',
              cursor: 'pointer',
              border: '1px solid lightgray'
            }
          }}
        >
          <LazyLoadImage
            onClick={() => onClickImage({ src: originalFile, name: fileName })}
            effect="blur"
            src={originalFile}
          />
        </Box>
      )}
    </Box>
  )
}

const AssetTypeMax = ({
  asset,
  onClickImage = () => {},
  playerRef,
  triggerRender,
  setTriggerRender,
  assetExtendedData = {},
  loadingExtendedData = true
}) => {
  const { assetType, originalFile, filetype, fileName } = asset
  const [viewAnnotations, setViewAnnotations] = useState(false)
  const {
    boundingRegions = [],
    boundingRegionsCls = [],
    markers = []
  } = assetExtendedData

  const handleVideoSeek = (mark) => {
    const player = playerRef?.current
    if (player?.player_ && mark?.start_time) {
      player.currentTime(convertToSeconds(mark.start_time))
    }
  }

  if (assetType?.includes('video')) {
    return (
      originalFile && (
        <VideoPlayer
          setTriggerRender={setTriggerRender}
          playerRef={playerRef}
          url={originalFile}
          type={filetype}
          markers={markers}
        />
      )
    )
  } else {
    return (
      <Box sx={{ height: 'calc(100% - 40px)' }}>
        <Box sx={{ textAlign: 'end' }}>
          <Tooltip title={viewAnnotations ? 'Image Viewer' : 'Image Annotator'}>
            <IconButton onClick={() => setViewAnnotations(!viewAnnotations)}>
              {viewAnnotations ? <ImageIcon /> : <PhotoSizeSelectLargeIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        {viewAnnotations ? (
          <Annotator
            hideHeader
            labelImages
            regionClsList={boundingRegionsCls}
            images={[
              {
                src: originalFile,
                name: fileName,
                regions: boundingRegions
              }
            ]}
          />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              '& span': {
                height: '90%',
                width: '100%',
                textAlign: 'center',
                background: '#E8F0FE',
                display: 'flex !important',
                justifyContent: 'center',
                alignItems: 'center'
              },
              '& img': {
                objectFit: 'contain',
                maxHeight: '90%',
                maxWidth: '100%',
                cursor: 'pointer'
              }
            }}
          >
            <LazyLoadImage
              onClick={() =>
                onClickImage({ src: originalFile, name: fileName })
              }
              effect="blur"
              src={originalFile}
            />
          </Box>
        )}
      </Box>
    )
  }
}

const HideButton = ({
  data = {},
  signedImages = [],
  dispatch,
  setData = () => {},
  setParentLoading = () => {},
  setParentLoadingText = () => {}
}) => {
  const [showdialog, setShowDialog] = useState(false)
  const [checked, setChecked] = useState(false)
  const [hidden, setHidden] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data && ![true, false].includes(hidden)) {
      setHidden(data?.hidden_from_search)
    }
  }, [data])

  const handleSubmit = async () => {
    setLoading(true)
    setShowDialog(false)
    setParentLoading(true)
    setParentLoadingText('Hiding asset')
    const ids = [data?.id]
    if (checked) {
      signedImages.forEach((image) => {
        ids.push(image?.asset_id ? image?.asset_id : image?.content_id)
      })
    }
    const callback = () => {
      setData({ ...data, hidden_from_search: !hidden })
      setHidden(!hidden)
      setLoading(false)
      setParentLoading(false)
      setParentLoadingText('')
    }
    const res = await hideAsset({ asset_id: ids, hide_from_search: !hidden })
    if (res.status === 200) {
      callback()
      toast.success('Asset hidden successfully')
    } else {
      setLoading(false)
      setParentLoading(false)
      setParentLoadingText('')
      toast.error('Unable to hide asset')
    }
  }

  return (
    <>
      <Tooltip
        title={hidden ? 'Show asset on search' : 'Hide asset from search'}
      >
        <IconButton
          disableRipple
          disabled={loading}
          onClick={() => setShowDialog(true)}
        >
          {hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Tooltip>
      <Dialog
        open={showdialog}
        handleSubmit={handleSubmit}
        handleClose={() => setShowDialog(false)}
        dialogTitle={`Are you sure you want to ${
          hidden ? 'show' : 'hide'
        } this asset?`}
        submitButtonText={`Yes, ${hidden ? 'show' : 'hide'} it`}
        dialogContent={
          signedImages?.length > 0 && (
            <Box onClick={() => setChecked(!checked)}>
              <Checkbox checked={checked} />
              {hidden ? 'Show' : 'Hide'} Similar Assets
            </Box>
          )
        }
      />
    </>
  )
}

const DeleteButton = ({ data, dispatch, onDelete = () => {} }) => {
  const [showdialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleAssetDelete = async () => {
    const id = data?.id
    if (id) {
      setLoading(true)
      setShowDialog(false)
      const callback = () => {
        onDelete()
      }
      const req = {
        list_of_asset_ids: [id]
      }
      const res = await deleteMultiAsset(req)
      if (res.status === 200) {
        setLoading(false)
        callback()
        toast.success('Asset deleted successfully')
      } else {
        setLoading(false)
        toast.error('Unable to delete asset')
      }
    }
  }

  return (
    <>
      <Tooltip title={'Delete Asset'}>
        <IconButton disableRipple onClick={() => setShowDialog(true)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={showdialog}
        handleSubmit={handleAssetDelete}
        handleClose={() => setShowDialog(false)}
        dialogTitle={'Are you sure you want to delete this asset?'}
        submitButtonText={'Yes, delete it'}
      />
    </>
  )
}

const AssetViewer = (props) => {
  const {
    s3Obj,
    asset,
    onClose,
    searchKey,
    relayImagePaneSize,
    setParentLoading = () => {},
    setParentLoadingText = () => {}
  } = props
  const {
    id,
    originalFile,
    url,
    fileName,
    src,
    similarObj = [],
    similarAspectRatios = [],
    resultType
  } = asset
  const dispatch = useDispatch()
  const isAdmin = checkUserRoleAdmin()
  const isSuperUser = checkUserRoleSuperUser()
  const [assetData, setAssetData] = useState({})
  const [loading, setLoading] = useState(false)
  const [editState, setEditState] = useState(false)
  const [galleryImages, setGalleryImages] = useState([])
  const [similarImages, setSimilarImages] = useState([])
  const [similarImagesAspectRatios, setSimilarImagesAspectRatios] = useState([])
  const [imagePaneSize, setImagePaneSize] = useState('min')
  const [assetExtendedData, setAssetExtendedData] = useState({})
  const [loadingExtendedData, setLoadingExtendedData] = useState(true)
  const playerRef = useRef(null)
  const [triggerRender, setTriggerRender] = useState(false)
  const [showHideModel, setShowHideModel] = useState(false)

  useEffect(() => {
    relayImagePaneSize(imagePaneSize)
  }, [imagePaneSize])

  const classes = useStyles()
  const {
    infoData,
    dataclass,
    keyclass,
    timeStampContainer,
    markerText,
    dataContainer,
    searchWrapper,
    chapterDataContainer,
    markerKeyTime,
    markerTime,
    markerKeyText,
    listPointer,
    liPointer,
    markerLabel
  } = classes

  useEffect(() => {
    setLoading(true)
    setInitalData()
    const { id } = asset
    if (id) {
      fetchExtendedData(id)
    }
  }, [asset])

  useEffect(() => {
    const source = axios.CancelToken.source()
    async function initalizeData() {
      const { id, src, similarObj = [], similarAspectRatios } = asset
      setSimilarImages(similarObj)
      setSimilarImagesAspectRatios(similarAspectRatios)
      if (id && src) {
        const similarityType = src === 'asset' ? 'asset' : 'image'
        const req = {
          id,
          content_type: similarityType
        }
        const res = await getSimilarImages(req, source.token)
        if (res.status === 200) {
          const responseImages = res?.data?.data
          const aspectArray = []
          const signedImages = []
          await Promise.all(
            responseImages?.map(async (img, index) => {
              const { asset_id, content_id } = img
              const prevIndex = similarObj.findIndex(
                (image) =>
                  image?.asset_id === asset_id ||
                  image?.content_id === content_id
              )
              if (!(id === asset_id || id === content_id) && prevIndex === -1) {
                const url = img?.image_thumbnail_url
                  ? img?.image_thumbnail_url
                  : img?.image_url
                const signedSrc = await checkExpiry(url, s3Obj)
                const signedOrignal = await checkExpiry(
                  img?.image_url ? img?.image_url : img?.image_thumbnail_url,
                  s3Obj
                )
                const image_data = await extractDimensions(
                  img,
                  signedSrc,
                  'image'
                )
                const { width = 215, height = 145 } = image_data
                const aspectRatio = width / height
                aspectArray.push(aspectRatio)
                signedImages.push({
                  ...img,
                  signedSrc: signedSrc + '&cacheblock=true',
                  signedOrignal: signedOrignal + '&cacheblock=true'
                })
              }
            })
          )
          setSimilarImagesAspectRatios([...similarAspectRatios, ...aspectArray])
          setSimilarImages([...similarObj, ...signedImages])
        }
      }
    }
    if (!_.isEmpty(asset) && !_.isEmpty(s3Obj)) {
      initalizeData()
    }
    return () => {
      source.cancel('Request canceled')
    }
  }, [asset, s3Obj])

  const fetchExtendedData = async (id) => {
    setLoadingExtendedData(true)
    const res = await getAsset(id)
    if (res.status === 200) {
      const { data } = res
      processExtendedData(data)
    } else {
      setLoadingExtendedData(false)
    }
  }

  const processExtendedData = (data) => {
    const { src, asset_type, analysed_data, tags } = data
    const obj = {
      src,
      asset_type
    }
    if (asset_type?.includes('image')) {
      const { labels_bounding = [] } = analysed_data?.[0] || {}
      const regions = []
      let regionsCls = []
      const colorIndex = {}
      labels_bounding.forEach((obj, index) => {
        regionsCls.push(obj[0])
      })
      regionsCls = _.uniq(_.compact(regionsCls))
      regionsCls.forEach((reg, index) => {
        colorIndex[reg] = colors[index % colors.length]
      })
      labels_bounding.forEach((obj, index) => {
        const newObj = {
          cls: obj[0],
          color: colorIndex[obj[0]],
          editingLabels: false,
          type: 'box',
          id: index,
          h: obj[1][2],
          w: obj[1][3],
          x: obj[1][1],
          y: obj[1][0]
        }
        regions.push(newObj)
      })
      obj.boundingRegions = regions
      obj.boundingRegionsCls = regionsCls
    } else if (asset_type?.includes('video')) {
      const markers = []
      analysed_data &&
        analysed_data.forEach((el, idx) => {
          const { labels, texts, start_time } = el
          let highlight = false
          if (searchKey) {
            let keyword = searchKey.toLowerCase()
            keyword = keyword.split(' ')
            let textTags = [...texts, ...labels]
            textTags = textTags.toString().toLowerCase()
            for (const key of keyword) {
              if (!highlight && searchKey && textTags.includes(key)) {
                highlight = true
                break
              }
            }
          }
          markers.push({
            index: idx,
            time: highlight ? convertToSeconds(start_time) : 'N/A',
            formatted_time: reformatTime(start_time.replace(';', ':')),
            text: capitalize(texts.toString()),
            label: capitalize(labels.toString()),
            start_time: start_time.replace(';', ':'),
            highlight
          })
        })
      obj.markers = markers
    }
    setAssetData((assetData) => ({
      ...assetData,
      editableData: {
        ...assetData.editableData,
        tags
      }
    }))
    setAssetExtendedData(obj)
    setLoadingExtendedData(false)
  }

  const setInitalData = async () => {
    const assetClone = _.cloneDeep(asset)
    if (!isEmpty(assetClone)) {
      if (src === 'proposal') {
        const {
          id,
          data = {},
          height,
          width,
          hidden_from_search,
          resultType
        } = assetClone
        const {
          proposal_id,
          metadata,
          uploaded_on,
          uploaded_by,
          page_number,
          proposal_name,
          proposal_tags = {},
          caption = '',
          scanned_text = ''
        } = data
        const {
          metadata: imageMetaData = {},
          identified_as = '',
          labels = ''
        } = metadata || {}
        const { format } = imageMetaData
        const proposalData = {
          proposal: {
            proposal_name,
            proposal_id,
            page_number,
            proposal_tags
          }
        }
        if (labels) {
          proposalData.labels = labels.split(',').map((el) => el.trim())
        }
        if (identified_as) {
          proposalData.identified_as = identified_as
        }
        const otherData = {
          uploaded_on: moment(uploaded_on).format('ll'),
          uploaded_by
        }
        const editableData = {
          caption,
          scanned_text
        }
        const el = {
          metaData: metadata,
          infoData: proposalData,
          editableData,
          proposalTags: proposal_tags,
          hidden_from_search,
          id,
          originalFile,
          fileName,
          src,
          signedUrl: url,
          filetype: format,
          height,
          width,
          assetType: resultType,
          otherData
        }
        setAssetData(el)
      } else {
        const {
          resultType,
          id,
          hidden_from_search,
          data = {},
          height,
          width
        } = assetClone
        const {
          uploaded_on,
          image_name,
          uploaded_by,
          metadata = {},
          caption = '',
          scanned_text = '',
          segment = {}
        } = data
        const { format = 'jpg' } = metadata || {}
        const otherData = {
          uploaded_on: moment(uploaded_on).format('ll'),
          uploaded_by
        }
        let editableData = {}
        const el = {
          infoData: {},
          metaData: metadata,
          markers: [],
          hidden_from_search,
          id,
          assetType: resultType,
          originalFile,
          fileName,
          src,
          signedUrl: url,
          filetype: format,
          height,
          width,
          otherData,
          segment
        }
        if (resultType?.includes('image')) {
          if (scanned_text) {
            editableData = {
              caption,
              scanned_text
            }
          } else {
            editableData = {
              caption
            }
          }
        } else if (resultType?.includes('video')) {
          editableData = {
            caption
          }
        }
        el.editableData = editableData
        setAssetData(el)
      }
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [assetData])

  const OpenProposalPdf = (proposal) => {
    const { proposal_id, page_number = 0 } = proposal
    const newPath = `/view/pdf/${proposal_id}/${page_number}`
    window.open(newPath, '_blank')
  }

  const handleEdit = () => {
    if (editState) {
      handleSave()
    }
    setEditState(!editState)
  }

  const handleTagChange = (e, type) => {
    setAssetData({
      ...assetData,
      toSave: true,
      editableData: {
        ...assetData.editableData,
        [type]: e
      }
    })
  }
  const handleChangeValue = (e, type) => {
    setAssetData({
      ...assetData,
      toSave: true,
      editableData: {
        ...assetData.editableData,
        [type]: e.target.value
      }
    })
  }

  const handleSave = async () => {
    const { editableData, id, toSave, src, assetType } = assetData
    const saveData = {
      ...editableData,
      id,
      src,
      type: assetType
    }
    if (toSave) {
      const callback = () => {
        setAssetData({ ...assetData, toSave: false })
      }
      const res = await postAssets(saveData)
      if (res.status === 200) {
        callback()
        toast.success('Asset updated successfully')
      } else {
        toast.error('Unable to update asset')
      }
    }
  }

  const handleImageClick = (e) => {
    setGalleryImages([e])
  }

  const renderDetails = () => {
    const {
      src,
      infoData: assetInfoData = {},
      editableData: assetEditableData = {}
    } = assetData
    return (
      <Box className={infoData}>
        {Object.keys(assetInfoData || {}).map((key, indexData) =>
          key === 'proposal' ? (
            <>
              {assetInfoData?.[key].proposal_id && (
                <Box key={indexData} className={dataContainer}>
                  <Box className={keyclass}>{_.startCase(key)}</Box>
                  <Box
                    className={dataclass}
                    sx={{
                      cursor: 'pointer',
                      '& :hover': { textDecoration: 'underline' }
                    }}
                    onClick={() => OpenProposalPdf(assetInfoData[key])}
                  >
                    <Box component={'span'}>
                      <Box component={'span'}>
                        {assetInfoData?.[key].proposal_name}
                      </Box>
                      {assetInfoData?.[key].page_number && (
                        <Box component={'span'}>
                          &nbsp;(Page {assetInfoData?.[key].page_number})
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              {assetInfoData?.[key].proposal_tags.length > 0 && (
                <InputTags
                  description={'Proposal Tags'}
                  tags={assetInfoData?.[key].proposal_tags}
                  showTagOnly={true}
                  showLabel
                  label={'Proposal Tags'}
                />
              )}
            </>
          ) : key === 'labels' ? (
            <InputTags
              description={_.startCase(key)}
              initalTags={assetInfoData?.[key] || []}
              showLabel
              hideMoreTags
              showTagOnly={true}
              fullWidth={true}
              label={'Labels'}
              labelStyleProps={{
                fontWeight: '500',
                color: '#A2A2A2',
                fontSize: '15px'
              }}
            />
          ) : (
            <Box key={indexData} className={dataContainer}>
              <Box className={keyclass}>{_.startCase(key)}</Box>
              <Box className={dataclass}>
                {assetInfoData?.[key] ? assetInfoData?.[key] : '-'}
              </Box>
            </Box>
          )
        )}
        {Object.keys(assetEditableData || {}).map((key, indexData) =>
          key !== 'tags' ? (
            <Box key={indexData} className={dataContainer}>
              <Box className={keyclass}>{_.startCase(key)}</Box>
              {editState ? (
                <TextField
                  className={dataclass}
                  sx={{ width: '45%', '& input': { padding: '0px' } }}
                  defaultValue={assetEditableData?.[key]}
                  placeholder={'value'}
                  name={key}
                  onChange={(e) => handleChangeValue(e, key)}
                  variant="standard"
                />
              ) : (
                <Box className={dataclass}>
                  {assetEditableData?.[key] ? assetEditableData?.[key] : '-'}
                </Box>
              )}
            </Box>
          ) : (
            <>
              <InputTags
                description={_.startCase(key)}
                initalTags={assetEditableData?.[key] || []}
                showLabel
                hideMoreTags
                showTagOnly={!editState}
                fullWidth={true}
                setTags={(e) => handleTagChange(e, key)}
                label={key === 'tags' ? 'Media Tags' : _.startCase(key)}
                isObjectKey={'value'}
                additionalObjectData={{ type: 'userAdded' }}
              />
            </>
          )
        )}
      </Box>
    )
  }

  const ShowMoreLess = ({ text }) => {
    const [expanded, setExpanded] = useState(false)
    return (
      <Box className={markerText}>
        {expanded ? (
          <p>
            {text}
            <Box
              onClick={() => setExpanded(!expanded)}
              component="span"
              style={{ paddingLeft: '5px', color: 'blue' }}
            >
              show less
            </Box>
          </p>
        ) : text.length > 100 ? (
          <p>
            {text.substring(0, 100)}
            <Box
              onClick={() => setExpanded(!expanded)}
              component="span"
              style={{ paddingLeft: '5px', color: 'blue' }}
            >
              ...show more
            </Box>
          </p>
        ) : (
          <p>{text}</p>
        )}
      </Box>
    )
  }

  const handleVideoSeek = (mark) => {
    const player = playerRef.current
    const [hours, minutes, seconds, milliseconds] = mark.start_time.split(':')
    const seek_seconds =
      parseInt(hours) * 60 * 60 +
      parseInt(minutes) * 60 +
      parseInt(seconds) +
      milliseconds / 100
    player.currentTime(seek_seconds)
  }

  const renderChapters = () => {
    const { markers = [] } = assetExtendedData
    return (
      <Box className={timeStampContainer}>
        {loadingExtendedData ? (
          <Loader loading={loadingExtendedData} caption={''} />
        ) : (
          markers.length > 0 && (
            <>
              <Box sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Timeline
              </Box>
              {markers.map((mark, idx) => {
                let { formatted_time, text, label, highlight, index } = mark
                label = label.replaceAll(',', ' ')
                text = text.replaceAll(',', ' ')
                let markerWrapper = chapterDataContainer
                if (highlight) {
                  markerWrapper = clsx(searchWrapper, chapterDataContainer)
                }
                return (
                  <Box
                    className={markerWrapper}
                    key={idx}
                    id={'chapter_' + index}
                  >
                    <Box className={markerKeyTime}>
                      <Link
                        underline="none"
                        className={markerTime}
                        onClick={(e) => handleVideoSeek(mark)}
                      >
                        {formatted_time}
                      </Link>
                    </Box>
                    <Box className={markerKeyText}>
                      <ul className={listPointer}>
                        <li
                          className={liPointer}
                          onClick={() => handleVideoSeek(mark)}
                        >
                          <Box className={markerLabel}>
                            {_.startCase(label)}
                          </Box>
                        </li>
                        <ShowMoreLess text={_.startCase(text)} />
                      </ul>
                    </Box>
                  </Box>
                )
              })}
            </>
          )
        )}
      </Box>
    )
  }

  const setRelatedImages = (images) => {
    const imagesArray = []
    images.forEach((img, index) => {
      const { image_name, signedOrignal } = img
      imagesArray.push({
        src: signedOrignal,
        name: image_name
      })
    })
    setGalleryImages(imagesArray)
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Loader loading={loading} caption={''} />
      </Grid>
    )
  }

  return (
    <Container style={{ height: '100%' }}>
      {imagePaneSize === 'min' ? (
        <>
          <SectionFixed>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                background: 'white',
                zIndex: '1000',
                boxShadow: '0 2px 4px 0 rgba(229, 229, 229, 0.8)'
              }}
            >
              <Box>
                <Tooltip title={'Discover More'}>
                  <IconButton
                    disableRipple
                    onClick={() =>
                      setImagePaneSize(imagePaneSize === 'min' ? 'max' : 'min')
                    }
                    sx={{ color: 'black' }}
                  >
                    <SvgIcon style={{ height: '30px', width: '30px' }}>
                      {imagePaneSize === 'min' ? <CloseArrow /> : <OpenArrow />}
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title={'Close'}>
                  <IconButton disableRipple onClick={() => onClose()}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box>
              <AssetTypeMin
                asset={assetData}
                onClickImage={handleImageClick}
                triggerRender={triggerRender}
                playerRef={playerRef}
                setTriggerRender={setTriggerRender}
              />
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}
            >
              <Box sx={{ display: 'flex' }}>
                {/* <Tooltip title={editState ? 'Edit Data' : 'Save Edited Data'}>
                                    <IconButton disableRipple onClick={() => handleEdit()}>
                                        {editState ?
                                            <SaveIcon /> : <EditIcon />
                                        }
                                    </IconButton>
                                </Tooltip> */}
                <Tooltip title={'Download Asset'}>
                  <IconButton
                    disableRipple
                    onClick={(e) => {
                      trackEvent(
                        mixpanelEvents.ASSET_DOWNLOAD,
                        'SUCCESS',
                        {},
                        { original_file: originalFile, file_name: fileName }
                      )
                      initalizeDownload(originalFile, fileName)
                    }}
                  >
                    <DownloadIcon className="download" />
                  </IconButton>
                </Tooltip>
                <Box sx={{ display: 'flex' }}>
                  {/* {src === 'asset' &&
                                        <DeleteButton data={assetData} dispatch={dispatch} onDelete={onClose} />
                                    }
                                    {isAdmin &&
                                        <HideButton data={assetData} setData={setAssetData} signedImages={similarObj} dispatch={dispatch} setParentLoading={setParentLoading} setParentLoadingText={setParentLoadingText} />
                                    } */}
                </Box>
              </Box>
            </Box>
          </SectionFixed>
          <Section overFlow>
            {renderDetails()}
            {/* <Box sx={{ padding: '15px', fontWeight: 'bold' }}>
                            {isSuperUser && <Button onClick={() => setShowHideModel(true)}>Show all similar images</Button>}
                        </Box> */}
            {similarImages?.length > 0 && (
              <>
                <Box sx={{ padding: '15px', fontWeight: 'bold' }}>
                  Related Images
                </Box>
                <Box>
                  <SimilarityViewer
                    handleImageClick={(img, images) => setRelatedImages(images)}
                    signedImages={similarImages || []}
                    aspectRatio={similarImagesAspectRatios || []}
                  />
                </Box>
              </>
            )}
            <HideImages
              show={showHideModel}
              onClose={() => setShowHideModel(false)}
              parentImage={assetData || {}}
              s3Obj={s3Obj}
            />
          </Section>
        </>
      ) : (
        <Section overFlow>
          <Grid container style={{ height: '100%', padding: 0 }}>
            <Grid
              item
              xs={6}
              lg={8}
              xl={9}
              md={8}
              style={{
                height: '100%',
                padding: '20px',
                borderRight: '1px solid #E5E5E5',
                overflow: 'auto'
              }}
            >
              <AssetTypeMax
                asset={assetData}
                onClickImage={handleImageClick}
                assetExtendedData={assetExtendedData}
                loadingExtendedData={loadingExtendedData}
                triggerRender={triggerRender}
                playerRef={playerRef}
                setTriggerRender={setTriggerRender}
              />
            </Grid>
            <Grid item xs lg xl md style={{ height: '100%', overflow: 'auto' }}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: '1000',
                  display: 'flex',
                  justifyContent: 'space-between',
                  boxShadow: '0 2px 4px 0 rgba(229, 229, 229, 0.8)'
                }}
              >
                <Box>
                  <Tooltip title={'Discover More'}>
                    <IconButton
                      disableRipple
                      onClick={() =>
                        setImagePaneSize(
                          imagePaneSize === 'min' ? 'max' : 'min'
                        )
                      }
                      sx={{ color: 'black' }}
                    >
                      <SvgIcon style={{ height: '30px', width: '30px' }}>
                        {imagePaneSize === 'min' ? (
                          <CloseArrow />
                        ) : (
                          <OpenArrow />
                        )}
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title={'Close'}>
                    <IconButton disableRipple onClick={() => onClose()}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '15px',
                  justifyContent: 'end',
                  marginTop: '5px'
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Tooltip title={editState ? 'Edit Data' : 'Save Edited Data'}>
                    <IconButton disableRipple onClick={() => handleEdit()}>
                      {editState ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Download Asset'}>
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        trackEvent(
                          mixpanelEvents.ASSET_DOWNLOAD,
                          'SUCCESS',
                          {},
                          { original_file: originalFile, file_name: fileName }
                        )
                        initalizeDownload(originalFile, fileName)
                      }}
                    >
                      <DownloadIcon className="download" />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ display: 'flex' }}>
                    {src === 'asset' && (
                      <DeleteButton
                        data={assetData}
                        dispatch={dispatch}
                        onDelete={onClose}
                      />
                    )}
                    {isAdmin && (
                      <HideButton
                        data={assetData}
                        setData={setAssetData}
                        signedImages={similarObj}
                        dispatch={dispatch}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {renderDetails()}
              {resultType === 'video' && renderChapters()}
              {similarImages?.length > 0 && (
                <>
                  <SectionFixed>
                    <Box sx={{ padding: '15px', fontWeight: 'bold' }}>
                      Related Images
                    </Box>
                  </SectionFixed>
                  <Section overFlow>
                    <Box>
                      <SimilarityViewer
                        handleImageClick={(img, images) =>
                          setRelatedImages(images)
                        }
                        signedImages={similarImages || []}
                        aspectRatio={similarImagesAspectRatios || []}
                      />
                    </Box>
                  </Section>
                </>
              )}
            </Grid>
          </Grid>
        </Section>
      )}
      {galleryImages.length > 0 && (
        <ImageGallery
          images={galleryImages}
          onClose={() => setGalleryImages([])}
        />
      )}
    </Container>
  )
}

export default AssetViewer
