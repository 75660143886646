import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react'
import Box from '@mui/material/Box'
import { Container, Section, SectionFixed } from '../../components/Container'
import PDFViewer from '../../sections/PDFSectionEditorPDF'
import Editor from '../../sections/PDFSectionEditorPDF/Editor'
import Toolbar from '../../sections/PDFSectionEditorPDF/Toolbar'
import { useStyles } from './styles'
import _, { entries, set, template } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
import {
  getProposalBounding,
  saveProposalBounding,
  getTextExtractPageData,
  savePageData,
  getProjectExtract
} from '../../store/api'
import { useSelector, useDispatch } from 'react-redux'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import { setToasterAlert } from '../../store/Common/Actions'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as BackImg } from '../../assets/images/back.svg'
import { stripUnknownASCII, copyHTMLContent } from '../../utils/CopyHTML'
import InputTags from '../../components/InputTags'
import Loader from '../../components/Loader'
import { Grid } from '@material-ui/core'
import { getSignedUrl, initalizeAWSSigner } from '../../utils/AWS'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import { Button, ImageGallery } from '../../components'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { checkUserRoleSuperUser } from '../../utils/User'
import { PAGE_TYPE_KEYS, PAGE_SUBTYPE_KEYS } from '../../config/constants'
import Select, { components as selectComponents } from 'react-select'
import ProjectEditor from '../../sections/ProjectEditor/Editor'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import HelpIcon from '@mui/icons-material/Help'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import { newResumeTemplate } from '../../sections/ResumeResult/resumeStyle'
import { Auth } from 'aws-amplify'
import CloseIcon from '@mui/icons-material/Close'
import { useColor } from '../../ThemeContext'
const escapeUnclosedTags =
  '</ul></li></div></span></h1></h2></h3></h4></h5></h6></b></i></a></strong></sub></mark></sup>'

const ResumeEditor = lazy(() => import('../../sections/ResumeEditor/Editor'))
const PDFSectionEditor = (props) => {
  const { setShowSectionEditor, PDFUrl, PDFData, PDFOrgUrl, s3Obj, onClose } =
    props
  const { id: proposalId, name, created_for_domain } = PDFData
  const [mode, setMode] = useState('')
  const [backupAnnotations, setBackupAnnotations] = useState([])
  const [detectedPageDimensions, setDetectedPageDimensions] = useState({})
  const [isReadOnly, setIsReadOnly] = useState(true)
  const [toggleReadOnly, setToggleReadOnly] = useState(true)
  const [isScreenShotState, setIsScreenShotState] = useState(false)
  const [canvasState, setCanvasState] = useState(true)
  const [selectedEntry, setSelectedEntry] = useState('')
  const [isFocusViewer, setIsFocusViewer] = useState(false)
  const [isFocusEditor, setIsFocusEditor] = useState(false)
  const [selectedSubEntry, setSelectedSubEntry] = useState(null)
  const [editedState, setEditedState] = useState('')
  const classes = useStyles()
  const { titleContainer, borderBot, iconButton, pdfTitle, icon } = classes
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id } = domain || {}
  const [pdfWidth, setPdfWidth] = useState(0)
  const [editorWidth, setEditorWidth] = useState(0)
  const [toolbarWidth, setToolbarWidth] = useState(0)
  const { width, height, ref } = useResizeDetector()
  const [merge, setMerge] = useState(false)
  const [mergeChecked, setMergeChecked] = useState([])
  const [isSaving, setIsSaving] = useState(true)
  const dispatch = useDispatch()
  const [editorUpdating, seteditorUpdating] = useState(false)
  const [state, setState] = useState([])
  const [prevStates, setPrevStates] = useState([[]])
  const [futureStates, setFutureStates] = useState([])
  const [loading, setLoading] = useState(true)
  const [vimMode, setVimMode] = useState(false)
  const [dataPage, setDataPage] = useState(1)
  const [dataPageMeta, setDataPageMeta] = useState({})
  const [pageLimit, setPageLimit] = useState(100)
  const [elementShown, setElementShown] = useState('section')
  const [apiData, setApiData] = useState({})
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { jumpToPage } = pageNavigationPluginInstance
  const { pageNo } = useParams()
  const [pdfCurrentPage, setPdfCurrentPage] = useState(pageNo)
  const isSuperUser = checkUserRoleSuperUser()
  const [pageData, setPageData] = useState({})
  const [faces, setFaces] = useState({})
  const [resumeEditorState, setResumeEditorState] = useState('people')
  const [textBBox, setTextBBox] = useState([])
  const [selectedTextBBox, setSelectedTextBBox] = useState([])
  const [resumeReprocess, setResumeReprocess] = useState(false)
  const [projectReprocess, setProjectReprocess] = useState(false)
  const [resumeReprocessMode, setResumeReprocessMode] = useState('')
  const [projectReprocessMode, setProjectReprocessMode] = useState('')
  const [pageValue, setPageValue] = useState({
    from: '',
    to: ''
  })
  const [signerObj, setSignerObj] = useState({})
  const [selectedImage, setSelectedImage] = useState([])
  const { selectedColor } = useColor()

  let disableReprocess = true
  if (
    resumeReprocessMode === 'pagerange' ||
    projectReprocessMode === 'pagerange'
  ) {
    if (pageValue.from && pageValue.to) {
      disableReprocess = false
    }
  } else if (
    resumeReprocessMode === 'textextract' ||
    projectReprocessMode === 'textextract'
  ) {
    if (!_.isEmpty(textBBox)) {
      disableReprocess = false
    }
  }

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    setTextBBox([])
    setSelectedTextBBox(null)
  }, [resumeReprocessMode, selectedEntry, projectReprocessMode])

  useEffect(() => {
    if (selectedEntry) {
      setResumeReprocess(false)
      setProjectReprocess(false)
      setResumeReprocessMode('')
      setProjectReprocessMode('')
      setResumeEditorState('people')
    }
  }, [selectedEntry])

  useEffect(() => {
    if (resumeReprocess) {
      setSelectedEntry('')
      setSelectedSubEntry(null)
    }
  }, [resumeReprocess])

  useEffect(() => {
    if (projectReprocess) {
      setSelectedEntry('')
      setSelectedSubEntry(null)
    }
  }, [projectReprocess])

  const renderResumeReprocessMenu = () => {
    return (
      resumeReprocess && (
        <Paper sx={{ marginTop: '10px', padding: '10px' }} elevation={3}>
          <Box
            sx={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            Resume Extract Options
            <Tooltip
              title={
                <>
                  <Box>
                    Page Range: Contents extracted from the pages will be
                    parsed.
                  </Box>
                  <Box>
                    Bounding Boxes: Draw Bounding Boxes on the PDFto select.
                    Text will be sent in the order of the bbox drawn.
                  </Box>
                </>
              }
            >
              <IconButton disableRipple sx={{ padding: '0' }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container>
            <Grid
              item
              md={6}
              sm={6}
              sx={{
                padding: '0',
                color: mode === 'title' ? 'primary' : 'grey'
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  color:
                    resumeReprocessMode === 'pagerange'
                      ? selectedColor
                      : 'inherit',
                  textAlign: 'center'
                }}
                onClick={() => setResumeReprocessMode('pagerange')}
              >
                <Box>
                  <AutoStoriesIcon />
                </Box>
                Page Range
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              sx={{
                padding: '0',
                color: mode === 'title' ? 'primary' : 'grey'
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  color:
                    resumeReprocessMode === 'textextract'
                      ? selectedColor
                      : 'inherit',
                  textAlign: 'center'
                }}
                onClick={() => {
                  setResumeReprocessMode('textextract')
                  setCanvasState(true)
                }}
              >
                <Box>
                  <HighlightAltIcon />
                </Box>
                Bounding Boxes
              </Box>
            </Grid>
          </Grid>
          {resumeReprocessMode === 'pagerange' ? (
            <Box sx={{ marginTop: '10px' }}>
              <Box>Add Page Range to Extract</Box>
              <Box sx={{ marginTop: '5px' }}>
                From:
                <TextField
                  sx={{ width: '60px', '& input': { padding: '0px 5px' } }}
                  value={pageValue.from}
                  variant="standard"
                  onChange={(e) =>
                    setPageValue({
                      ...pageValue,
                      from: e.target.value
                    })
                  }
                />
                To:
                <TextField
                  sx={{ width: '60px', '& input': { padding: '0px 5px' } }}
                  value={pageValue.to}
                  variant="standard"
                  onChange={(e) =>
                    setPageValue({
                      ...pageValue,
                      to: e.target.value
                    })
                  }
                />
              </Box>
            </Box>
          ) : resumeReprocessMode === 'textextract' ? (
            <Box sx={{ marginTop: '10px' }}>
              <Box sx={{ marginTop: '5px' }}>
                <Tooltip title={'Create a bbox'}>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      handleMode('textextract')
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: mode === 'textextract' ? 'black' : 'lightgray'
                      }}
                    />
                  </IconButton>
                </Tooltip>
                Bounding Boxes Drawn {textBBox.length ? textBBox.length : 0}
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {resumeReprocessMode && (
            <Box sx={{ marginTop: '10px', textAlign: 'end' }}>
              <Button
                disabled={disableReprocess || isSaving}
                onClick={() => handleResumeReprocess()}
              >
                Extract
              </Button>
            </Box>
          )}
        </Paper>
      )
    )
  }

  const renderProjectReprocessMenu = () => {
    return (
      projectReprocess && (
        <Paper sx={{ marginTop: '10px', padding: '10px' }} elevation={3}>
          <Box
            sx={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            Project Extract Options
            <Tooltip
              title={
                <>
                  <Box>
                    Page Range: Contents extracted from the pages will be
                    parsed.
                  </Box>
                  <Box>
                    Bounding Boxes: Draw Bounding Boxes on the PDF to select.
                    Text will be sent in the order of the bbox drawn.
                  </Box>
                </>
              }
            >
              <IconButton disableRipple sx={{ padding: '0' }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container>
            {/* <Grid item md={6} sm={6} sx={{ padding: '0', color: mode === 'title' ? 'primary' : 'grey' }}>
            <Box sx={{ cursor: 'pointer', color: projectReprocessMode === 'pagerange' ? '#0645AD' : 'inherit', textAlign: 'center' }} onClick={() => setProjectReprocessMode('pagerange')}>
              <Box>
                <AutoStoriesIcon />
              </Box>
              Page Range
            </Box>
          </Grid> */}
            <Grid
              item
              md={6}
              sm={6}
              sx={{
                padding: '0',
                color: mode === 'title' ? 'primary' : 'grey'
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  color:
                    projectReprocessMode === 'textextract'
                      ? selectedColor
                      : 'inherit',
                  textAlign: 'center'
                }}
                onClick={() => {
                  setProjectReprocessMode('textextract')
                  setCanvasState(true)
                }}
              >
                <Box>
                  <HighlightAltIcon />
                </Box>
                Bounding Boxes
              </Box>
            </Grid>
          </Grid>
          {projectReprocessMode === 'pagerange' ? (
            <Box sx={{ marginTop: '10px' }}>
              <Box>Add Page Range to Extract</Box>
              <Box sx={{ marginTop: '5px' }}>
                From:
                <TextField
                  sx={{ width: '60px', '& input': { padding: '0px 5px' } }}
                  value={pageValue.from}
                  variant="standard"
                  onChange={(e) =>
                    setPageValue({
                      ...pageValue,
                      from: e.target.value
                    })
                  }
                />
                To:
                <TextField
                  sx={{ width: '60px', '& input': { padding: '0px 5px' } }}
                  value={pageValue.to}
                  variant="standard"
                  onChange={(e) =>
                    setPageValue({
                      ...pageValue,
                      to: e.target.value
                    })
                  }
                />
              </Box>
            </Box>
          ) : projectReprocessMode === 'textextract' ? (
            <Box sx={{ marginTop: '10px' }}>
              <Box sx={{ marginTop: '5px' }}>
                <Tooltip title={'Create a bbox'}>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      handleMode('textextract')
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: mode === 'textextract' ? 'black' : 'lightgray'
                      }}
                    />
                  </IconButton>
                </Tooltip>
                Bounding Boxes Drawn {textBBox.length ? textBBox.length : 0}
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {projectReprocessMode && (
            <Box sx={{ marginTop: '10px', textAlign: 'end' }}>
              <Button
                disabled={disableReprocess || isSaving}
                onClick={() => handleProjectReprocess()}
              >
                Extract
              </Button>
            </Box>
          )}
        </Paper>
      )
    )
  }

  const navigate = useNavigate()

  const handleUndo = () => {
    if (prevStates.length > 2) {
      const previousState = prevStates[prevStates.length - 2]
      const previousStateClone = _.cloneDeep(previousState)
      setFutureStates([...futureStates, prevStates[prevStates.length - 1]])
      setState(previousStateClone)
      setPrevStates(prevStates.slice(0, prevStates.length - 1))
      clearState(true, previousStateClone)
    }
  }

  const handleRedo = useCallback(() => {
    if (futureStates.length > 0) {
      const nextState = futureStates[futureStates.length - 1]
      setState(nextState)
      setPrevStates([...prevStates, nextState])
      setFutureStates(futureStates.slice(0, futureStates.length - 1))
      clearState(true, nextState)
    }
  }, [futureStates, state, prevStates])

  const setAnnotations = (val) => {
    setPrevStates([...prevStates, val])
    const valClone = _.clone(val)
    setState(valClone)
    setFutureStates([])
  }

  const clearState = (check, annotationState) => {
    setMode('')
    setMerge(false)
    setMergeChecked([])
    if (check) {
      const annState = _.cloneDeep(annotationState)
      const index = _.findIndex(annState, { id: selectedEntry?.id })
      const indexSub = _.findIndex(annState[index][selectedSubEntry?.type], {
        id: selectedSubEntry?.id
      })
      if (index === -1) {
        setSelectedEntry('')
      } else {
        setSelectedEntry(annState[index])
      }
      if (indexSub === -1) {
        setSelectedSubEntry(null)
      } else {
        setSelectedSubEntry(annState[index][selectedSubEntry?.type][indexSub])
      }
    } else {
      setSelectedEntry('')
      setSelectedSubEntry(null)
    }
  }

  const disableUndo = !(prevStates.length > 2)
  const disableRedo = !(futureStates.length > 0)

  const annotations = state || []

  async function signScreenshots(screenshots) {
    const promises = screenshots.map(async (e, i) => {
      const { src } = e
      const signedSrc = await getSignedUrl(src, s3Obj)
      screenshots[i] = { ...e, src: signedSrc }
    })
    await Promise.all(promises)
    return screenshots
  }

  useEffect(() => {
    if (elementShown && !_.isEmpty(apiData)) {
      setLoading(true)
      setSelectedEntry('')
      setSelectedSubEntry(null)
      setMode('')
      setMerge(false)
      setMergeChecked([])
      const { data, resume_data, project_data = [] } = apiData
      const cloneData = _.cloneDeep(data)
      const resume_data_clone = _.cloneDeep(resume_data)
      const project_data_clone = _.cloneDeep(project_data)
      setData(cloneData, resume_data_clone, project_data_clone)
    } else {
      setLoading(false)
    }
  }, [elementShown, apiData])

  const getRandomColor = () => {
    let randomColor = ''

    do {
      randomColor = Math.floor(Math.random() * 16777215).toString(16)
    } while (randomColor === 'ffffff')

    return '#' + randomColor
  }

  const setData = async (data, resume_data = [], project_data = []) => {
    const entries = []
    if (elementShown === 'section') {
      resume_data.forEach((item, index) => {
        const resume_data_parsed =
          typeof resume_data[index]?.resume_data === 'string'
            ? JSON.parse(resume_data[index]?.resume_data)
            : resume_data[index]?.resume_data
        const profile_picture_location = item?.master?.people_data?.[0]
          ?.profile_picture_location
          ? {
              src: item?.master?.people_data?.[0]?.profile_picture_location,
              face_id: item?.metadata_?.mapped_face_id,
              face_group_id: item?.face_group_id
            }
          : ''
        const newObj = {
          ...resume_data[index],
          parsedData: resume_data_parsed,
          screenshots: resume_data[index]?.metadata_?.screenshots
            ? resume_data[index].metadata_.screenshots
            : [],
          profile_picture_location,
          del_screenshots: [],
          pageIndex: item?.page_number,
          type: 'resume'
        }
        entries.push(newObj)
      })
    }
    if (elementShown === 'section') {
      project_data.forEach((item, index) => {
        let project_data_parsed = {}
        if (typeof project_data[index]?.project_data === 'string') {
          try {
            project_data_parsed = JSON.parse(
              (project_data[index]?.project_data).replace(/'/g, '"')
            )
          } catch (e) {
            project_data_parsed = {}
          }
        } else {
          project_data_parsed = project_data[index]?.project_data
        }
        const newObj = {
          ...project_data[index],
          parsedData: project_data_parsed,
          screenshots: project_data[index]?.metadata_?.screenshots
            ? project_data[index].metadata_.screenshots
            : [],
          // profile_picture_location,
          del_screenshots: [],
          pageIndex: item?.page_number,
          type: 'project'
        }
        if (project_data_parsed !== {}) {
          entries.push(newObj)
        }
      })
    }

    for (const key of Object.keys(data)) {
      let {
        section = [],
        title = [],
        page_dimensions,
        keywords,
        screenshots = [],
        type,
        hidden_from_search = false
      } = data[key]
      const checkType =
        elementShown === 'section'
          ? type === 'section-temp'
            ? !hidden_from_search
            : ['section', 'section-temp'].includes(type)
          : type === elementShown
      if (checkType) {
        screenshots = await signScreenshots(screenshots)
        const color = getRandomColor()
        const pageIndex = title?.[0]?.pageIndex
          ? title[0].pageIndex
          : section?.[0]?.pageIndex
          ? section[0].pageIndex
          : 1
        title.forEach((e, i) => {
          const left = e.bounds[0]
          const right = e.bounds[2]
          const top = e.bounds[3]
          const bottom = e.bounds[1]
          const refomated = {
            x: Math.abs(left / (e.width / 100)),
            y:
              type === 'section-temp'
                ? Math.abs(bottom / (e.height / 100))
                : Math.abs(top / (e.height / 100) - 100),
            width: Math.abs((right - left) / (e.width / 100)),
            height: Math.abs((top - bottom) / (e.height / 100)),
            id: uuid(),
            fill: e?.fill ? e.fill : color,
            opacity: '.2',
            type: 'title',
            pageIndex: e.pageIndex,
            visible: true,
            text: e.text,
            org_height: e.height,
            org_width: e.width
          }
          title[i] = refomated
        })

        section.forEach((e, i) => {
          const left = e.bounds[0]
          const right = e.bounds[2]
          const top = e.bounds[3]
          const bottom = e.bounds[1]
          const refomated = {
            x: Math.abs(left / (e.width / 100)),
            y:
              type === 'section-temp'
                ? Math.abs(bottom / (e.height / 100))
                : Math.abs(top / (e.height / 100) - 100),
            width: Math.abs((right - left) / (e.width / 100)),
            height: Math.abs((top - bottom) / (e.height / 100)),
            id: uuid(),
            fill: e?.fill ? e.fill : color,
            opacity: '.2',
            type: 'section',
            pageIndex: e.pageIndex,
            visible: true,
            text: e.text,
            org_height: e.height,
            org_width: e.width
          }
          section[i] = refomated
        })
        screenshots = await signScreenshots(screenshots)
        const { titleText, sectionText } = constructTextData(data[key])
        const obj = {
          color,
          title,
          section,
          id: key,
          pageIndex,
          visible: true,
          page_dimensions,
          screenshots,
          tags: _.uniq(_.compact(keywords?.split(','))),
          titleText: removeUnwantedHyphens(titleText),
          sectionText: removeUnwantedHyphens(sectionText),
          type,
          hidden_from_search
        }
        if (type === 'image') {
          const {
            caption,
            hidden_from_search,
            image_name,
            image_url,
            image_thumbnail_url,
            classification = ''
          } = data[key]
          obj.caption = caption
          obj.hidden_from_search = hidden_from_search
          obj.images = {
            name: image_name,
            src: image_url,
            thumbnail: image_thumbnail_url
          }
          obj.classification = classification
        }
        setDetectedPageDimensions(page_dimensions)
        entries.push(obj)
      }
    }

    const sortedEntries = _.sortBy(entries, ['pageIndex'])
    sortedEntries.forEach((ent, index) => {
      sortedEntries[index].index = index
      sortedEntries[index]?.title &&
        sortedEntries[index].title.forEach((tit, subIndex) => {
          sortedEntries[index].title[subIndex].parentIndex = index
          sortedEntries[index].title[subIndex].index = subIndex
          sortedEntries[index].title[subIndex].parentType =
            sortedEntries[index].type
        })
      sortedEntries[index]?.section &&
        sortedEntries[index].section.forEach((sec, subIndex) => {
          sortedEntries[index].section[subIndex].parentIndex = index
          sortedEntries[index].section[subIndex].index = subIndex
          sortedEntries[index].section[subIndex].parentType =
            sortedEntries[index].type
        })
    })
    if (!_.isEmpty(sortedEntries)) {
      setState(sortedEntries)
      setBackupAnnotations(sortedEntries)
      setPrevStates([...prevStates, sortedEntries])
    }
    setLoading(false)
  }

  useEffect(() => {
    if (proposalId && dataPage) {
      setIsSaving(true)
      setIsReadOnly(true)
      fetchData()
    }
  }, [proposalId, dataPage, pageLimit])

  useEffect(() => {
    setDataPage(1)
  }, [pageLimit])

  const fetchData = async () => {
    if (domain_id && proposalId) {
      const res = await getProposalBounding(
        domain_id,
        proposalId,
        dataPage,
        pageLimit
      )
      if (res.status === 200) {
        const {
          data,
          file_status,
          current_page,
          pdf_start_page,
          pdf_end_page,
          total_pages,
          has_more_rows,
          page_size,
          page_data,
          resume_data = [],
          face_data = []
        } = res.data
        if (file_status === 'completed') {
          setPageData(page_data)
          setApiData(res.data)
          setIsSaving(false)
          setIsReadOnly(false)
          setDataPageMeta({
            current_page,
            pdf_start_page,
            pdf_end_page,
            total_pages,
            has_more_rows,
            page_size
          })
          Object.keys(face_data).forEach(async (key) => {
            face_data[key].forEach(async (item, index) => {
              face_data[key][index] = {
                ...face_data[key][index],
                signedUrl: await getSignedUrl(item?.location, s3Obj)
              }
            })
          })
          setFaces(face_data)
        } else {
          setApiData(res.data)
          setLoading(false)
          setIsSaving(false)
          setIsReadOnly(true)
          setToggleReadOnly(false)
          setDataPageMeta({
            current_page,
            pdf_start_page,
            pdf_end_page,
            total_pages,
            has_more_rows,
            page_size
          })
          dispatch(
            setToasterAlert({
              message: `File is currently under process. Status: ${file_status}`,
              show: true,
              type: 'error'
            })
          )
        }
      }
    }
  }

  const preprocessText = (text) => {
    text = removeStyleAttribute(text)
    return text
  }

  const constructTextData = (obj, force) => {
    let { section = [], title = [], sectionText, titleText } = obj
    if (!titleText || force) {
      let htmlAdd = ''
      title.forEach((element) => {
        let { text } = element
        if (text) {
          text = preprocessText(text)
          htmlAdd = htmlAdd + `<p>${text}</p>${escapeUnclosedTags}<br>`
        }
      })
      titleText = htmlAdd
    }
    if (!sectionText || force) {
      let htmlAdd = ''
      section.forEach((element) => {
        let { text } = element
        if (text) {
          text = preprocessText(text)
          htmlAdd = htmlAdd + `<p>${text}</p>${escapeUnclosedTags}<br>`
        }
      })
      sectionText = htmlAdd
    }
    titleText = removeEmptyTableElements(titleText)
    sectionText = removeEmptyTableElements(sectionText)
    return { titleText, sectionText }
  }

  const removeUnwantedHyphens = (htmlString) => {
    if (!htmlString.startsWith('<p')) {
      htmlString = htmlString.replaceAll(' - ', '')
    }
    return htmlString
  }

  const reConstructTextData = (obj, ann) => {
    const text = constructTextData(obj, true)
    const newObj = { ...obj, ...text, updateStatus: 'edit' }
    const index = _.findIndex(ann, { id: obj.id })
    const newAnn = _.cloneDeep(ann)
    newAnn[index] = newObj
    if (selectedEntry.id === obj.id) {
      setSelectedEntry(newObj)
    }
    setAnnotations(newAnn)
  }

  const handleVisibility = (entry, value) => {
    const newAnn = _.cloneDeep(annotations)
    const newArr = newAnn.map((obj) => {
      if (obj.id === entry.id) {
        return { ...obj, visible: value }
      }
      return obj
    })
    setAnnotations(newArr)
  }

  const canvasToggle = () => {
    setCanvasState(!canvasState)
  }

  const updateEntryColor = (hex, entry) => {
    const title = []
    const section = []
    const newAnn = _.cloneDeep(annotations)
    const newArr = newAnn.map((obj) => {
      if (obj.id === entry.id) {
        obj.title.forEach((val) => {
          const titleVal = { ...val, fill: hex }
          title.push(titleVal)
        })
        obj.section.forEach((val) => {
          const sectionVal = { ...val, fill: hex }
          section.push(sectionVal)
        })
        return { ...obj, color: hex, title, section }
      }
      return obj
    })
    const newEntry = newArr.find((obj) => obj.id === entry.id)
    setAnnotations(newArr)
  }

  const handleCreateEntry = () => {
    const type = elementShown
    const ann = annotations
    const newId = uuid()
    const randomColor = getRandomColor()
    let obj = {
      id: newId,
      color: '#' + randomColor,
      pageIndex: 'new',
      index: ann.length,
      tags: [],
      title: [],
      section: [],
      visible: true,
      screenshots: [],
      sectionText: '',
      titleText: '',
      type
    }
    if (type === 'image') {
      obj = {
        ...obj,
        caption: ''
      }
    }
    ann.push(obj)
    setAnnotations(ann)
    setSelectedEntry(obj)
  }

  const handleDeleteEntry = async (entry) => {
    const index = _.findIndex(annotations, { id: entry.id })
    if (entry.type === 'resume') {
      const newAnn = _.cloneDeep(annotations)
      newAnn[index].updateStatus = 'delete'
      const newFaces = _.cloneDeep(faces)
      const { src } = newAnn[index]?.profile_picture_location
      const result = findMatchingFace(faces, src)
      const { key: facePage, index: faceIndex } = result
      if (faceIndex !== undefined && facePage !== undefined) {
        newFaces[facePage][faceIndex].mapped_people_ids = newFaces[facePage][
          faceIndex
        ]?.mapped_people_ids?.filter((id) => id !== newAnn[index].id)
        newFaces[facePage][faceIndex].updateStatus = 'edit'
      }
      setAnnotations(newAnn)
      setFaces(newFaces)
    } else {
      const newAnn = _.cloneDeep(annotations)
      newAnn[index].updateStatus = 'delete'
      if (selectedEntry.id === entry.id) {
        setSelectedEntry('')
      }
      setAnnotations(newAnn)
    }
  }

  const handleSubEntryDelete = (sub) => {
    const index = _.findIndex(annotations, { id: selectedEntry.id })
    const newAnn = _.cloneDeep(annotations)
    const indexSub = _.findIndex(newAnn[index][sub.type], { id: sub.id })
    newAnn[index][sub.type].splice(indexSub, 1)
    newAnn[index].updateStatus = 'edit'
    setAnnotations(newAnn)
    setSelectedEntry(newAnn[index])
    if (selectedSubEntry?.id === sub?.id) {
      setSelectedSubEntry(null)
    }
  }

  const handleSubVisiblity = (sub, value) => {
    const index = _.findIndex(annotations, { id: selectedEntry.id })
    const newAnn = _.cloneDeep(annotations)
    const indexSub = _.findIndex(newAnn[index][sub.type], { id: sub.id })
    newAnn[index][sub.type][indexSub].visible = value
    setAnnotations(newAnn)
  }

  const handleHidefromSearch = (entry, value) => {
    const index = _.findIndex(annotations, { id: entry.id })
    const newAnn = _.cloneDeep(annotations)
    newAnn[index].hidden_from_search = value
    newAnn[index].updateStatus = 'edit'
    setAnnotations(newAnn)
  }

  const handleGenerateText = (attrs, asList, type) => {
    const pageElement = document.querySelectorAll(
      `[data-testid=core__page-layer-${parseInt(attrs.pageIndex) - 1}]`
    )
    const { clientWidth = 1, clientHeight = 1 } = pageElement[0]
    const textElement = pageElement[0]?.querySelectorAll(
      '.rpv-core__text-layer-text'
    )
    const te = []

    const reCalculatedValue = {
      ...attrs,
      x: (attrs.x * clientWidth) / 100,
      y: (attrs.y * clientHeight) / 100,
      width: (attrs.width * clientWidth) / 100,
      height: (attrs.height * clientHeight) / 100
    }

    const rectSelection = {
      top: reCalculatedValue.y,
      left: reCalculatedValue.x,
      bottom: reCalculatedValue.height + reCalculatedValue.y,
      right: reCalculatedValue.width + reCalculatedValue.x,
      width: reCalculatedValue.height,
      height: reCalculatedValue.width
    }
    const parentPos = pageElement[0].getBoundingClientRect()

    textElement.forEach((el) => {
      const childPos = el.getBoundingClientRect()
      const relativePos = {}
      relativePos.top = Math.abs(childPos.top - parentPos.top)
      relativePos.left = Math.abs(childPos.left - parentPos.left)
      relativePos.right = relativePos.left + childPos.width
      relativePos.bottom = relativePos.top + childPos.height
      if (
        relativePos.bottom > rectSelection.top &&
        relativePos.right > rectSelection.left &&
        relativePos.top < rectSelection.bottom &&
        relativePos.left < rectSelection.right
      ) {
        te.push(el?.outerHTML)
      }
    })
    let htmlString = te.join('')
    const htmlArray = orderElements(htmlString)
    if (asList) {
      if (type === 2) {
        let listString = '<p>'
        let isList = false
        for (let i = 0; i < htmlArray.length; i++) {
          if (htmlArray[i].textContent.trim()) {
            if (_.isFinite(parseInt(htmlArray[i].textContent))) {
              if (!isList) {
                isList = true
                listString = listString + '<ol>'
              }
              i = i + 1
              listString = listString + `</li><li>${htmlArray[i]?.outerHTML}`
            } else {
              if (isList) {
                listString = listString + ` ${htmlArray[i].outerHTML}`
              } else {
                listString = listString + `${htmlArray[i].outerHTML}`
              }
            }
          }
        }
        listString = listString + '</ol></p>'
        htmlString = listString
      } else if (type === 1) {
        let listString = '<p>'
        let isList = false
        htmlArray.forEach((el) => {
          if (['•', '.', '>', 'o'].includes(el.textContent)) {
            if (!isList) {
              isList = true
              listString = listString + '<ul>'
            }
            listString = listString + `</li><li>${el.outerHTML}`
          } else {
            listString = listString + ` ${el.outerHTML}`
          }
        })
        listString = listString + '</ul></p>'
        htmlString = listString
      } else if (type === 3) {
        let listString = '<p><ul>'
        htmlArray.forEach((el) => {
          listString = listString + `<li>${el.outerHTML}</li>`
        })
        listString = listString + '</ul></p>'
        htmlString = listString
      }
    } else {
      let prevHeight = ''
      let lineString = '<span>'
      let escapeSpan = 0
      htmlArray.forEach((el, ind) => {
        if ([1, 2, 3, 4].includes(escapeSpan)) {
          escapeSpan = escapeSpan + 1
        } else {
          escapeSpan = 0
        }
        if (el) {
          const currHeight = el?.style?.top
          const currTextContent = el?.textContent
          const nextHeight = htmlArray[ind + 1]?.style?.top
          const nextAfterTextContent = htmlArray[ind + 2]?.textContent
          const nextAfterHeight = htmlArray[ind + 2]?.style?.top
          if (
            parseInt(currHeight) !== parseInt(nextHeight) &&
            currTextContent.endsWith('-')
          ) {
            el.textContent = el.textContent.slice(0, -1)
            escapeSpan = 2
          }
          if (
            parseInt(currHeight) !== parseInt(nextAfterHeight) &&
            nextAfterTextContent === '-'
          ) {
            htmlArray[ind + 2] = ''
            escapeSpan = 1
          }
          if (prevHeight) {
            const diffHeight = Math.abs(
              parseInt(currHeight) - parseInt(prevHeight)
            )
            if (diffHeight > 20) {
              lineString = lineString + '<p></p>'
            } else if (diffHeight !== 0 && ![3, 4].includes(escapeSpan)) {
              lineString = lineString + '<span> </span>'
            }
          }
          lineString = lineString + el.outerHTML
          prevHeight = currHeight
        }
      })
      lineString = lineString + '</span>'
      htmlString = lineString
    }
    const styleStripped = removeStyleAttribute(htmlString)
    const index = _.findIndex(annotations, { id: selectedEntry.id })
    const newAnn = _.cloneDeep(annotations)
    const indexSub = _.findIndex(newAnn[index][attrs.type], { id: attrs.id })
    if (indexSub > -1) {
      newAnn[index][attrs.type][indexSub].text = styleStripped
    }
    newAnn[index].updateStatus = 'edit'
    if (attrs.type === 'title') {
      const { titleText = '' } = newAnn[index]
      if (styleStripped) {
        newAnn[index].titleText = titleText + `<br><p>${styleStripped}</p>`
      }
    } else {
      const { sectionText = '' } = newAnn[index]
      if (styleStripped) {
        newAnn[index].sectionText = sectionText + `<br><p>${styleStripped}</p>`
      }
    }
    setAnnotations(newAnn)
    const newEntry = newAnn[index]
    setSelectedEntry(newEntry)
    copyHTMLContent(styleStripped)
  }

  const removeStyleAttribute = (htmlString) => {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const elementsWithStyle = div.querySelectorAll('[style]')
    elementsWithStyle.forEach((element) => {
      element.removeAttribute('style')
    })
    return div.innerHTML
  }

  const orderElements = (htmlString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const elements = Array.from(
      doc.querySelectorAll('.rpv-core__text-layer-text')
    )
    elements.sort((a, b) => {
      const aLeft = parseInt(a.style.left, 10)
      const bLeft = parseInt(b.style.left, 10)
      const aTop = parseInt(a.style.top, 10)
      const bTop = parseInt(b.style.top, 10)

      if (aTop === bTop || Math.abs(aTop - bTop < 15)) {
        return aLeft - bLeft
      }

      return aTop - bTop
    })
    return elements
  }

  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect()
    const vWidth = window.innerWidth || document.documentElement.clientWidth
    const vHeight = window.innerHeight || document.documentElement.clientHeight
    const efp = function (x, y) {
      return document.elementFromPoint(x, y)
    }

    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    ) {
      return false
    }

    return (
      el.contains(efp(rect.left, rect.top)) ||
      el.contains(efp(rect.right, rect.top)) ||
      el.contains(efp(rect.right, rect.bottom)) ||
      el.contains(efp(rect.left, rect.bottom))
    )
  }

  const setGoToPage = (pageIndex) => {
    if (pageIndex > -1 && pageIndex !== 'new') {
      jumpToPage(pageIndex)
    }
    const { id, type } = selectedSubEntry || {}
    if (id && type) {
      const element = document.getElementById(`listItem_${type}_${id}`)
      if (element && !isElementVisible(element)) {
        element.scrollIntoView()
      }
    }
  }

  useEffect(() => {
    const { id } = selectedEntry || {}
    if (id) {
      const element = document.getElementById(`listItem_${id}`)
      if (element && !isElementVisible(element)) {
        element.scrollIntoView()
      }
    }
  }, [selectedEntry])

  useEffect(() => {
    handleOnResize()
  }, [width])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    const saveQueue = _.filter(annotations, function (o) {
      return o?.updateStatus
    })
    if (!_.isEmpty(saveQueue)) {
      window.addEventListener('beforeunload', alertUser)
      return () => window.removeEventListener('beforeunload', alertUser)
    } else {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [annotations])

  const handleOnResize = (event, obj) => {
    if (event && obj) {
      const { handle } = obj
      const sizeWidth = obj?.size?.width
      if (handle === 'w') {
        const diff = sizeWidth - editorWidth
        const newPWidth = pdfWidth + diff
        const newTWidth = width - editorWidth - newPWidth - 2
        if (
          (newPWidth / width) * 100 > 40 &&
          (newTWidth / width) * 100 > 10 &&
          (newTWidth / width) * 100 < 30
        ) {
          setPdfWidth(newPWidth)
          setToolbarWidth(newTWidth)
        }
      } else {
        const diff = sizeWidth - editorWidth
        const newPWidth = pdfWidth + diff
        const newEWidth = width - toolbarWidth - newPWidth - 2
        if ((newPWidth / width) * 100 > 40 && (newEWidth / width) * 100 > 20) {
          setPdfWidth(newPWidth)
          setEditorWidth(newEWidth)
        }
      }
    } else {
      const pWidth = Math.round((50 / 100) * width)
      const eWidth = Math.round((30 / 100) * width)
      const tWidth = width - eWidth - pWidth - 2
      setPdfWidth(pWidth)
      setEditorWidth(eWidth)
      setToolbarWidth(tWidth)
    }
  }

  useEffect(() => {
    if (selectedEntry.type !== 'section-temp') {
      updateHtmlString(editedState)
    }
  }, [editedState])

  const updateHtmlString = (htmlString) => {
    const objArr = extractDatafromHtml(htmlString)
    const newAnn = _.cloneDeep(annotations)
    let updateFlag = false
    const index = _.findIndex(annotations, { id: selectedEntry.id })
    if (index > -1) {
      if (newAnn[index].titleText !== objArr[0]) {
        newAnn[index].titleText = removeUnclosedTags(objArr[0])
        updateFlag = true
      }
      if (newAnn[index].sectionText !== objArr[1]) {
        newAnn[index].sectionText = removeUnclosedTags(objArr[1])
        updateFlag = true
      }
    }
    if (updateFlag) {
      newAnn[index].updateStatus = 'edit'
      setAnnotations(newAnn)
    }
  }
  const removeUnclosedTags = (htmlString) => {
    const parser = new DOMParser()
    const dom = parser.parseFromString(htmlString, 'text/html')
    dom.querySelectorAll('*').forEach((node) => {
      if (node.innerHTML === '') {
        node.parentNode.removeChild(node)
      }
    })
    return trimGeneratedHtml(dom.documentElement.outerHTML)
  }

  const removeEmptyTableElements = (htmlString) => {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const tables = div.getElementsByTagName('table')
    for (let i = tables.length - 1; i >= 0; i--) {
      const table = tables[i]
      const cells = table.getElementsByTagName('td')
      let empty = true
      for (let j = 0; j < cells.length; j++) {
        if (cells[j].innerHTML.trim() !== '') {
          empty = false
          break
        }
      }
      if (empty) {
        table.parentNode.removeChild(table)
      }
    }
    return div.innerHTML
  }

  const extractDatafromHtml = (htmlString) => {
    htmlString = htmlString || ''
    htmlString = htmlString.replace(
      '<p class="PlaygroundEditorTheme__paragraph"><span> </span></p>',
      ''
    )
    htmlString = htmlString.replace(
      '<h2 class="PlaygroundEditorTheme__h2"><span>Title:</span></h2>',
      ''
    )
    htmlString = htmlString.replace(
      '<h2 class="PlaygroundEditorTheme__h2"><span>Section:</span></h2>',
      ''
    )
    const stringArr = htmlString.split('======')
    return stringArr
  }

  const toFloat = (x) => {
    return Number.parseFloat(x).toFixed(2)
  }

  const toDeleteScreenshots = (oldScreenshots, screenshots) => {
    return oldScreenshots.filter((oldScreenshot) => {
      return !screenshots.find((screenshot) => {
        return screenshot.src === oldScreenshot.src
      })
    })
  }

  const trimGeneratedHtml = (htmlString) => {
    htmlString = removeEmptyTableElements(htmlString)
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const body = doc.querySelector('body')
    const elements = Array.from(body.children)

    // Find the index of the first non-empty element
    const firstNonEmptyIndex = elements.findIndex(
      (el) => el.textContent.trim() !== ''
    )
    if (firstNonEmptyIndex === -1) {
      return ''
    }

    // Find the index of the last non-empty element
    let lastNonEmptyIndex = elements
      .slice()
      .reverse()
      .findIndex((el) => el.textContent.trim() !== '')
    if (lastNonEmptyIndex !== -1) {
      lastNonEmptyIndex = elements.length - 1 - lastNonEmptyIndex
    }

    // Remove all elements before the first non-empty element
    for (let i = 0; i < firstNonEmptyIndex; i++) {
      elements[i].remove()
    }

    // Remove all elements after the last non-empty element
    for (let i = elements.length - 1; i > lastNonEmptyIndex; i--) {
      elements[i].remove()
    }
    return body.innerHTML
  }

  const handleRenderPrediction = async () => {
    const { domain_id, profile_picture_location = {} } = selectedEntry
    const { face_id } = profile_picture_location || {}
    const { id } = selectedEntry
    const index = _.findIndex(annotations, { id })
    const newAnn = _.cloneDeep(annotations)

    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Predicting Resume Mapping...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )
    // const data = {
    //   "face_id": "beef1b4d-e0a9-4348-aa05-9578cbc17ab2",
    //   "domain_id": "3b54bbff-9a13-4e04-878f-704c6404c6fe"
    // };
    const data = {
      face_id,
      domain_id
    }
    const url = process.env.REACT_APP_PREDICT_MAPPING_URL || ''
    const apiUrl = new URL(url)
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname
      }
    })
    try {
      const response = await fetch(`${url}`, { ...signedUrl })
      toast.dismiss(toastId)
      if (response.status === 200) {
        const res = await response.json()
        newAnn[index].predictions = res
        setSelectedEntry(newAnn[index])
        setAnnotations(newAnn)
        dispatch(
          setToasterAlert({
            message: 'Mapping Extracted',
            show: true,
            type: 'success'
          })
        )
      } else {
        dispatch(
          setToasterAlert({
            message: 'Failed to extract mapping',
            show: true,
            type: 'error'
          })
        )
      }
    } catch (err) {
      console.log(err)
      toast.dismiss(toastId)
    }
  }

  const handleSave = async () => {
    setIsReadOnly(true)
    setIsSaving(true)
    let newAnn = _.cloneDeep(annotations)
    const toUpdateData = []
    const emptyUpdate = []
    const toUpdateResume = []
    const toUpdateFace = []
    const toUpdateProject = []
    // domain_id: str = Query(min_length=1)
    // id: str = Query(min_length=1)
    // page_number: int
    // project_data: Union[str, None] = ''
    // proposal_id: str = Query(min_length=1)
    // parsedData: Union[dict, None] = {}
    // update_status: str = Query(min_length=1)
    const newFaces = _.cloneDeep(faces)

    annotations.forEach((ann, index) => {
      if (ann.type === 'resume' && ann?.updateStatus) {
        toUpdateResume.push({
          ...ann,
          update_status: ann?.updateStatus,
          resume_data: ann?.parsedData ? JSON.stringify(ann.parsedData) : ''
        })
        newAnn[index].updateStatus = ''
        newAnn[index].showPredictions = ''
        newAnn[index].predictions = ''
        newAnn[index].del_screenshots = []
        if (ann?.updateStatus === 'delete') {
          newAnn[index] = null
        }
        if (newAnn[index]?.master?.updateStatus) {
          if (newAnn[index]?.master?.updateStatus === 'delete') {
            newAnn[index].master = null
          } else if (newAnn[index]?.master?.updateStatus === 'reprocess') {
            newAnn[index].master.removeMapping = []
            newAnn[index].master.removeMappingFace = []
          }
          newAnn[index].master.updateStatus = ''
        }
      } else if (ann.type === 'project' && ann?.updateStatus) {
        const project_data = {
          domain_id: ann.domain_id,
          id: ann.id,
          page_number: ann.page_number,
          proposal_id: ann.proposal_id,
          parsedData: ann.parsedData,
          update_status: ann?.updateStatus
        }
        toUpdateProject.push(project_data)
        newAnn[index].updateStatus = ''
      } else {
        if (ann.updateStatus === 'edit') {
          let { page_dimensions, tags = [], sectionText, titleText } = ann || {}
          if (!page_dimensions) {
            page_dimensions = detectedPageDimensions
          }
          const {
            title = [],
            section = [],
            id,
            pageIndex,
            screenshots,
            hidden_from_search = false
          } = ann
          const title_bound = []
          const content_bound = []
          title.forEach((t) => {
            const { x, y, width, height } = t
            const { org_height, org_width } = t
            const left = toFloat(x * (org_width / 100))
            const top = toFloat((100 - y) * (org_height / 100))
            const right = toFloat(left - width * (org_width / 100))
            const bottom = toFloat(top - height * (org_height / 100))
            const tempObj = {
              pageIndex: t.pageIndex,
              text: t.text,
              bounds: [left, bottom, right, top]
            }
            title_bound.push(tempObj)
            if (!page_dimensions?.[pageIndex]) {
              page_dimensions = {
                ...page_dimensions,
                [pageIndex]: { width: org_width, height: org_height }
              }
            }
          })
          section.forEach((s) => {
            const { x, y, width, height } = s
            const { org_height, org_width } = s
            const left = toFloat(x * (org_width / 100))
            const top = toFloat((100 - y) * (org_height / 100))
            const right = toFloat(left - width * (org_width / 100))
            const bottom = toFloat(top - height * (org_height / 100))
            const tempObj = {
              pageIndex: s.pageIndex,
              text: s.text,
              bounds: [left, bottom, right, top]
            }
            content_bound.push(tempObj)
            if (!page_dimensions?.[pageIndex]) {
              page_dimensions = {
                ...page_dimensions,
                [pageIndex]: { width: org_width, height: org_height }
              }
            }
          })
          if (
            titleText?.trim() ||
            sectionText?.trim() ||
            ann?.type === 'image'
          ) {
            let oldScreenshots = backupAnnotations[index]?.screenshots
            oldScreenshots = oldScreenshots || []
            const delScreenshots = toDeleteScreenshots(
              oldScreenshots,
              screenshots
            )
            let obj = {
              id,
              title: trimGeneratedHtml(titleText),
              content: trimGeneratedHtml(sectionText),
              keywords: tags.toString(),
              page_number: pageIndex,
              title_bound,
              content_bound,
              page_dimensions,
              update_status: 'edit',
              screenshots,
              del_screenshots: delScreenshots,
              type: ann.type,
              hidden_from_search
            }
            if (ann?.type === 'image') {
              obj = {
                ...obj,
                caption: ann.caption,
                content: '',
                images: ann.images
              }
            }
            toUpdateData.push(obj)
            newAnn[index].updateStatus = ''
          } else {
            const { id, pageIndex, type } = ann
            const obj = {
              id,
              title: '',
              content: '',
              keywords: '',
              page_number: Number.isInteger(pageIndex) ? pageIndex : 0,
              title_bound: [],
              content_bound: [],
              screenshots: [],
              del_screenshots: [],
              page_dimensions: {},
              update_status: 'delete',
              type
            }
            newAnn[index] = null
            toUpdateData.push(obj)
          }
        } else if (ann.updateStatus === 'delete') {
          const { id, pageIndex, type } = ann
          const obj = {
            id,
            title: '',
            content: '',
            keywords: '',
            page_number: Number.isInteger(pageIndex) ? pageIndex : 0,
            title_bound: [],
            content_bound: [],
            page_dimensions: {},
            update_status: 'delete',
            type
          }
          newAnn[index] = null
          toUpdateData.push(obj)
        }
      }
    })

    Object.keys(newFaces).forEach((key) => {
      newFaces[key].forEach((face, index) => {
        if (face?.updateStatus === 'edit') {
          toUpdateFace.push({ ...face, update_status: 'edit' })
          newFaces[key][index].updateStatus = ''
        }
      })
    })

    const req = {
      section_data: toUpdateData,
      resume_data: toUpdateResume,
      face_data: toUpdateFace,
      project_data: toUpdateProject
    }

    newAnn = newAnn.filter((n) => n)
    const response = await saveProposalBounding(domain_id, proposalId, req)
    if (response.status === 200) {
      const {
        screenshots = {},
        profile_picture_location = {},
        res_faces = [],
        project_images = {}
      } = response.data

      for (const [id, value] of Object.entries(screenshots)) {
        const result = newAnn.findIndex((item) => item.id === id)
        if (result !== -1) {
          value.forEach((item, index) => {
            newAnn[result].screenshots[index] = {
              ...newAnn[result].screenshots[index],
              delete_url: item.src
            }
          })
        }
      }
      for (const [id, value] of Object.entries(profile_picture_location)) {
        const result = newAnn.findIndex((item) => item.id === id)
        if (result !== -1) {
          newAnn[result].profile_picture_location = {
            ...newAnn[result]?.profile_picture_location,
            base64: newAnn[result]?.profile_picture_location?.src,
            ...value
          }
        }
      }
      res_faces.forEach((item) => {
        newFaces[item?.page_number] = [...newFaces[item.page_number], item]
      })

      setFaces(newFaces)
      let msg = 'Saved Changes!'
      if (!_.isEmpty(emptyUpdate)) {
        msg = `${msg} Entries without text content were deleted.`
      }
      dispatch(
        setToasterAlert({
          message: msg,
          show: true,
          type: 'success'
        })
      )
      setBackupAnnotations(newAnn)
      setIsSaving(false)
      setIsReadOnly(false)
      setAnnotations(newAnn)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Error in Saving',
          show: true,
          type: 'error'
        })
      )
      fetchData()
    }
  }

  const handleSelectionAction = (e, el, val) => {
    switch (e) {
      case 'generate':
        handleGenerateText(el)
        break
      case 'visble':
        handleSubVisiblity(el, val)
        break
      case 'delete':
        handleSubEntryDelete(el)
        break
      case 'generateBulletList':
        handleGenerateText(el, true, 1)
        break
      case 'generateListNumber':
        handleGenerateText(el, true, 2)
        break
      case 'generateList':
        handleGenerateText(el, true, 3)
        break
      case 'convertTemp':
        handleConvertTemp(el)
        break
      case 'deleteTextBox': {
        const index = _.findIndex(textBBox, { id: selectedTextBBox.id })
        const newAnn = _.cloneDeep(textBBox)
        newAnn.splice(index, 1)
        setTextBBox(newAnn)
        break
      }
      case 'deleteTextBoxIndex':
        {
          const newAnn = _.cloneDeep(textBBox)
          newAnn.splice(el, 1)
          setTextBBox(newAnn)
        }
        break
      default:
        break
    }
  }

  const handleConvertTemp = (el) => {
    const { parentIndex } = el
    const tempSection = annotations[parentIndex]
    const ann = _.cloneDeep(annotations)
    const newId = uuid()
    const randomColor = getRandomColor()
    const newParentIndex = ann.length
    const { section = [], pageIndex, sectionText } = tempSection
    const newSection = []
    section.forEach((s) => {
      newSection.push({
        ...s,
        fill: randomColor,
        parentType: 'section',
        parentIndex: newParentIndex
      })
    })
    const obj = {
      id: newId,
      color: '#' + randomColor,
      pageIndex,
      index: newParentIndex,
      tags: [],
      title: [],
      section: newSection,
      visible: true,
      screenshots: [],
      sectionText: `<html><p>${sectionText}</p></html>`,
      titleText: '',
      type: 'section',
      updateStatus: 'edit'
    }
    ann.push(obj)
    ann[parentIndex].updateStatus = 'delete'
    setSelectedEntry('')
    setAnnotations(ann)
    setSelectedEntry(obj)
  }

  const handleMerge = () => {
    if (mergeChecked.length > 1) {
      const ann = _.cloneDeep(annotations)
      const primaryIndex = _.findIndex(annotations, { id: mergeChecked[0] })
      let secondarySections = []
      ann[primaryIndex].updateStatus = 'edit'
      for (let i = 1; i < mergeChecked.length; i++) {
        const secondaryIndex = _.findIndex(annotations, { id: mergeChecked[i] })
        console.log(ann[secondaryIndex])
        ann[secondaryIndex].updateStatus = 'delete'
        ann[primaryIndex].page_dimensions = {
          ...ann[primaryIndex].page_dimensions,
          ...ann[secondaryIndex].page_dimensions
        }
        secondarySections = [
          ...secondarySections,
          ...annotations[secondaryIndex]?.title,
          ...annotations[secondaryIndex]?.section
        ]
      }
      ann[primaryIndex].section = [
        ...ann[primaryIndex]?.section,
        ...secondarySections
      ]

      ann.forEach((ent, i) => {
        if (ann[i].updateStatus !== 'delete') {
          const primaryColor = ann[i].color
          ann[i]?.title?.forEach((tit, subIndex) => {
            ann[i].title[subIndex].parentIndex = ann[i].index
            ann[i].title[subIndex].index = subIndex
            ann[i].title[subIndex].fill = primaryColor
          })
          ann[i]?.section?.forEach((sec, subIndex) => {
            if (sec.type === 'title') {
              let { text = '' } = sec
              text = `<b>${text}</b>`
              ann[i].section[subIndex].text = text
            }
            ann[i].section[subIndex].type = 'section'
            ann[i].section[subIndex].parentIndex = ann[i].index
            ann[i].section[subIndex].index = subIndex
            ann[i].section[subIndex].fill = primaryColor
          })
        }
      })
      reConstructTextData(ann[primaryIndex], ann)
    }
    handleClearMerge()
  }

  const handleClearMerge = () => {
    setMergeChecked([])
    setMerge(false)
  }

  const handleReset = () => {
    setVimMode(false)
    setCanvasState(true)
    setIsSaving(false)
    clearState()
    setState(backupAnnotations)
    setPrevStates([[]])
    setFutureStates([])
    seteditorUpdating(false)
  }

  const handleMove = (el, toParent) => {
    if (el.parentIndex !== toParent.index) {
      const ann = _.clone(annotations)
      let element = annotations[el.parentIndex][el.type][el.index]
      element = {
        ...element,
        parentIndex: toParent.index,
        index: toParent[el.type]?.length,
        fill: toParent.color
      }
      ann[el.parentIndex][el.type].splice(el.index, 1)
      ann[el.parentIndex][el.type].forEach((_, i) => {
        ann[el.parentIndex][el.type][i].index = i
      })
      ann[toParent.index][el.type] = [...ann[toParent.index][el.type], element]
      if (ann[toParent.index].pageIndex === 'new') {
        ann[toParent.index].pageIndex = el.pageIndex
      }
      ann[toParent.index].updateStatus = 'edit'
      ann[el.parentIndex].updateStatus = 'edit'
      setAnnotations(ann)
    }
  }

  const handleSubMove = (el, toType, toIndex) => {
    if (!_.isEmpty(el) && toType && _.isNumber(toIndex)) {
      const { type: fromType, index: fromIndex } = el
      const entryIndex = _.findIndex(annotations, { id: selectedEntry.id })
      const ann = _.clone(annotations)
      const fromElement = ann[entryIndex][fromType][fromIndex]
      fromElement.type = toType
      ann[entryIndex][fromType].splice(fromIndex, 1)
      ann[entryIndex][toType].splice(toIndex, 0, fromElement)
      ann[entryIndex][fromType].forEach((_, i) => {
        ann[entryIndex][fromType][i].index = i
      })
      if (fromType !== toType) {
        ann[entryIndex][toType].forEach((_, i) => {
          ann[entryIndex][toType][i].index = i
        })
      }
      ann[entryIndex].updateStatus = 'edit'
      setAnnotations(ann)
      setSelectedEntry({ ...ann[entryIndex] })
      if (selectedSubEntry) {
        const subEntryIndex = _.findIndex(
          ann[entryIndex][selectedSubEntry.type],
          { id: selectedSubEntry.id }
        )
        if (subEntryIndex > -1) {
          setSelectedSubEntry({
            ...ann[entryIndex][selectedSubEntry.type][subEntryIndex]
          })
        }
      }
    }
  }

  const handleBackButton = () => {
    setShowSectionEditor(false)
  }

  const handleSetTags = (e) => {
    const index = _.findIndex(annotations, { id: selectedEntry.id })
    const newAnn = _.cloneDeep(annotations)
    newAnn[index].tags = e
    newAnn[index].updateStatus = 'edit'
    setAnnotations(newAnn)
    // setSelectedEntry(newAnn[index])
  }

  const checkOverlay = (val1, val2) => {
    if (val2?.type !== 'image') {
      const {
        pageIndex: pageIndex1,
        width: width1,
        x: x1,
        y: y1,
        height: height1
      } = val1
      const {
        pageIndex: pageIndex2,
        width: width2,
        x: x2,
        y: y2,
        height: height2
      } = val2
      if (pageIndex1 !== pageIndex2) {
        return false
      }
      const intersectX = x1 < x2 + width2 && x1 + width1 > x2
      const intersectY = y1 < y2 + height2 && y1 + height1 > y2
      const intersect = intersectX && intersectY
      return intersect
    } else {
      return false
    }
  }

  const handleBBoxWithoutMerging = (value) => {
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    const ann = _.clone(annotations)
    try {
      ann.forEach((ent, i) => {
        if (primaryIndex !== i && ann[i].updateStatus !== 'delete') {
          const allSections = []
          let sectionDel = []
          let titlesDel = []
          ann[i].title?.forEach((tit, subIndex) => {
            if (checkOverlay(value, tit)) {
              let { text } = tit
              text = `<b>${text}</b>`
              allSections.push({ ...tit, type: 'section', text })
              titlesDel.push(subIndex)
            }
          })
          ann[i].section?.forEach((sec, subIndex) => {
            if (checkOverlay(value, sec)) {
              allSections.push({ ...sec, type: 'section' })
              sectionDel.push(subIndex)
            }
          })
          if (!_.isEmpty(allSections)) {
            sectionDel = _.orderBy(sectionDel, [], ['desc'])
            titlesDel = _.orderBy(titlesDel, [], ['desc'])
            ann[primaryIndex].section = [
              ...ann[primaryIndex]?.section,
              ...allSections
            ]
            ann[i].updateStatus = 'edit'
            ann[primaryIndex].updateStatus = 'edit'
            sectionDel.forEach((ind) => {
              ann[i].section.splice(ind, 1)
            })
            titlesDel.forEach((ind) => {
              ann[i].title.splice(ind, 1)
            })
            const text = constructTextData(ann[i], true)
            ann[i] = { ...ann[i], ...text }
          }
        }
      })
      ann.forEach((ent, i) => {
        if (ann[i].updateStatus !== 'delete') {
          const primaryColor = ann[i].color
          ann[i]?.title?.forEach((tit, subIndex) => {
            ann[i].title[subIndex].parentIndex = ann[i].index
            ann[i].title[subIndex].index = subIndex
            ann[i].title[subIndex].fill = primaryColor
          })
          ann[i]?.section?.forEach((sec, subIndex) => {
            ann[i].section[subIndex].parentIndex = ann[i].index
            ann[i].section[subIndex].index = subIndex
            ann[i].section[subIndex].fill = primaryColor
          })
        }
      })
      reConstructTextData(ann[primaryIndex], ann)
    } catch (e) {
      console.log(e)
    }
  }

  const getOverlapRectangle = (rectangles) => {
    if (!_.isEmpty(rectangles)) {
      let minX = rectangles[0].x
      let minY = rectangles[0].y
      let maxX = rectangles[0].x + rectangles[0].width
      let maxY = rectangles[0].y + rectangles[0].height
      let textConcat = ''
      for (let i = 1; i < rectangles.length; i++) {
        const rect = rectangles[i]
        const { text, type } = rect
        minX = Math.min(minX, rect.x)
        minY = Math.min(minY, rect.y)
        maxX = Math.max(maxX, rect.x + rect.width)
        maxY = Math.max(maxY, rect.y + rect.height)
        if (type === 'title') {
          textConcat = textConcat + `<p><b>${text}</b><br></p>`
        } else {
          textConcat = textConcat + `<p>${text}<br></p>`
        }
      }

      const overlapWidth = maxX - minX
      const overlapHeight = maxY - minY
      return {
        ...rectangles[0],
        x: minX,
        y: minY,
        width: overlapWidth,
        height: overlapHeight,
        text: textConcat,
        type: 'section'
      }
    }
    return null
  }

  const handleBBoxWithMerging = (value) => {
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    const ann = _.clone(annotations)
    const allSections = []
    ann?.forEach((ent, i) => {
      if (ann[i].updateStatus !== 'delete') {
        const sections = []
        const titles = []
        let sectionDel = []
        let titlesDel = []
        if (i !== primaryIndex) {
          ann[i]?.title?.forEach((tit, subIndex) => {
            if (checkOverlay(value, tit)) {
              titles.push(tit)
              titlesDel.push(subIndex)
              allSections.push(tit)
            }
          })
          ann[i]?.section?.forEach((sec, subIndex) => {
            if (checkOverlay(value, sec)) {
              sections.push(sec)
              sectionDel.push(subIndex)
              allSections.push(sec)
            }
          })
        }
        if (!_.isEmpty(sections) || !_.isEmpty(titles)) {
          sectionDel = _.orderBy(sectionDel, [], ['desc'])
          titlesDel = _.orderBy(titlesDel, [], ['desc'])
          ann[i].updateStatus = 'edit'
          ann[primaryIndex].updateStatus = 'edit'
          sectionDel?.forEach((ind) => {
            ann[i].section.splice(ind, 1)
          })
          titlesDel?.forEach((ind) => {
            ann[i].title.splice(ind, 1)
          })
          const text = constructTextData(ann[i], true)
          ann[i] = { ...ann[i], ...text }
        }
      }
    })

    const mergedObj = getOverlapRectangle(allSections)
    ann[primaryIndex].section = [...ann[primaryIndex].section, mergedObj]

    ann?.forEach((ent, i) => {
      if (ann[i].updateStatus !== 'delete') {
        const primaryColor = ann[i].color
        ann[i]?.title?.forEach((tit, subIndex) => {
          ann[i].title[subIndex].parentIndex = ann[i].index
          ann[i].title[subIndex].index = subIndex
          ann[i].title[subIndex].fill = primaryColor
        })
        ann[i]?.section?.forEach((sec, subIndex) => {
          ann[i].section[subIndex].parentIndex = ann[i].index
          ann[i].section[subIndex].index = subIndex
          ann[i].section[subIndex].fill = primaryColor
        })
      }
    })
    reConstructTextData(ann[primaryIndex], ann)
  }

  const handleMergeBBox = (value, fun) => {
    switch (fun) {
      case 'handleBBoxWithMerging':
        handleBBoxWithMerging(value)
        break
      case 'handleBBoxWithoutMerging':
        handleBBoxWithoutMerging(value)
        break
      default:
        break
    }
  }

  const addprofilePicture = (img, facePage, faceIndex) => {
    const selectedResumeIndex = _.findIndex(annotations, {
      id: selectedEntry.id
    })
    if (annotations[selectedResumeIndex].type === 'project') {
      const newResumes = _.cloneDeep(annotations)
      newResumes[selectedResumeIndex].parsedData.images.push(img)
      newResumes[selectedResumeIndex].updateStatus = 'edit'
      newResumes[selectedResumeIndex].showPredictions = true
      setSelectedEntry(newResumes[selectedResumeIndex])
      setAnnotations(newResumes)
    } else {
      const newFaces = _.cloneDeep(faces)
      const oldSrc = annotations[selectedResumeIndex]?.profile_picture_location
        ?.src
        ? annotations[selectedResumeIndex]?.profile_picture_location?.src
        : ''
      const oldFace = findMatchingFace(faces, oldSrc)
      const { key: oldFacePage, index: oldFaceIndex } = oldFace
      if (oldFaceIndex !== undefined && oldFacePage !== undefined) {
        newFaces[oldFacePage][oldFaceIndex].mapped_people_ids = newFaces[
          oldFacePage
        ][oldFaceIndex]?.mapped_people_ids?.filter(
          (id) => id !== annotations[selectedResumeIndex].id
        )
        newFaces[oldFacePage][oldFaceIndex].updateStatus = 'edit'
      }
      if (faceIndex === undefined && facePage === undefined) {
        if (img) {
          const { src } = img
          const result = findMatchingFace(faces, src)
          const { key, index } = result
          faceIndex = index
          facePage = key
        } else {
          const { src } =
            annotations[selectedResumeIndex]?.profile_picture_location
          const result = findMatchingFace(faces, src)
          const { key, index } = result
          faceIndex = index
          facePage = key
        }
      }
      if (faceIndex !== undefined && facePage !== undefined) {
        if (img) {
          newFaces[facePage][faceIndex].mapped_people_ids = [
            annotations[selectedResumeIndex].id
          ]
        } else {
          newFaces[facePage][faceIndex].mapped_people_ids = newFaces[facePage][
            faceIndex
          ]?.mapped_people_ids?.filter(
            (id) => id !== annotations[selectedResumeIndex].id
          )
        }
        newFaces[facePage][faceIndex].updateStatus = 'edit'
      }
      const newResumes = _.cloneDeep(annotations)
      if (newResumes[selectedResumeIndex]?.profile_picture_location) {
        newResumes[selectedResumeIndex].profile_picture_location = img
      } else {
        newResumes[selectedResumeIndex].profile_picture_location = img
      }
      newResumes[selectedResumeIndex].updateStatus = 'edit'
      newResumes[selectedResumeIndex].showPredictions = true
      setSelectedEntry(newResumes[selectedResumeIndex])
      setAnnotations(newResumes)
      setFaces(newFaces)
    }
  }

  const addScreenShot = (img, type) => {
    if (type === 'screenshot_profile') {
      addprofilePicture(img)
    } else {
      const ann = _.clone(annotations)
      const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
      if (ann[primaryIndex]?.screenshots) {
        ann[primaryIndex].screenshots = [...ann[primaryIndex].screenshots, img]
      } else {
        ann[primaryIndex].screenshots = [img]
      }
      ann[primaryIndex].updateStatus = 'edit'
      setSelectedEntry(ann[primaryIndex])
      setAnnotations(ann)
    }
  }

  const removeScreenShot = (idx) => {
    const ann = _.clone(annotations)
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    ann[primaryIndex].screenshots.splice(idx, 1)
    ann[primaryIndex].updateStatus = 'edit'
    setSelectedEntry(ann[primaryIndex])
    setAnnotations(ann)
  }

  const handleMode = (val) => {
    if (!canvasState || isReadOnly) {
      return
    }
    if (selectedEntry?.type === 'image' && val !== 'screenshot_image') {
      return
    }
    if (val === mode) {
      val = ''
    }
    setMode(val)
  }
  const handleCaptionChange = (e) => {
    const ann = _.clone(annotations)
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    ann[primaryIndex].caption = e.target.value
    ann[primaryIndex].updateStatus = 'edit'
    setSelectedEntry(ann[primaryIndex])
    setAnnotations(ann)
  }

  const handleScannedTextChange = (e) => {
    const ann = _.clone(annotations)
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    if (!ann[primaryIndex]?.section[0]) {
      ann[primaryIndex].section[0] = {}
    }
    ann[primaryIndex].section[0].text = e.target.value
    ann[primaryIndex].updateStatus = 'edit'
    setSelectedEntry(ann[primaryIndex])
    setAnnotations(ann)
  }

  const addImage = (img, bbox) => {
    const ann = _.clone(annotations)
    const { pageIndex } = bbox
    const primaryIndex = _.findIndex(annotations, { id: selectedEntry.id })
    ann[primaryIndex].images = img
    ann[primaryIndex].caption = ''
    ann[primaryIndex].pageIndex = pageIndex
    ann[primaryIndex].section = [
      {
        ...bbox,
        type: 'section'
      }
    ]
    ann[primaryIndex].updateStatus = 'edit'
    setSelectedEntry(ann[primaryIndex])
    setAnnotations(ann)
  }

  const convertBoundingBoxToAdobeFormat = (
    boundingBox,
    pageHeight,
    pageWidth
  ) => {
    const [Left, Top, Width, Height] = boundingBox
    const adobeLeft = Left * pageWidth
    const adobeBottom = (1 - (Top + Height)) * pageHeight
    const adobeRight = (Left + Width) * pageWidth
    const adobeTop = (1 - Top) * pageHeight
    return [adobeLeft, adobeBottom, adobeRight, adobeTop]
  }

  const getOverallBBox = (boundingBoxes) => {
    if (!boundingBoxes || boundingBoxes.length === 0) {
      return [0, 0, 0, 0]
    }

    let xMin = Infinity
    let yMin = Infinity
    let xMax = -Infinity
    let yMax = -Infinity

    for (const box of boundingBoxes) {
      xMin = Math.min(xMin, box[0])
      yMin = Math.min(yMin, box[1])
      xMax = Math.max(xMax, box[2])
      yMax = Math.max(yMax, box[3])
    }

    const overallBoundingBox = [xMin, yMin, xMax, yMax]

    return overallBoundingBox
  }

  const handleTextExtract = async () => {
    setIsReadOnly(true)
    const MAX_RETRIES = 3
    if (_.isNumber(pdfCurrentPage) && pdfCurrentPage > -1) {
      const page = pdfCurrentPage + 1
      const req = {
        page_number: page,
        domain_id,
        proposal_id: proposalId
      }
      const toastId = toast.info(
        <div style={{ display: 'flex' }}>
          {`Extracting text from page ${page}...`}&nbsp;
          <CircularProgress size={20} />
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          draggable: false
        }
      )
      const extract = async (req, retries = 0) => {
        const res = await getTextExtractPageData(req)
        if (res?.status === 200) {
          const { forms = [], tables = [], page_dims = {} } = res.data
          const { width, height } = page_dims || {}
          if (width && height) {
            const ann = _.clone(annotations)
            forms.forEach((form, i) => {
              const { data: textData, bounds: textractBounds } = form
              const overallBounds = getOverallBBox(textractBounds)
              const randomColor = getRandomColor()
              const color = '#' + randomColor
              const bounds = convertBoundingBoxToAdobeFormat(
                overallBounds,
                height,
                width
              )
              const left = bounds[0]
              const right = bounds[2]
              const top = bounds[3]
              const bottom = bounds[1]
              const refomated = {
                x: Math.abs(left / (width / 100)),
                y: Math.abs(top / (height / 100) - 100),
                width: Math.abs((right - left) / (width / 100)),
                height: Math.abs((top - bottom) / (height / 100)),
                id: uuid(),
                fill: color,
                opacity: '.2',
                type: 'section',
                pageIndex: page,
                visible: true,
                text: textData,
                org_height: height,
                org_width: width
              }
              const obj = {
                color,
                title: [],
                section: [refomated],
                index: ann.length,
                id: uuid(),
                pageIndex: page,
                visible: true,
                page_dimensions: {},
                screenshots: [],
                tags: [],
                titleText: '',
                sectionText: removeUnwantedHyphens(textData),
                type: 'section',
                updateStatus: 'edit'
              }
              ann.push(obj)
            })
            tables.forEach((table, i) => {
              const { data: textData, bounds: textractBounds } = table
              const randomColor = getRandomColor()
              const color = '#' + randomColor
              const bounds = convertBoundingBoxToAdobeFormat(
                textractBounds,
                height,
                width
              )
              const left = bounds[0]
              const right = bounds[2]
              const top = bounds[3]
              const bottom = bounds[1]
              const refomated = {
                x: Math.abs(left / (width / 100)),
                y: Math.abs(top / (height / 100) - 100),
                width: Math.abs((right - left) / (width / 100)),
                height: Math.abs((top - bottom) / (height / 100)),
                id: uuid(),
                fill: color,
                opacity: '.2',
                type: 'section',
                pageIndex: page,
                visible: true,
                text: textData,
                org_height: height,
                org_width: width
              }
              const obj = {
                color,
                title: [],
                section: [refomated],
                id: uuid(),
                index: ann.length,
                pageIndex: page,
                visible: true,
                page_dimensions: {},
                screenshots: [],
                tags: [],
                titleText: '',
                sectionText: removeUnwantedHyphens(textData),
                type: 'section',
                updateStatus: 'edit'
              }
              ann.push(obj)
            })

            const sortedEntries = _.sortBy(ann, ['pageIndex'])
            sortedEntries.forEach((ent, index) => {
              sortedEntries[index].index = index
              sortedEntries[index].title.forEach((tit, subIndex) => {
                sortedEntries[index].title[subIndex].parentIndex = index
                sortedEntries[index].title[subIndex].index = subIndex
                sortedEntries[index].title[subIndex].parentType =
                  sortedEntries[index].type
              })
              sortedEntries[index].section.forEach((sec, subIndex) => {
                sortedEntries[index].section[subIndex].parentIndex = index
                sortedEntries[index].section[subIndex].index = subIndex
                sortedEntries[index].section[subIndex].parentType =
                  sortedEntries[index].type
              })
              if (!_.isEmpty(sortedEntries)) {
                setState(sortedEntries)
                setBackupAnnotations(sortedEntries)
                setPrevStates([...prevStates, sortedEntries])
              }
              setIsReadOnly(false)
            })
          } else {
            dispatch(
              setToasterAlert({
                message: `Failed to extract text from height and width from page number ${page}`,
                show: true,
                type: 'error'
              })
            )
          }
          toast.dismiss(toastId)
          setIsReadOnly(false)
        } else if (res.status === 201 && retries < MAX_RETRIES) {
          const delay = (retries + 1) * 30 * 1000
          setTimeout(() => extract(req, retries + 1), delay)
        } else {
          dispatch(
            setToasterAlert({
              message: `Failed to extract text from page number ${page}${
                res?.response?.data?.message
                  ? ': ' + res.response.data.message
                  : ''
              }`,
              show: true,
              type: 'error'
            })
          )
          setIsReadOnly(false)
          toast.dismiss(toastId)
        }
      }
      extract(req)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Failed to extract page number',
          show: true,
          type: 'error'
        })
      )
      setIsReadOnly(false)
    }
  }

  const saveSync = isSaving || editorUpdating
  const saveText = editorUpdating ? 'Updating' : 'Saving'
  const saveQueue = _.filter(annotations, function (o) {
    return o?.updateStatus
  })
  const disableSave = isSaving || _.isEmpty(saveQueue) || isReadOnly || saveSync
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isReadOnly) {
        if (
          event.key === 'Delete' &&
          selectedSubEntry !== null &&
          isFocusViewer
        ) {
          const index = _.findIndex(annotations, { id: selectedEntry.id })
          const newAnn = _.cloneDeep(annotations)
          const indexSub = _.findIndex(newAnn[index][selectedSubEntry.type], {
            id: selectedSubEntry.id
          })
          newAnn[index][selectedSubEntry.type].splice(indexSub, 1)
          newAnn[index].updateStatus = 'edit'
          setAnnotations(newAnn)
          setSelectedEntry(newAnn[index])
        } else if (event.key === 'Escape' && mode) {
          setMode('')
        } else if (event.ctrlKey && event.key.toLowerCase() === 's') {
          event.preventDefault()
          if (!disableSave) {
            handleSave()
          }
        } else if (!isFocusEditor) {
          if (event.shiftKey && event.key.toLowerCase() === 'i') {
            event.preventDefault()
            setElementShown(elementShown === 'image' ? 'section' : 'image')
          }
          if (event.shiftKey && event.key.toLowerCase() === 'n') {
            event.preventDefault()
            handleCreateEntry()
          } else if (event.shiftKey && event.key.toLowerCase() === 't') {
            event.preventDefault()
            if (
              !_.isEmpty(selectedEntry) &&
              selectedEntry?.type === 'section'
            ) {
              handleMode('title')
            }
          } else if (event.shiftKey && event.key.toLowerCase() === 's') {
            event.preventDefault()
            if (
              !_.isEmpty(selectedEntry) &&
              selectedEntry?.type === 'section'
            ) {
              handleMode('section')
            }
          } else if (event.shiftKey && event.key.toLowerCase() === 'm') {
            event.preventDefault()
            if (
              !_.isEmpty(selectedEntry) &&
              selectedEntry?.type === 'section'
            ) {
              handleMode('multiselect')
            }
          } else if (event.shiftKey && event.key.toLowerCase() === 'd') {
            event.preventDefault()
            if (
              !_.isEmpty(selectedEntry) &&
              ['resume', 'section'].includes(selectedEntry?.type)
            ) {
              handleMode('screenshot')
            }
          } else if (event.shiftKey && event.key.toLowerCase() === 'v') {
            event.preventDefault()
            if (
              !_.isEmpty(selectedEntry) &&
              selectedEntry?.type === 'section'
            ) {
              setVimMode(!vimMode)
            }
          } else if (event.shiftKey && event.key.toLowerCase() === 'r') {
            event.preventDefault()
            if (elementShown === 'section') {
              createNewResume()
            }
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    selectedEntry,
    isReadOnly,
    mode,
    elementShown,
    disableSave,
    vimMode,
    annotations,
    selectedSubEntry,
    isFocusViewer,
    isFocusEditor
  ])

  const arrayToOptions = (array) => {
    return array.map((item) => ({
      value: item,
      label: _.startCase(item)
    }))
  }

  const createNewResume = () => {
    const type = 'resume'
    const template = _.cloneDeep(newResumeTemplate)
    const newResume = {
      id: uuid(),
      domain_id: created_for_domain,
      proposal_id: proposalId,
      page_number: pdfCurrentPage + 1,
      resume_data: JSON.stringify(template),
      parsedData: template,
      profile_picture_location: {},
      screenshots: [],
      del_screenshots: [],
      updateStatus: 'edit',
      type,
      pageIndex: pdfCurrentPage + 1,
      index: annotations.length
    }
    const newAnn = [...annotations, newResume]
    const sortedEntries = _.sortBy(newAnn, ['pageIndex'])
    setAnnotations(sortedEntries)
    setSelectedEntry(newResume)
  }

  const createNewProject = () => {
    const newProjectTemplate = {
      project_name: '',
      project_location: '',
      project_description: '',
      client_company: '',
      client_contact: '',
      client_location: '',
      project_timeline: {
        start_date: '',
        end_date: ''
      },
      project_team_members: [
        {
          name: '',
          role: '',
          responsibilities: ''
        }
      ],
      total_project_cost: '',
      square_footage: '',
      images: []
    }
    const type = 'project'
    const template = _.cloneDeep(newProjectTemplate)
    const newProject = {
      id: uuid(),
      domain_id: created_for_domain,
      proposal_id: proposalId,
      page_number: pdfCurrentPage + 1,
      project_data: JSON.stringify(template),
      parsedData: template,
      screenshots: [],
      updateStatus: 'edit',
      type,
      pageIndex: pdfCurrentPage + 1,
      index: annotations.length
    }
    const newAnn = [...annotations, newProject]
    const sortedEntries = _.sortBy(newAnn, ['pageIndex'])
    setAnnotations(sortedEntries)
    setSelectedEntry(newProject)
  }

  const checkResumeHasData = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key !== 'content_id') {
        if (Array.isArray(value) && value.length > 0) return true // Check for non-empty array
        if (typeof value === 'string' && value.trim() !== '') return true // Check for non-empty string
        if (
          typeof value === 'object' &&
          value !== null &&
          Object.keys(value).length > 0
        )
          return true // Check for non-empty object
        if (typeof value === 'number' && !isNaN(value)) return true // Check for number
      }
    }
    return false
  }

  const isValidInteger = (str) => {
    const numberValue = _.toNumber(str)
    return _.isInteger(numberValue)
  }

  const handleResumeReprocess = async () => {
    setIsSaving(true)
    let pageToAdd = pdfCurrentPage + 1
    let payload = {
      domain_id: created_for_domain,
      proposal_id: proposalId
    }
    if (resumeReprocessMode === 'pagerange') {
      if (isValidInteger(pageValue.from) && isValidInteger(pageValue.to)) {
        pageToAdd = pageValue.from
        let reprocess_type = 'page_range'
        if (pageValue.from === pageValue.to) {
          reprocess_type = 'single_page'
        }
        payload = {
          ...payload,
          page_number: pageValue.from,
          start_page_number: pageValue.from,
          end_page_number: pageValue.to,
          reprocess_type
        }
      } else {
        toast.error('Invalid page range')
        return
      }
    } else {
      pageToAdd = textBBox[0]?.pageIndex
      let textExtracted = ''
      textBBox.forEach((bbox) => {
        textExtracted = textExtracted + bbox.text + '\n'
      })
      payload = {
        ...payload,
        page_number: pageToAdd,
        reprocess_type: 'rawtext',
        raw_text: textExtracted
      }
    }
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Extracting Data...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )
    const data = {
      forwardPayload: payload,
      requestType: 'resumeExtract'
    }
    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    let completeText = ''
    const response = await fetch(`${url}`, { ...signedUrl })
    if (response.status === 200 && response.body) {
      const reader = response.body.getReader()
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        const text = new TextDecoder().decode(value)
        completeText = completeText + text
      }
      completeText = completeText.replace(/__END_OF_CHAT__/g, '')
      toast.dismiss(toastId)
      setResumeReprocessMode('')
      setResumeReprocess(false)
      setMode('')
      setPageValue({
        from: '',
        to: ''
      })
      setTextBBox([])
      setSelectedTextBBox('')
      try {
        const resume_data = completeText
        const template = JSON.parse(resume_data)
        if (checkResumeHasData(template)) {
          const newResume = {
            id: uuid(),
            domain_id: created_for_domain,
            proposal_id: proposalId,
            page_number: pageToAdd,
            resume_data,
            parsedData: template,
            profile_picture_location: {},
            screenshots: [],
            del_screenshots: [],
            updateStatus: 'edit',
            type: 'resume',
            pageIndex: pageToAdd,
            index: annotations.length
          }
          const newAnn = [...annotations, newResume]
          const sortedEntries = _.sortBy(newAnn, ['pageIndex'])
          setAnnotations(sortedEntries)
          setSelectedEntry(newResume)
        } else {
          toast.error('No resume data found. Extraction Failed.')
          toast.dismiss(toastId)
        }
      } catch (e) {
        console.log(e)
        toast.error('Something went wrong. Extraction Failed.')
        toast.dismiss(toastId)
      }
    } else {
      toast.error('Something went wrong. Extraction Failed.')
      toast.dismiss(toastId)
    }
    setIsSaving(false)
  }

  const handleProjectReprocess = async () => {
    setIsSaving(true)
    let pageToAdd = pdfCurrentPage + 1
    let payload = {
      domain_id: created_for_domain,
      proposal_id: proposalId
    }
    let data1 = {}
    if (projectReprocessMode === 'pagerange') {
      if (isValidInteger(pageValue.from) && isValidInteger(pageValue.to)) {
        pageToAdd = pageValue.from
        let reprocess_type = 'page_range'
        if (pageValue.from === pageValue.to) {
          reprocess_type = 'single_page'
        }
        payload = {
          ...payload,
          page_number: pageValue.from,
          start_page_number: pageValue.from,
          end_page_number: pageValue.to,
          reprocess_type
        }
      } else {
        toast.error('Invalid page range')
        return
      }
    } else {
      pageToAdd = textBBox[0]?.pageIndex
      let textExtracted = ''
      textBBox.forEach((bbox) => {
        textExtracted = textExtracted + bbox.text + '\n'
      })
      payload = {
        ...payload,
        page_number: pageToAdd,
        reprocess_type: 'rawtext',
        raw_text: textExtracted
      }
      data1 = {
        domain_id: created_for_domain,
        proposal_id: proposalId,
        reprocess_type: 'rawtext',
        raw_text: textExtracted,
        page_number: pageToAdd,
        start_page_number: 0,
        end_page_number: 0
      }
    }
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Extracting Data...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )
    const data = {
      forwardPayload: payload,
      requestType: 'projectExtract'
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    let completeText = ''
    const response = await fetch(`${url}`, { ...signedUrl })
    if (response.status === 200 && response.body) {
      const reader = response.body.getReader()
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        const text = new TextDecoder().decode(value)
        completeText = completeText + text
      }
      completeText = completeText.replace(/__END_OF_CHAT__/g, '')

      console.log('success')
      // project_data = {
      //   ...project_data,
      //   "images":[]
      // }

      toast.dismiss(toastId)
      setProjectReprocessMode('')
      setProjectReprocess(false)
      setMode('')
      setPageValue({
        from: '',
        to: ''
      })
      setTextBBox([])
      setSelectedTextBBox('')
      try {
        // const project_data = {}
        const project_data = completeText
        let template = {}
        try {
          template = JSON.parse(project_data)
        } catch (e) {
          template = {}
        }
        template = {
          ...template,
          images: []
        }
        if (checkResumeHasData(project_data)) {
          const newProject = {
            id: uuid(),
            domain_id: created_for_domain,
            proposal_id: proposalId,
            page_number: pageToAdd,
            project_data,
            parsedData: template,
            screenshots: [],
            updateStatus: 'edit',
            type: 'project',
            pageIndex: pageToAdd,
            index: annotations.length
          }
          const newAnn = [...annotations, newProject]
          const sortedEntries = _.sortBy(newAnn, ['pageIndex'])
          setAnnotations(sortedEntries)
          setSelectedEntry(newProject)
        } else {
          toast.error('No project data found. Extraction Failed.')
          toast.dismiss(toastId)
        }
      } catch (e) {
        console.log(e)
        toast.error('Something went wrong. Extraction Failed.')
        toast.dismiss(toastId)
      }
    } else {
      toast.error('Something went wrong. Extraction Failed.')
      toast.dismiss(toastId)
    }
    setIsSaving(false)
  }

  const handleResumeExtract = () => {
    setResumeReprocess(true)
  }

  const handleProjectExtract = () => {
    setProjectReprocess(true)
  }

  const handleUpdatePageData = async (val) => {
    setIsReadOnly(true)
    const newType = val.value
    const newPageData = _.cloneDeep(pageData)
    const index = pdfCurrentPage + 1
    let req = {}
    if (newPageData[index]) {
      newPageData[index].content_type = newType
      newPageData[index].content_sub_type = ''

      req = {
        id: newPageData[index].id,
        content_type: newType,
        content_sub_type: '',
        page_number: index,
        proposal_id: proposalId
      }
    } else {
      newPageData[index] = {
        id: uuid(),
        content_type: newType,
        content_sub_type: '',
        page_number: index
      }
      req = {
        id: newPageData[index].id,
        content_type: newType,
        content_sub_type: '',
        page_number: index,
        proposal_id: proposalId
      }
    }
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Saving page type...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )
    const res = await savePageData(req)
    toast.dismiss(toastId)
    if (res?.status === 200) {
      setPageData(newPageData)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Failed to update page data',
          show: true,
          type: 'error'
        })
      )
    }
    setIsReadOnly(false)
  }

  const handleUpdatePageDataSubType = async (val) => {
    setIsReadOnly(true)
    const newType = val.value
    const newPageData = _.cloneDeep(pageData)
    const index = pdfCurrentPage + 1
    let req = {}
    newPageData[index].content_sub_type = newType
    req = {
      id: newPageData[index].id,
      content_sub_type: newType,
      page_number: index,
      proposal_id: proposalId
    }
    const toastId = toast.info(
      <div style={{ display: 'flex' }}>
        {'Saving page sub type...'}&nbsp;
        <CircularProgress size={20} />
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false
      }
    )
    const res = await savePageData(req)
    toast.dismiss(toastId)
    if (res?.status === 200) {
      setPageData(newPageData)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Failed to update page data',
          show: true,
          type: 'error'
        })
      )
    }
    setIsReadOnly(false)
  }

  const removeMasterMapping = (index) => {
    if (
      window.confirm(
        'Removing the mapping will reprocess master resume and the changes to the master resume will be lost. Are you sure you want to continue?'
      )
    ) {
      const newAnn = _.clone(annotations)
      const selectedResumeIndex = _.findIndex(newAnn, { id: selectedEntry.id })
      const removedValue = newAnn[selectedResumeIndex].master.people_data[index]
      newAnn[selectedResumeIndex].master.people_data.splice(index, 1)
      newAnn[selectedResumeIndex].master.updateStatus = 'reprocess'
      const existingRemoveMappingFace = Array.isArray(
        newAnn[selectedResumeIndex]?.master?.removeMappingFace
      )
        ? newAnn[selectedResumeIndex]?.master?.removeMappingFace
        : []
      const existingRemoveMapping = Array.isArray(
        newAnn[selectedResumeIndex]?.master?.removeMapping
      )
        ? newAnn[selectedResumeIndex]?.master?.removeMapping
        : []
      newAnn[selectedResumeIndex].master.removeMapping = [
        ...existingRemoveMapping,
        removedValue.id
      ]
      newAnn[selectedResumeIndex].master.removeMappingFace = [
        ...existingRemoveMappingFace,
        removedValue?.face_id
      ]
      newAnn[selectedResumeIndex].updateStatus = 'edit'
      setSelectedEntry(newAnn[selectedResumeIndex])
      setAnnotations(newAnn)
    }
  }

  const findMatchingFace = (data, targetUrl) => {
    let result = {}
    if (targetUrl) {
      for (const key in data) {
        const innerArray = data[key]
        for (let i = 0; i < innerArray.length; i++) {
          if (innerArray[i].image_url === targetUrl) {
            result = { key, index: i }
            return result
          }
        }
      }
    }
    return result
  }

  const handleResumeVerify = async (index, isMaster) => {
    const newResumes = _.clone(annotations)
    if (isMaster) {
      newResumes[index].master.verified = !newResumes[index].master.verified
      newResumes[index].master.updateStatus = 'edit'
      newResumes[index].updateStatus = 'edit'
    } else {
      newResumes[index].verified = !newResumes[index].verified
      newResumes[index].updateStatus = 'edit'
    }
    setAnnotations(newResumes)
  }

  const currentPageType = pageData?.[pdfCurrentPage + 1]?.content_type
  const currentPageSubType = pageData?.[pdfCurrentPage + 1]?.content_sub_type
  const currentPageTypeOption = {
    value: currentPageType,
    label: _.startCase(currentPageType)
  }
  const currentPageSubTypeOption = {
    value: currentPageSubType,
    label: _.startCase(currentPageSubType)
  }

  const pageTypeOptions = arrayToOptions(PAGE_TYPE_KEYS)
  const pageSubTypeOptions = arrayToOptions(PAGE_SUBTYPE_KEYS)

  const handleSetElementShown = (val) => {
    setLoading(true)
    setElementShown(val)
  }

  const ValueContainer = ({ children, ...props }) => {
    const { selectProps } = props
    const { value, menuIsOpen } = selectProps || {}
    const { label } = value || {}
    return !menuIsOpen ? (
      <Tooltip title={label} arrow>
        <Box {...props}>
          <selectComponents.ValueContainer {...props}>
            {children}
          </selectComponents.ValueContainer>
        </Box>
      </Tooltip>
    ) : (
      <selectComponents.ValueContainer {...props}>
        {children}
      </selectComponents.ValueContainer>
    )
  }

  const PageOptionSelect = (props) => {
    return (
      <Select
        styles={{
          control: (base, state) => ({
            ...base,
            background: '#EEEEEE',
            border: 'none',
            width: 'fit-content',
            cursor: 'pointer',
            margin: '0px 2px'
          }),
          menu: (provided, state) => ({
            ...provided,
            background: '#EEEEEE'
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: 0,
            minWidth: '100px'
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightgrey' : 'white'
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: 'black'
          }),
          placeholder: (provided, state) => ({
            ...provided,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word'
          })
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ValueContainer
        }}
        {...props}
      />
    )
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Loader loading={loading} caption={'opening your pdf'} />
      </Grid>
    )
  }
  return (
    <Container>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
      <SectionFixed className={borderBot}>
        <Box
          className={titleContainer}
          sx={{
            opacity: isScreenShotState ? '0.2' : '1'
          }}
        >
          {isSuperUser && (
            <Tooltip title={'Switch to PDF View'}>
              <IconButton onClick={() => handleBackButton()}>
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box className={pdfTitle}>{name}</Box>
          {onClose ? (
            <Box className={classes.closeIconWrapper}>
              <Tooltip title="Close">
                <IconButton disableRipple onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </SectionFixed>
      <Section overFlow>
        <div
          ref={ref}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex'
          }}
        >
          <div
            style={{
              width: toolbarWidth - 1 + 'px',
              borderRight: '1px solid #EEEEEE',
              opacity: isScreenShotState ? '0.2' : '1',
              zIndex: 0
            }}
          >
            <Toolbar
              handleMode={handleMode}
              handleDeleteEntry={handleDeleteEntry}
              selectedEntry={selectedEntry}
              setSelectedEntry={setSelectedEntry}
              selectedSubEntry={selectedSubEntry}
              setSelectedSubEntry={setSelectedSubEntry}
              createEntry={handleCreateEntry}
              setMode={setMode}
              mode={mode}
              annotations={annotations}
              updateEntryColor={updateEntryColor}
              handleVisibility={handleVisibility}
              handleSubEntryDelete={handleSubEntryDelete}
              handleSubVisiblity={handleSubVisiblity}
              handleGenerateText={handleGenerateText}
              canvasToggle={canvasToggle}
              canvasState={canvasState}
              setGoToPage={setGoToPage}
              handleSave={handleSave}
              isReadOnly={isReadOnly}
              setIsReadOnly={setIsReadOnly}
              toggleReadOnly={toggleReadOnly}
              merge={merge}
              setMerge={setMerge}
              mergeChecked={mergeChecked}
              setMergeChecked={setMergeChecked}
              handleMerge={handleMerge}
              handleClearMerge={handleClearMerge}
              isSaving={isSaving}
              editorUpdating={editorUpdating}
              handleReset={handleReset}
              handleMove={handleMove}
              handleSubMove={handleSubMove}
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              history={history}
              disableRedo={disableRedo}
              disableUndo={disableUndo}
              reConstructTextData={reConstructTextData}
              removeScreenShot={removeScreenShot}
              s3Obj={s3Obj}
              saveSync={saveSync}
              saveText={saveText}
              disableSave={disableSave}
              vimMode={vimMode}
              setVimMode={setVimMode}
              dataPage={dataPage}
              setDataPage={setDataPage}
              dataPageMeta={dataPageMeta}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              elementShown={elementShown}
              setElementShown={handleSetElementShown}
              handleTextExtract={handleTextExtract}
              resumeEditorState={resumeEditorState}
              renderResumeReprocessMenu={renderResumeReprocessMenu}
              renderProjectReprocessMenu={renderProjectReprocessMenu}
              createNewResume={createNewResume}
              createNewProject={createNewProject}
              handleResumeExtract={handleResumeExtract}
              handleProjectExtract={handleProjectExtract}
              handleResumeVerify={handleResumeVerify}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              removeMasterMapping={removeMasterMapping}
              handleRenderPrediction={handleRenderPrediction}
              domain_id={domain_id}
              handleHidefromSearch={handleHidefromSearch}
            />
          </div>
          <Resizable
            width={editorWidth}
            axis="x"
            onResize={handleOnResize}
            resizeHandles={['e', 'w']}
          >
            <div
              style={{
                width: pdfWidth - 1 + 'px',
                borderRight: '1px solid #EEEEEE'
              }}
            >
              <Box
                sx={{ height: '100%', overflow: 'auto' }}
                onMouseEnter={() => setIsFocusViewer(true)}
                onMouseLeave={() => setIsFocusViewer(false)}
              >
                <PDFViewer
                  selectedSubEntry={selectedSubEntry}
                  setSelectedSubEntry={setSelectedSubEntry}
                  annotations={annotations}
                  setAnnotations={setAnnotations}
                  selectedEntry={selectedEntry}
                  mode={mode}
                  setSelectedEntry={setSelectedEntry}
                  canvasState={canvasState}
                  setMode={setMode}
                  handleMergeBBox={handleMergeBBox}
                  pageNavigationPluginInstance={pageNavigationPluginInstance}
                  setPdfCurrentPage={setPdfCurrentPage}
                  isReadOnly={isReadOnly}
                  setIsReadOnly={setIsReadOnly}
                  isScreenShotState={isScreenShotState}
                  setIsScreenShotState={setIsScreenShotState}
                  PDFUrl={PDFOrgUrl || PDFUrl}
                  handleSelectionAction={handleSelectionAction}
                  addScreenShot={addScreenShot}
                  addImage={addImage}
                  vimMode={vimMode}
                  pageNumber={pageNo}
                  setTextBBox={setTextBBox}
                  textBBox={textBBox}
                  selectedTextBBox={selectedTextBBox}
                  setSelectedTextBBox={setSelectedTextBBox}
                  resumeReprocessMode={resumeReprocessMode}
                  projectReprocessMode={projectReprocessMode}
                  customPageTypeElement={
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '300px',
                          justifyContent: 'end',
                          alignItems: 'center',
                          color: 'black'
                        }}
                      >
                        #
                        <PageOptionSelect
                          options={pageTypeOptions}
                          value={currentPageType ? currentPageTypeOption : null}
                          placeholder="Add Page Type"
                          onChange={(e) => handleUpdatePageData(e)}
                        />
                        {currentPageType &&
                          ['people_resume', 'project_resume'].includes(
                            currentPageType
                          ) && (
                            <PageOptionSelect
                              options={pageSubTypeOptions}
                              value={
                                currentPageSubType
                                  ? currentPageSubTypeOption
                                  : null
                              }
                              placeholder="Add Sub Type"
                              onChange={(e) => handleUpdatePageDataSubType(e)}
                            />
                          )}
                      </Box>
                    </>
                  }
                />
              </Box>
            </div>
          </Resizable>
          {selectedEntry?.type === 'image' ? (
            <div
              style={{
                width: editorWidth + 'px',
                height: '100%',
                opacity: isScreenShotState ? '0.2' : '1'
              }}
            >
              <Container>
                <SectionFixed>
                  <Box
                    sx={{
                      padding: '0px 20px',
                      overflow: 'auto',
                      '& .MuiFormControl-root': { width: '100%' }
                    }}
                    onMouseEnter={() => setIsFocusEditor(true)}
                    onMouseLeave={() => setIsFocusEditor(false)}
                  >
                    <Box sx={{ margin: '5px 0px' }}>
                      <Box sx={{ margin: '5px 0px' }}>Keywords:</Box>
                      <InputTags
                        showTagOnly={_.isEmpty(selectedEntry)}
                        tags={selectedEntry?.tags || []}
                        setTags={(e) => handleSetTags(e)}
                        placeholder={'Image Keywords'}
                      />
                    </Box>
                    <Box sx={{ margin: '5px 0px' }}>
                      <Box sx={{ margin: '5px 0px' }}>Caption:</Box>
                      <TextField
                        multiline
                        rows={4}
                        placeholder="Caption"
                        value={selectedEntry?.caption || ''}
                        onChange={(e) => handleCaptionChange(e)}
                      />
                    </Box>
                    <Box sx={{ margin: '5px 0px' }}>
                      <Box sx={{ margin: '5px 0px' }}>Detected Text:</Box>
                      <TextField
                        multiline
                        rows={4}
                        placeholder="Text"
                        value={selectedEntry?.section[0]?.text || ''}
                        onChange={(e) => handleScannedTextChange(e)}
                      />
                    </Box>
                    <Box sx={{ margin: '5px 0px' }}>
                      <Box sx={{ margin: '5px 0px' }}>Classified as:</Box>
                      <TextField
                        multiline
                        rows={1}
                        placeholder="Text"
                        value={selectedEntry?.classification || ''}
                        slotProps={{
                          input: {
                            readOnly: true
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </SectionFixed>
              </Container>
            </div>
          ) : (
            <div
              style={{
                width: editorWidth + 'px',
                height: '100%',
                opacity: isScreenShotState ? '0.2' : '1'
              }}
            >
              <Container>
                <Section>
                  <Box
                    onMouseEnter={() => setIsFocusEditor(true)}
                    onMouseLeave={() => setIsFocusEditor(false)}
                    sx={{
                      height: '100%',
                      '& .ContentEditable__root': {
                        height: '1px',
                        minHeight: '95%'
                      }
                    }}
                  >
                    {selectedEntry?.type === 'resume' ||
                    resumeReprocessMode === 'textextract' ? (
                      <Suspense fallback={<Loader loading={true} />}>
                        <ResumeEditor
                          annotations={annotations}
                          setAnnotations={setAnnotations}
                          selectedEntry={selectedEntry}
                          setSelectedEntry={setSelectedEntry}
                          isSaving={isSaving || isReadOnly}
                          s3Obj={s3Obj}
                          faces={faces}
                          setFaces={setFaces}
                          currentPage={pdfCurrentPage}
                          edit={resumeEditorState}
                          setEdit={setResumeEditorState}
                          reprocessMode={resumeReprocessMode}
                          textBBox={textBBox}
                          setTextBBox={setTextBBox}
                          handleSelectionAction={handleSelectionAction}
                          setIsSaving={setIsSaving}
                          signerObj={signerObj}
                          handleResumeVerify={handleResumeVerify}
                          setSelectedImage={setSelectedImage}
                          handleMode={handleMode}
                          addprofilePicture={addprofilePicture}
                        />
                      </Suspense>
                    ) : (
                      <>
                        {selectedEntry?.type === 'project' ||
                        projectReprocessMode === 'textextract' ? (
                          <ProjectEditor
                            annotations={annotations}
                            setAnnotations={setAnnotations}
                            selectedEntry={selectedEntry}
                            setSelectedEntry={setSelectedEntry}
                            isSaving={isSaving || isReadOnly}
                            s3Obj={s3Obj}
                            faces={faces}
                            setFaces={setFaces}
                            currentPage={pdfCurrentPage}
                            edit={resumeEditorState}
                            setEdit={setResumeEditorState}
                            reprocessMode={projectReprocessMode}
                            textBBox={textBBox}
                            setTextBBox={setTextBBox}
                            handleSelectionAction={handleSelectionAction}
                            setIsSaving={setIsSaving}
                            signerObj={signerObj}
                            handleResumeVerify={handleResumeVerify}
                            setSelectedImage={setSelectedImage}
                            handleMode={handleMode}
                            addprofilePicture={addprofilePicture}
                          />
                        ) : (
                          <Editor
                            editorWidth={editorWidth}
                            selectedEntry={selectedEntry}
                            setEditedState={setEditedState}
                            isReadOnly={
                              isReadOnly ||
                              _.isEmpty(selectedEntry) ||
                              selectedEntry?.type === 'section-temp'
                            }
                            seteditorUpdating={seteditorUpdating}
                            enableTextFormat
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Section>
                {['section', 'image'].includes(selectedEntry?.type) && (
                  <SectionFixed>
                    <Box
                      sx={{
                        padding: '0px 20px',
                        overflow: 'auto',
                        height: '150px',
                        '& .MuiFormControl-root': { width: '100%' }
                      }}
                    >
                      <InputTags
                        showTagOnly={_.isEmpty(selectedEntry)}
                        tags={selectedEntry?.tags || []}
                        setTags={(e) => handleSetTags(e)}
                      />
                    </Box>
                  </SectionFixed>
                )}
              </Container>
            </div>
          )}
        </div>
      </Section>
    </Container>
  )
}

export default PDFSectionEditor
