import {
  ADD_DOCUMENT_TO_CHAT,
  ADD_CONTENT_TO_CHAT,
  SET_CHAT_STATE,
  CLEAR_DOCUMENT_TO_CHAT,
  ATTACH_ALERT_TO_CHAT,
  CLEAR_CONTENT_TO_CHAT
} from '../../../actionTypes'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'
import featureGroup from '@/config/feature-group'

const addDocumentToChat = (data, trigger) => async (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT_TO_CHAT,
    payload: data
  })
  dispatch({
    type: SET_CHAT_STATE,
    payload: 'open'
  })

  trackEvent(
    mixpanelEvents.CHAT_FILE_ATTACHED,
    'SUCCESS',
    {},
    {
      'Feature Group': featureGroup.CHAT_V2,
      document_type: data?.document_type ?? 'document',
      trigger: trigger ?? 'document-preview'
    }
  )
}

const attachAlertToChat = (data) => async (dispatch) => {
  dispatch({
    type: ATTACH_ALERT_TO_CHAT,
    payload: data
  })
}

const clearDocumentFromChat = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_TO_CHAT,
    payload: {}
  })
}

const addContentToChat = (data) => async (dispatch) => {
  dispatch({
    type: ADD_CONTENT_TO_CHAT,
    payload: data
  })
  dispatch({
    type: SET_CHAT_STATE,
    payload: 'open'
  })

  trackEvent(
    mixpanelEvents.CHAT_ADD_CONTENT_TO_CHAT,
    'SUCCESS',
    {},
    { 'Feature Group': featureGroup.CHAT_V2 }
  )
}

const setChatState = (data) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_STATE,
    payload: data
  })
}

const resetChatContent = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CONTENT_TO_CHAT,
    payload: ''
  })
}

export {
  addDocumentToChat,
  addContentToChat,
  clearDocumentFromChat,
  setChatState,
  attachAlertToChat,
  resetChatContent
}
