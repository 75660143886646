import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './styles/index.css'
import App from './App'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { PostHogProvider } from 'posthog-js/react'
import * as serviceWorker from './serviceWorker'
import { ThemeContext } from './ThemeContext'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
}

Amplify.configure(awsExports)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StyledEngineProvider injectFirst>
    <PostHogProvider
      apiKey={
        process.env.REACT_APP_ENV === 'production'
          ? process.env.REACT_APP_PUBLIC_POSTHOG_KEY
          : ''
      }
      options={options}
    >
      <ThemeContext>
        <App />
        <div id="radix-portal-container" className="twp" />
      </ThemeContext>
    </PostHogProvider>
  </StyledEngineProvider>
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js').then(
      (registration) => {
        console.log(
          'ServiceWorker registration successful with scope: ',
          registration.scope
        )
      },
      (error) => {
        console.log('ServiceWorker registration failed: ', error)
      }
    )
  })
}
