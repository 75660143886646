import React, { useEffect, useRef, useState } from 'react'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'

import { cn } from '@/lib/utils'
import { AiGenerateSubMenu } from './ai-generate-submenu'

type XYPosition = {
  x: number | string
  y: number | string
}

export type MenuItem = React.ComponentPropsWithoutRef<
  typeof DropdownMenuItem
> & {
  id: string
  label: string
  icon?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement>, props: any) => void
}

type MenuProps = {
  style?: React.CSSProperties
  position: XYPosition | undefined
  onClose?: () => void
  items: MenuItem[]
  textSelectProps?: any
}

export const PdfViewerContextMenu = ({
  style,
  position,
  onClose,
  items,
  textSelectProps
}: MenuProps) => {
  const [open, setOpen] = useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpen(position !== undefined)
  }, [position, triggerRef])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.()
    }

    setOpen(open)
  }

  return (
    <DropdownMenu open={open} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        {/* Invisible trigger to position the dropdown menu in the correct place */}
        <div
          ref={triggerRef}
          className={cn(
            'absolute z-[1500]',
            position && open ? 'visible' : 'invisible'
          )}
          style={{
            left: position?.x ?? -1000,
            top: position?.y ?? -1000
          }}
        ></div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        autoFocus
        className="twp w-56"
        style={style}
        align="start"
      >
        <DropdownMenuGroup>
          {items.map((item) =>
            item.id === 'ai-generate' ? (
              <AiGenerateSubMenu
                key={item.id}
                textSelectProps={textSelectProps}
                item={item}
              />
            ) : (
              <DropdownMenuItem
                key={item.id}
                className={cn('flex items-center gap-2', item.className)}
                {...item}
                onClick={(e) => item.onClick?.(e, textSelectProps)}
              >
                {item.icon}
                {item.label}
              </DropdownMenuItem>
            )
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
