import React, { useEffect, useRef, useState } from 'react'
import {
  CircleLoader,
  CrossIcon,
  SearchIcon
} from '../../components/Icons/Icons'
import useAutocomplete from '@mui/material/useAutocomplete'
import { styled } from '@mui/system'
import { Avatar } from '@mui/material'
import {
  getColorsForInitials,
  getInitialsFromUserName
} from '../../utils/String'

const SearchNew = ({
  onEnter = () => {},
  onChange = () => {},
  onSelect = () => {},
  autoComplete = false,
  onClear = null,
  value,
  onDebounce,
  applyDebounce,
  loading,
  style,
  placeholder = 'Search...',
  inputStyle = {},
  autoFocus = false,
  options = []
}) => {
  // const inputRef = useRef(null)

  useEffect(() => {
    if (applyDebounce) {
      const timer = setTimeout(() => {
        onDebounce(value)
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [value])

  if (autoComplete) {
    return (
      <UseAutocomplete
        onEnter={onEnter}
        onChange={onChange}
        onSelect={onSelect}
        onClear={onClear}
        value={value}
        loading={loading}
        style={style}
        placeholder={placeholder}
        inputStyle={inputStyle}
        options={options}
      />
    )
  }

  return (
    <div
      className="flex gap-2 items-center bg-grey-50 p-2 px-3 rounded-lg border-1 border-grey-200"
      style={style}
    >
      <SearchIcon className="size-4 text-grey-500" />
      <input
        type="text"
        className="input-base bg-grey-50 w-full font-poppins"
        style={inputStyle}
        placeholder={placeholder}
        value={value}
        // autoFocus
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onEnter(event.target.value)
          }
        }}
      />
      {loading ? (
        <CircleLoader className="size-4 text-grey-500 animate-spin ml-auto" />
      ) : (
        <CrossIcon
          className={`size-4 text-grey-500 cursor-pointer ml-auto ${
            value.length > 0 ? 'visible' : 'invisible'
          }`}
          onClick={() => (onClear ? onClear('') : onChange(''))}
        />
      )}
    </div>
  )
}

export default SearchNew

const Label = styled('label')({
  display: 'block'
})

const Input = styled('input')(({ theme }) => ({
  width: 200,
  backgroundColor: '#fff',
  color: '#000',
  ...theme.applyStyles('dark', {
    backgroundColor: '#000',
    color: '#fff'
  })
}))

const Listbox = styled('ul')(({ theme }) => ({
  width: 200,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: '#fff',
  overflow: 'auto',
  maxHeight: 200,
  border: '1px solid rgba(0,0,0,.25)',
  '& li.Mui-focused': {
    backgroundColor: '#4a8df6',
    color: 'white',
    cursor: 'pointer'
  },
  '& li:active': {
    backgroundColor: '#2977f5',
    color: 'white'
  },
  ...theme.applyStyles('dark', {
    backgroundColor: '#000'
  })
}))

function UseAutocomplete({
  onEnter = () => {},
  onChange = () => {},
  onSelect = () => {},
  onClear = null,
  value,
  loading,
  style,
  placeholder = 'Search...',
  inputStyle = {},
  options
}) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options,
    getOptionLabel: (option) => {
      console.log('option', option)
      return option?.label
    }
  })

  const [searchValue, setSearchValue] = useState(value)

  useEffect(() => {
    console.log('options', options)
  }, [options])

  return (
    <div>
      <div {...getRootProps()}>
        {/* <Label {...getInputLabelProps()}>useAutocomplete</Label> */}
        <div
          className="flex gap-2 items-center bg-grey-50 p-2 px-3 rounded-lg border-1 border-grey-200"
          style={style}
        >
          <SearchIcon className="size-4 text-grey-500" />
          <input
            type="text"
            className="input-base bg-grey-50 w-full font-poppins"
            style={inputStyle}
            placeholder={placeholder}
            value={searchValue}
            autoFocus
            onChange={(event) => setSearchValue(event.target.value)}
            {...getInputProps()}
          />
          {loading ? (
            <CircleLoader className="size-4 text-grey-500 animate-spin ml-auto" />
          ) : (
            <CrossIcon
              className={`size-4 text-grey-500 cursor-pointer ml-auto ${
                value.length > 0 ? 'visible' : 'invisible'
              }`}
              onClick={() => (onClear ? onClear('') : onChange(''))}
            />
          )}
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox
          {...getListboxProps()}
          className="bg-zinc-100 shadow-lg rounded p-4 flex flex-col gap-2 border-box"
          sx={{
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '8px',
            padding: '12px',
            border: 'none',
            width: '92%',
            maxHeight: '200px',
            overflow: 'auto'
          }}
        >
          {groupedOptions.map((user, index) => {
            const { key, ...optionProps } = getOptionProps({ user, index })

            return (
              <div
                key={user.value}
                className="flex items-center gap-2 cursor-pointer overflow-hidden"
                onClick={() => {
                  onSelect(user)
                }}
                style={{
                  width: '90%',
                  minHeight: '40px'
                }}
                // {...optionProps}
              >
                <Avatar
                  sx={{
                    backgroundColor: getColorsForInitials(user?.label),
                    height: '32px',
                    width: '32px',
                    fontSize: '14px'
                  }}
                >
                  {getInitialsFromUserName(user?.label?.toUpperCase())}
                </Avatar>

                <div className="">
                  <p className="m-0 text-sm font-medium">{user?.label}</p>
                  <p className="m-0 text-xs text-grey-600">{user?.email}</p>
                </div>
              </div>
            )
          })}
        </Listbox>
      ) : null}
    </div>
  )
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
