import { useState, useEffect } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  ListItemIcon,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Checkbox,
  Tooltip,
  Fade
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { TextInput, Button } from '../index'
import { useStyles } from './styles'
import { getTagCenterIcon } from '../../sections/TagCenterSettings'
import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { theme } from '../../config/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ClearIcon from '@mui/icons-material/Clear'
import { toast } from 'react-toastify'
import moment from 'moment'
import FolderIcon from '@mui/icons-material/Folder'
import ResourceAddToCollection from '../ResourceAddToCollection'
import AddIcon from '@mui/icons-material/Add'
import DocumentAdd from './DocumentAdd'
import EllipsisTooltip from '../EllipsisTooltip'
import clsx from 'clsx'
import Button2 from '../Button/Button2'
import { AttachIcon, CrossIcon, PlusIcon } from '../Icons/Icons'
import { clearDocumentFromChat } from '../../store/Chat/Actions/filter'
import { useDispatch } from 'react-redux'
import { renderURL } from '../../utils/TagCenter'

const TagFilter = (props) => {
  const {
    tags = null,
    onChange = () => {},
    disabled = false,
    selectedTags = {},
    clearFilter = <></>,
    showButton = true,
    showTags = true,
    filterIcon = <FilterListIcon />,
    showCollectionOptions = false,
    showDocumentOptions = false,
    source = null,
    parent = null,
    showAddButton = true
  } = props

  const dispatch = useDispatch()
  const [cardView, setCardView] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [tagsProps, setTagsProps] = useState({})
  const [currentTags, setCurrentTags] = useState({})
  const [tagKey, setTagKey] = useState(null)
  const [tagValues, setTagValues] = useState([])
  const [isDatePickerOpen, setDatePickerOpen] = useState(false)
  const open = Boolean(anchorEl)
  const classes = useStyles()
  const [anchorElTag, setAnchorElTag] = useState(null)
  const [selectedTag, setSelectedTag] = useState(null)
  const openTag = Boolean(anchorElTag)
  const [search, setSearch] = useState('')
  const [searchValues, setSearchValues] = useState('')
  const [searchSelectedValues, setSearchSelectedValues] = useState('')
  const [openCollectionSelector, setOpenCollectionSelector] = useState(false)
  const [openDocumentSelector, setOpenDocumentSelector] = useState(false)
  const [anchorElContition, setAnchorElCondition] = useState(null)
  const openCondition = Boolean(anchorElContition)
  const [sortedTags, setSortedTags] = useState(null)

  const dropdownValues = {
    people: {
      label: 'Employee type',
      key: 'employee_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' },
        { label: 'Inactive', value: 'inactive' }
      ]
    },

    client: {
      label: 'Client type',
      key: 'client_type',
      options: [
        { label: 'Client', value: 'client' },
        { label: 'Partner', value: 'partner' },
        { label: 'Competitor', value: 'competitor' }
      ]
    },

    project: {
      label: 'Project type',
      key: 'project_type',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
        { label: 'Unknown', value: 'unknown' }
      ]
    }
  }

  useEffect(() => {
    if (tags) {
      const tempSortedTags = Object.keys(tags)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) // Case-insensitive sorting
        .reduce((obj, key) => {
          obj[key] = tags[key]
          return obj
        }, {})

      if (source && dropdownValues[source]) {
        tempSortedTags[dropdownValues[source].label] = {
          type: 'defaultsingle',
          // label: dropdownValues[source].label,
          values: dropdownValues[source].options
        }
      }

      setSortedTags(tempSortedTags)
    }
  }, [tags])

  useEffect(() => {
    if (open) {
      if (sortedTags && !_.isEqual(sortedTags, tagsProps)) {
        const tagsObj = {}
        const selectedTagsArray = Object.keys(selectedTags || {})
        Object.keys(sortedTags).forEach((tag) => {
          if (!selectedTagsArray.includes(tag)) {
            tagsObj[tag] = sortedTags[tag]
          }
        })
        setCurrentTags(tagsObj)
        setTagsProps(sortedTags)
      }
    } else {
      cleanUp()
    }
  }, [sortedTags, open])

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorElTag(null)
    setAnchorEl(null)
    setAnchorElCondition(null)
    setSelectedTag(null)
    setTagKey(null)
    setSearch('')
    setSearchSelectedValues('')
    setSearchValues('')
    setCurrentTags({})
    setTagsProps({})
    setTagValues([])
  }

  const handleOpenMenuTag = (event, key) => {
    setAnchorElTag(event.currentTarget)
    const { type } = sortedTags[key] || {}
    let selectedValues = selectedTags[key]?.values
    if (type?.includes('date')) {
      selectedValues = [...selectedValues?.[0]?.value] || []
    }
    setTagKey(key)
    setTagValues(selectedValues)
  }

  const cleanUp = () => {
    setTagKey(null)
    setSearch('')
    setSearchSelectedValues('')
    setSearchValues('')
    setCurrentTags({})
    setTagsProps({})
    setTagValues([])
  }
  const handleMenuSelectKey = (value) => {
    if (value === 'collections_selected') {
      setOpenCollectionSelector(true)
      handleCloseMenu()
    } else if (value === 'documents_selected') {
      setOpenDocumentSelector(true)
      handleCloseMenu()
    } else {
      setTagKey(value)
      setTagValues([])
    }
  }

  const handleValueSelect = (tag, type) => {
    if (type === 'defaultsingle') {
      setTagValues([tag])
    } else {
      let newValues = [...tagValues]
      const index = tagValues.findIndex((value) => value.value === tag.value)
      if (index === -1) {
        newValues.push(tag)
      } else {
        newValues = tagValues.filter((value) => value.value !== tag.value)
      }
      setTagValues(newValues)
    }
  }

  const handleSelect = () => {
    handleCloseMenu()

    console.log('tagKey apply', tagKey, tagValues, 'value')
    onChange(tagKey, tagValues, 'value')
  }

  const handleSelectDate = (type) => {
    const isValidDate =
      !isNaN(Date.parse(tagValues?.[0])) && !isNaN(Date.parse(tagValues?.[1]))
    if (!isValidDate) {
      toast.error('Please select valid dates')
      return
    }
    const startDate = new Date(tagValues?.[0])
    startDate.setHours(0, 0, 0, 0)
    const start = startDate.toISOString()

    const endDate = new Date(tagValues?.[1])
    endDate.setHours(23, 59, 59, 999)
    const end = endDate.toISOString()

    if (start && end) {
      if (start > end) {
        toast.error('Start date cannot be greater than end date')
        return
      }

      const value = [
        {
          value: tagValues,
          start,
          end,
          key: tagKey,
          type,
          label:
            moment(tagValues?.[0]).format('ll') +
            ' - ' +
            moment(tagValues?.[1]).format('ll')
        }
      ]
      handleCloseMenu()
      onChange(tagKey, value, 'value')
    } else {
      toast.error('Please select both dates')
    }
  }

  const handleBack = () => {
    setTagKey(null)
  }

  const renderPredefinedDates = (type) => {
    const predefinedDates = [
      'This Week',
      'Last 3 months',
      'Last 6 months',
      'Last 9 months',
      'This Year',
      'Last Year'
    ]
    return (
      <Box className={classes.predefinedDatesWrapper}>
        {predefinedDates.map((date, index) => {
          return (
            <Box key={index}>
              <IconButton
                disableRipple
                className={classes.predefinedDates}
                key={index}
                onClick={() => handlePredefinedDateSelect(date, type)}
              >
                {date}
              </IconButton>
            </Box>
          )
        })}
      </Box>
    )
  }

  const handlePredefinedDateSelect = (date, type) => {
    let start = null
    let end = null
    switch (date) {
      case 'This Week':
        start = moment().startOf('week')
        end = moment().endOf('week')
        break
      case 'Last 3 months':
        start = moment().subtract(3, 'months')
        end = moment()
        break
      case 'Last 6 months':
        start = moment().subtract(6, 'months')
        end = moment()
        break
      case 'Last 9 months':
        start = moment().subtract(9, 'months')
        end = moment()
        break
      case 'This Year':
        start = moment().startOf('year')
        end = moment().endOf('year')
        break
      case 'Last Year':
        start = moment().subtract(1, 'year').startOf('year')
        end = moment().subtract(1, 'year').endOf('year')
        break
      default:
        break
    }
    if (start && end) {
      start = start
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString()
      end = end
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toISOString()
      const value = [
        {
          value: [start, end],
          start,
          end,
          key: tagKey,
          type,
          label: moment(start).format('ll') + ' - ' + moment(end).format('ll')
        }
      ]
      handleCloseMenu()
      onChange(tagKey, value, 'value')
    }
  }

  const renderTagValues = () => {
    if (tagKey !== 'collections_selected') {
      const { type, editable, values = [] } = currentTags[tagKey] || {}
      const isURL = type?.includes('url')
      return type?.includes('date') ? (
        <Box className={classes.listItem}>
          <Box className={classes.selectedKey}>
            <ListItemIcon>
              <IconButton
                sx={{ padding: '0px' }}
                onClick={() => handleBack()}
                disableRipple
              >
                <ArrowBackIosIcon />
              </IconButton>
              {getTagCenterIcon(type, editable)}
            </ListItemIcon>
            {type?.includes('default') ? _.startCase(tagKey) : tagKey}
          </Box>
          <MenuItem>
            <Box className={classes.dateInput}>
              <DatePicker
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                value={tagValues[0] || null}
                onChange={(newValue) => {
                  setTagValues([newValue || null, tagValues[1]])
                }}
                renderInput={(props) => {
                  return (
                    <TextInput
                      {...props}
                      helperText=""
                      inputProps={{
                        ...props.inputProps,
                        placeholder: 'From (mm/dd/yy)'
                      }}
                    />
                  )
                }}
              />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box className={classes.dateInput}>
              <DatePicker
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                value={tagValues[1] || null}
                onChange={(newValue) =>
                  setTagValues([tagValues[0], newValue || null])
                }
                renderInput={(props) => {
                  return (
                    <TextInput
                      {...props}
                      helperText=""
                      inputProps={{
                        ...props.inputProps,
                        placeholder: 'To (mm/dd/yy)'
                      }}
                    />
                  )
                }}
              />
            </Box>
          </MenuItem>
          <Box className={classes.button}>
            <Button variant="outlined" onClick={() => handleSelectDate(type)}>
              Apply
            </Button>
          </Box>
          {renderPredefinedDates(type)}
        </Box>
      ) : (
        <Box>
          {values.length === 0 ? (
            <Box className={classes.listItem}>
              <Box className={classes.selectedKey}>
                <ListItemIcon>
                  <IconButton
                    sx={{ padding: '0px' }}
                    onClick={() => handleBack()}
                    disableRipple
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  {getTagCenterIcon(type, editable)}
                </ListItemIcon>
                {type?.includes('default') ? _.startCase(tagKey) : tagKey}
              </Box>
              <Box sx={{ textAlign: 'center' }}>No values found</Box>
            </Box>
          ) : (
            <>
              <TextInput
                value={searchValues}
                handleChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSearchValues(e.target.value)
                }}
                placeholder="Search"
                onKeyDown={(e) => e.stopPropagation()}
                className={classes.searchInput}
              />
              <Box className={classes.listItem}>
                <Box
                  className={classes.selectedKey}
                  onClick={() => handleBack()}
                >
                  <ListItemIcon>
                    <IconButton sx={{ padding: '0px' }} disableRipple>
                      <ArrowBackIosIcon />
                    </IconButton>
                  </ListItemIcon>
                  {tagKey?.includes('_') ? _.startCase(tagKey) : tagKey}
                </Box>
                {(
                  values.filter((tag) =>
                    tag.label.toLowerCase().includes(searchValues.toLowerCase())
                  ) || []
                ).map((tag, index) => {
                  const indexSelected = tagValues.findIndex(
                    (value) => value.value === tag.value
                  )
                  const selected = indexSelected !== -1
                  return (
                    <MenuItem
                      selected={selected}
                      key={index}
                      onClick={() => handleValueSelect(tag, type)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          disableRipple
                          color="primary"
                          checked={selected}
                          onChange={() => {
                            handleValueSelect(tag, type)
                          }}
                          size="small"
                          sx={{ padding: '0px' }}
                        />
                      </ListItemIcon>
                      {isURL ? renderURL(tag.label, true) : tag.label}
                    </MenuItem>
                  )
                })}
              </Box>
              <Box className={classes.button}>
                <Button variant="outlined" onClick={() => handleSelect()}>
                  Apply
                </Button>
              </Box>
            </>
          )}
        </Box>
      )
    }
  }

  const handleSelectedConditionClick = (tag, condition) => {
    setAnchorElCondition(null)
    onChange(tag, condition, 'condition')
  }

  const renderSelectedTagValues = (selectedTag) => {
    const tag = sortedTags[selectedTag] || {}
    if (tagKey !== 'collections_selected') {
      const {
        type,
        editable,
        values = [],
        label = '',
        is_generated = false
      } = tag
      const isURL = type?.includes('url')
      const isDate = type?.includes('date')
      return isDate ? (
        <Box className={classes.listItem}>
          <Box className={classes.selectedKey}>
            <ListItemIcon>{getTagCenterIcon(tag)}</ListItemIcon>
            {type?.includes('default') ? _.startCase(selectedTag) : selectedTag}
          </Box>
          <MenuItem>
            <Box className={classes.dateInput}>
              <DatePicker
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                value={tagValues[0] || null}
                onChange={(newValue) => {
                  setTagValues([newValue || null, tagValues[1]])
                }}
                renderInput={(props) => {
                  return (
                    <TextInput
                      {...props}
                      helperText=""
                      inputProps={{
                        ...props.inputProps,
                        placeholder: 'From (mm/dd/yy)'
                      }}
                    />
                  )
                }}
              />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box className={classes.dateInput}>
              <DatePicker
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                value={tagValues[1] || null}
                onChange={(newValue) =>
                  setTagValues([tagValues[0], newValue || null])
                }
                renderInput={(props) => {
                  return (
                    <TextInput
                      {...props}
                      helperText=""
                      inputProps={{
                        ...props.inputProps,
                        placeholder: 'To (mm/dd/yy)'
                      }}
                    />
                  )
                }}
              />
            </Box>
          </MenuItem>
          <Box className={classes.button}>
            <Button variant="outlined" onClick={() => handleSelectDate(type)}>
              Apply
            </Button>
          </Box>
          {renderPredefinedDates(type)}
        </Box>
      ) : (
        <Box>
          {values.length === 0 ? (
            <Box className={classes.listItem}>
              <Box className={classes.selectedKey}>
                <ListItemIcon>
                  <IconButton
                    sx={{ padding: '0px' }}
                    onClick={() => handleBack()}
                    disableRipple
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  {getTagCenterIcon(tag)}
                </ListItemIcon>
                {type?.includes('default') ? _.startCase(tagKey) : tagKey}
              </Box>
              <Box sx={{ textAlign: 'center' }}>No values found</Box>
            </Box>
          ) : (
            <>
              <TextInput
                value={searchSelectedValues}
                handleChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSearchSelectedValues(e.target.value)
                }}
                placeholder="Search"
                onKeyDown={(e) => e.stopPropagation()}
                className={classes.searchInput}
              />
              <Box className={classes.listItem}>
                <Box className={classes.selectedKey}>
                  <ListItemIcon>{getTagCenterIcon(tag)}</ListItemIcon>
                  {selectedTag?.includes('_')
                    ? _.startCase(selectedTag.replace('generated_', ''))
                    : selectedTag}
                </Box>
                {(
                  values.filter((tag) =>
                    tag.label
                      .toLowerCase()
                      ?.includes(searchSelectedValues.toLowerCase())
                  ) || []
                ).map((tag, index) => {
                  const indexSelected = tagValues.findIndex(
                    (value) => value.value === tag.value
                  )
                  const selected = indexSelected !== -1
                  return (
                    <MenuItem
                      selected={selected}
                      key={index}
                      onClick={() => handleValueSelect(tag, type)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          disableRipple
                          color="primary"
                          checked={selected}
                          onChange={() => {
                            handleValueSelect(tag, type)
                          }}
                          size="small"
                          sx={{ padding: '0px' }}
                        />
                      </ListItemIcon>
                      {isURL ? renderURL(tag.label, true) : tag.label}
                    </MenuItem>
                  )
                })}
              </Box>
              <Box className={classes.button}>
                <Button onClick={() => handleSelect()}>Apply</Button>
              </Box>
            </>
          )}
        </Box>
      )
    }
  }

  const handleCollectionSelect = (collections) => {
    setOpenCollectionSelector(false)
    onChange('collections_selected', collections, 'value')
  }

  const handleDocumentSelect = (documents) => {
    setOpenDocumentSelector(false)
    onChange('documents_selected', documents, 'value')
  }

  const getSelectedTagsOptions = (selectedTag) => {
    const { type } = sortedTags[selectedTag] || {}
    let options = [
      {
        label: 'is',
        value: 'is'
      },
      {
        label: 'is not',
        value: 'is not'
      }
    ]
    if (type?.includes('date')) {
      options = [
        {
          label: 'is between',
          value: 'is'
        },
        {
          label: 'is not between',
          value: 'is not'
        }
      ]
    }
    return options
  }

  const getSelectedTagValue = (tag) => {
    const { type } = sortedTags[tag] || {}
    let value = selectedTags[tag]?.condition || 'is'
    if (type?.includes('date')) {
      value = value === 'is not' ? 'is not between' : 'is between'
    }
    return value
  }

  const renderSelectedTagsElement = () => {
    return (
      <Box className={classes.tagsContainer + ' w-full'}>
        <Menu
          anchorEl={anchorElTag}
          open={openTag}
          onClose={handleCloseMenu}
          className={classes.menu}
          onKeyDown={(e) => e.stopPropagation()}
          disableEscapeKeyDown={isDatePickerOpen}
          sx={{
            zIndex: 1302
          }}
          componentsProps={{
            root: {
              'data-dialog-parent': parent
            }
          }}
        >
          {selectedTag && renderSelectedTagValues(selectedTag)}
        </Menu>
        <Menu
          anchorEl={anchorElContition}
          open={openCondition}
          onClose={handleCloseMenu}
          className={classes.menu}
          sx={{
            zIndex: 1302
          }}
          componentsProps={{
            root: {
              'data-dialog-parent': parent
            }
          }}
        >
          {getSelectedTagsOptions(selectedTag).map((option, index) => {
            return (
              <MenuItem
                key={index}
                selected={
                  selectedTags?.[selectedTag]?.condition === option.value
                }
                onClick={() =>
                  handleSelectedConditionClick(selectedTag, option.value)
                }
              >
                {option.label}
              </MenuItem>
            )
          })}
        </Menu>
        {Object.keys(selectedTags || {}).map((tag, index) => {
          const tagKey = tag.includes('_') ? _.startCase(tag) : tag
          const tagSeperator = getSelectedTagValue(tag)
          const tagValuesLabel = selectedTags[tag]?.values
            .map((value) => value.label)
            .join(', ')

          if (
            [
              'documents_selected',
              'rfx_selected',
              'analytics_selected'
            ].includes(tag) &&
            cardView &&
            tagValuesLabel
          ) {
            return (
              <DocumentCard
                key={index}
                file={tagValuesLabel}
                onClear={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onChange(tag, [], 'value')
                  dispatch(clearDocumentFromChat(tag))
                }}
              />
            )
          }

          return (
            <Box
              key={index}
              sx={{
                marginBottom: '5px',
                display: 'flex',
                overflow: 'auto'
              }}
            >
              <Box
                key={index}
                className={
                  disabled
                    ? clsx(classes.flexContainer, classes.selectedTagDisabled)
                    : clsx(classes.flexContainer, classes.selectedTag)
                }
              >
                <Box className={classes.flexShrinkItem} sx={{ flexShrink: 0 }}>
                  <EllipsisTooltip text={tagKey} />
                </Box>
                <IconButton
                  className={classes.selectTagButton}
                  disabled={disabled}
                  sx={{
                    color: 'var(--grey-500)'
                  }}
                  disableRipple
                  onClick={(event) => {
                    setAnchorElCondition(event.currentTarget)
                    setSelectedTag(tag)
                  }}
                >
                  <EllipsisTooltip text={tagSeperator} />
                </IconButton>
                {selectedTags[tag]?.values.map((value, index) => {
                  if (index === 0) {
                    return (
                      <Box
                        key={index}
                        className={
                          !disabled
                            ? clsx(
                                classes.flexShrinkItem,
                                classes.selectTagButton
                              )
                            : clsx(
                                classes.flexShrinkItem,
                                classes.selectTagButtonDisabled
                              )
                        }
                        disabled={disabled}
                        disableRipple
                        onClick={(e) => {
                          if (tag === 'collections_selected') {
                            setOpenCollectionSelector(true)
                          } else if (tag === 'documents_selected') {
                            setOpenDocumentSelector(true)
                          } else {
                            handleOpenMenuTag(e, tag)
                            setSelectedTag(tag)
                          }
                        }}
                      >
                        <EllipsisTooltip
                          text={
                            <>
                              {value.label}
                              {selectedTags[tag]?.values.length > 1 && (
                                <Tooltip
                                  arrow
                                  title={selectedTags[tag]?.values
                                    .slice(1)
                                    .map((value) => value.label)
                                    .join(', ')}
                                >
                                  <Box component="span">
                                    {' '}
                                    +{selectedTags[tag]?.values.length - 1}
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          }
                          tooltipText={tagValuesLabel}
                        />
                      </Box>
                    )
                  }
                  return <></>
                })}
                <IconButton
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onChange(tag, [], 'value')
                  }}
                  sx={{ padding: '0px' }}
                  disableRipple
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          )
        })}

        {!_.isEmpty(selectedTags) && showAddButton && (
          <Tooltip title="Add Filters">
            <IconButton
              sx={{ padding: 0 }}
              disableRipple
              disabled={disabled}
              onClick={handleOpenMenu}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <Box data-dialog-parent={parent} className="">
          {showCollectionOptions && (
            <ResourceAddToCollection
              isOpen={openCollectionSelector}
              handleClose={() => setOpenCollectionSelector(false)}
              actionButtons={[
                {
                  label: 'Select',
                  action: (e) => handleCollectionSelect(e)
                }
              ]}
              prevSelectedCollections={
                selectedTags?.collections_selected?.values || []
              }
              headerText={'Select Collections'}
              selectSubCollections
              parent={parent}
            />
          )}
          {showDocumentOptions && (
            <DocumentAdd
              isOpen={openDocumentSelector}
              handleClose={() => setOpenDocumentSelector(false)}
              actionButtons={[
                {
                  label: 'Attach',
                  action: (e) => handleDocumentSelect(e)
                }
              ]}
              prevSelectedCollections={
                selectedTags?.documents_selected?.values || []
              }
              headerText={'Attach Files'}
              parent={parent}
            />
          )}
          <Box className={disabled && classes.disabledWrapper}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              {showButton && (
                <IconButton
                  sx={{ padding: 0 }}
                  disableRipple
                  disabled={disabled}
                  onClick={handleOpenMenu}
                >
                  {filterIcon}
                </IconButton>
              )}
              {showDocumentOptions && (
                <Tooltip title="Attach">
                  <button
                    className="icon-btn rounded-md text-grey-700"
                    onClick={() => handleMenuSelectKey('documents_selected')}
                    style={{
                      padding: '5px'
                    }}
                  >
                    <AttachIcon className="size-4 text-grey-700" />
                  </button>
                </Tooltip>
              )}
              {!disabled && (
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  TransitionComponent={Fade}
                  onClose={handleCloseMenu}
                  className={classes.menu}
                  onKeyDown={(e) => e.stopPropagation()}
                  disableEscapeKeyDown={isDatePickerOpen}
                  sx={{
                    zIndex: 1302
                  }}
                  componentsProps={{
                    root: {
                      'data-dialog-parent': parent
                    }
                  }}
                >
                  {tagKey ? (
                    renderTagValues()
                  ) : (
                    <Box>
                      <TextInput
                        value={search}
                        handleChange={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setSearch(e.target.value)
                        }}
                        placeholder="Search"
                        onKeyDown={(e) => e.stopPropagation()}
                        className={classes.searchInput}
                      />
                      <Box className={classes.listItem}>
                        {showCollectionOptions &&
                          !selectedTags?.collections_selected && (
                            <MenuItem
                              onClick={() =>
                                handleMenuSelectKey('collections_selected')
                              }
                            >
                              <ListItemIcon>
                                <FolderIcon />
                              </ListItemIcon>
                              Collections
                            </MenuItem>
                          )}
                        {/* {showDocumentOptions &&
                          !selectedTags?.documents_selected && (
                            <MenuItem
                              onClick={() =>
                                handleMenuSelectKey('documents_selected')
                              }
                            >
                              <ListItemIcon>
                                <FolderIcon />
                              </ListItemIcon>
                              Documents
                            </MenuItem>
                          )} */}
                        {Object.keys(currentTags)
                          .filter((tag) =>
                            tag.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((tag, index) => {
                            const {
                              type,
                              editable,
                              label = ''
                            } = currentTags[tag] || {}
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => handleMenuSelectKey(tag)}
                              >
                                {label !== ''
                                  ? label
                                  : tag?.includes('_')
                                  ? _.startCase(tag)
                                  : tag}
                              </MenuItem>
                            )
                          })}
                      </Box>
                    </Box>
                  )}
                </Menu>
              )}
              {clearFilter}
            </Box>
            {showTags && sortedTags && renderSelectedTagsElement()}
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default TagFilter

const DocumentCard = ({ file, onClear }) => {
  const [extension, setExtension] = useState(null)
  const [fileName, setFileName] = useState(null)
  console.log('document card file', file)
  useEffect(() => {
    if (file.includes('pdf')) {
      setExtension('pdf')
      const tempFileName = file.split('.')[0]
      setFileName(tempFileName)
    }
  }, [file])

  function shortenFilename(filename) {
    if (filename.length > 15) {
      return `${filename.slice(0, 10)}...${filename.slice(-5)}`
    } else {
      return filename
    }
  }

  return (
    <div className="ml-1">
      <div
        className="flex items-center gap-1 p-1 bg-grey-50 rounded-lg px-2 parent-card"
        style={{
          border: '1px solid var(--grey-200)',
          borderBottom: 0,
          borderRadius: '10px 10px 0 0'
        }}
      >
        {extension === 'pdf' && (
          <div
            className="bg-red-500 text-white size-4 rounded-md grid place-content-center font-medium px-1"
            style={{
              fontSize: '9px'
            }}
          >
            PDF
          </div>
        )}
        {fileName && (
          <Tooltip title={file}>
            <p className="text-xs m-0 font-medium text-grey-700">
              {shortenFilename(fileName)}
            </p>
          </Tooltip>
        )}

        <div className="card-options2 cursor-pointer ml-2" onClick={onClear}>
          <CrossIcon className="size-3" />
        </div>
      </div>
    </div>
  )
}
