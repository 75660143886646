import { useEffect, useState } from 'react'
import * as React from 'react'
import { useStyles } from './styles'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import Grid from '@mui/material/Grid'
import Tab from '../../components/Tabs'
import {
  Viewer,
  SpecialZoomLevel,
  Position,
  Tooltip,
  MinimalButton,
  Icon
} from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as BackImg } from '../../assets/images/back.svg'
import Box from '@material-ui/core/Box'
import { Section, SectionFixed, Container } from '../../components/Container'
import ChatBot from '../../containers/ChatBot'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import ComplianceMatrix from './ComplianceMatrix'
import { useSelector, useDispatch } from 'react-redux'
import { searchPlugin } from '@react-pdf-viewer/search'
import ShareIcon from '@mui/icons-material/Share'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Button, ToasterAlert } from '../../components'
import TextField from '@mui/material/TextField'
import Loader from '../../components/Loader'
import { ShareRfx } from '../../store/api'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Error from '../../components/Error'
import Popover from '@mui/material/Popover'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import { checkUserRoleViewer } from '../../utils/User'
import Button2 from '../../components/Button/Button2'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import { initalizeAWSSigner } from '../../utils/AWS'
import { SparkIcon2 } from '../../components/Icons/Icons'
import { addDocumentToChat, attachAlertToChat } from '../../store/Chat/Actions'
import MUIToolTip from '@mui/material/Tooltip'

const PDFViewer = (props) => {
  const {
    url,
    isAnalytics,
    PDFData,
    setShowPDF = () => {},
    shareType = 'private',
    pageNo = 1,
    hideBreadCrumbs = false
  } = props
  const classes = useStyles()
  const {
    webviewer,
    page,
    infoWrapper,
    infoContainer,
    borderBot,
    titleContainer,
    iconButton,
    icon,
    pdfTitle,
    shareFileIcon,
    pdfHeader
  } = classes
  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar, renderDefaultToolbar } = toolbarPluginInstance
  const [activeTab, setActiveTab] = useState(0)
  const [aiPlugin, setAIPlugin] = useState(null)
  const [showError, setShowError] = useState(!!isAnalytics)
  const [pdfCurrentPage, setPdfCurrentPage] = useState(0)
  const { document_name = '', language = {} } = PDFData
  const { width, height, ref } = useResizeDetector()
  const [pdfWidth, setPdfWidth] = useState(0)
  const [toolbarWidth, setToolbarWidth] = useState(0)

  const [analysisReport, setAnalysisReport] = useState({})
  const matrix = []
  const [complianceMatrix, setComplianceMatrix] = useState([])
  const dispatch = useDispatch()
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState('custom')
  const [customValue, setCustomValue] = useState('14')
  const [linkLoading, setLinkLoading] = useState(false)
  const [shareableLink, setShareableLink] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isContentTranslated, setIsContentTranslated] = useState(false)

  useEffect(() => {
    setAnalysisReport(PDFData?.content[0]?.analysis_report)
    setIsContentTranslated(language?.rfx === language?.user)
  }, [])

  useEffect(() => {
    if (analysisReport) {
      setComplianceMatrix(analysisReport?.['Compliance List'])
    }
  }, [analysisReport])

  const [signerObj, setSignerObj] = useState(null)

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  const isUserViewer = checkUserRoleViewer()
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  useEffect(() => {
    handleOnResize()
  }, [width])

  useEffect(() => {
    if (PDFData?.id) {
      dispatch(
        attachAlertToChat({
          show: true,
          document_name: PDFData?.document_name,
          document_type: 'rfx',
          document_id: PDFData?.id
        })
      )
    }
  }, [PDFData])

  const handleOnResize = (event, obj) => {
    if (event && obj) {
      const newEWidth = obj.size.width
      const newSWidth = width - obj.size.width - 1
      if ((newSWidth / width) * 100 > 30 && (newEWidth / width) * 100 > 30) {
        setPdfWidth(newEWidth)
        setToolbarWidth(newSWidth)
      }
    } else {
      if (pdfWidth && toolbarWidth) {
        const sWidth = toolbarWidth
        const eWidth = width - sWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      } else {
        const eWidth = Math.round((60 / 100) * width)
        const sWidth = width - eWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      }
    }
  }

  const pdfName = document_name

  const transform = (slot, showAIPlugin) => {
    return {
      ...slot,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchScrollMode: () => <></>,
      SwitchScrollModeMenuItem: () => <></>
    }
  }

  const renderToolbar = (Toolbar) => {
    const TOOLTIP_OFFSET = { left: 50, top: 0 }
    return (
      <>
        {isAnalytics && (
          <Tooltip
            target={
              <MinimalButton
                onClick={() => {
                  setShowError(!showError)
                }}
              >
                <div style={{ color: showError ? '#6A6A6A' : 'black' }}>
                  <SvgIcon>
                    <Robot />
                  </SvgIcon>
                </div>
              </MinimalButton>
            }
            offset={TOOLTIP_OFFSET}
            position={Position.BottomCenter}
            content={() => `AI Generate ${showError ? 'Off' : 'On'}`}
          />
        )}
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </>
    )
  }

  const pageNavigationPluginInstance = pageNavigationPlugin()
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance
  const setGoToPage = (pageIndex) => {
    if (pageIndex > -1 && pageIndex !== 'new') {
      jumpToPage(pageIndex - 1)
    }
  }

  const searchPluginInstance = searchPlugin()
  const { jumpToMatch, highlight, jumpToNextMatch } = searchPluginInstance

  const handleTextSearch = (keyword) => {
    const searchText = 'Text to search in the PDF'
    const words = keyword.split(' ')
    // Get the first 3 words
    const firstThreeWords = words.slice(0, 3)
    const combinedSentence = firstThreeWords.join(' ')
    highlight(combinedSentence)
  }

  const handleTextSearchParagraph = (text) => {
    const words = text.split(' ')
    const initialWords = words.slice(0, 4).join(' ')
    highlight(initialWords)
  }

  const handleShareDialog = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenShareDialog(true)
  }

  const handleCloseShareDialog = () => {
    setShareableLink('')
    setIsCopied(false)
    setOpenShareDialog(false)
    setAnchorEl(null)
  }

  const handleCreateShareLink = async () => {
    setLinkLoading(true)
    const data = {
      rfx_id: PDFData?.id,
      valid_for_days: parseInt(customValue, 10)
    }
    const res = await ShareRfx(data)
    if (res.status === 200) {
      setShareableLink(
        `${process.env.REACT_APP_REDIRECT_URL}share/${res.data.data.id}`
      )
      trackEvent('share_rfx', 'SUCCESS', {}, data)

      setLinkLoading(false)
    }
  }

  const handleCustomValueChange = (event) => {
    const value = event.target.value
    if (value >= 0) {
      setCustomValue(value)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink)
    setIsCopied(true)
  }

  const handleTranslateAnalysis = async () => {
    const translatingToast = toast.loading('Translating the analysis report...')
    const { hidden, ...contentObj } = analysisReport
    const req = {
      requestType: 'translate',
      content: JSON.stringify(contentObj),
      translate_to: language?.user || 'en-US',
      content_type: 'rfx',
      file_id: PDFData?.id
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(req),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    let completeText = ''
    try {
      if (response.status === 200 && response.body) {
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }

          const text = new TextDecoder().decode(value)
          // console.log(text)
          completeText = completeText + text
        }
        completeText = completeText.split(/__END_OF_CHAT__/g)
        if (JSON.parse(completeText[0])) {
          // console.log()
          const data = JSON.parse(completeText[0])
          try {
            const newAnalysisReport = {}
            Object.keys(data).forEach((key) => {
              if (
                data[key] &&
                data[key].length > 0 &&
                data[key][0].length > 0
              ) {
                newAnalysisReport[key] = data[key]
              } else {
                newAnalysisReport[key] = analysisReport[key]
              }
            })
            setAnalysisReport(newAnalysisReport)
            setIsContentTranslated(true)
            toast.dismiss(translatingToast)
            toast.success('Analysis report translated successfully.')
          } catch (error) {
            toast.dismiss(translatingToast)
            toast.error('Something went wrong. Extraction Failed.')
          }
        }
      } else {
        toast.dismiss(translatingToast)
        toast.error('Something went wrong. Extraction Failed.')
      }
    } catch (error) {
      toast.dismiss(translatingToast)
      toast.error('Something went wrong. Extraction Failed.')
    }

    console.log(req)
  }

  const pdfToolTab = [
    {
      label: <span style={{ display: 'flex' }}>Analysis</span>,
      children: (
        <ComplianceMatrix
          content={analysisReport}
          complianceMatrix={complianceMatrix}
          setComplianceMatrix={setComplianceMatrix}
          id={PDFData.id}
          rfxName={PDFData.document_name}
          setGoToPage={setGoToPage}
          handleTextSearch={handleTextSearch}
          handleTextSearchParagraph={handleTextSearchParagraph}
          shareType={shareType}
          editingDisabled={
            isUserViewer && currentUserName !== PDFData?.created_by_user
          }
          isContentTranslated={isContentTranslated}
          handleTranslateAnalysis={handleTranslateAnalysis}
        />
      )
    }
    // {
    //   label: <span style={{ display: 'flex' }}>Chat</span>,
    //   children:
    //     shareType === 'private' ? (
    //       <ChatBot
    //         trackerId={PDFData?.id}
    //         chatType="rfx"
    //         textBoxPlaceholder={'Ask some questions about this RFX document'}
    //       />
    //     ) : (
    //       <Error
    //         errorMessage={'Please Login to access the Chat functionality'}
    //         refresh={false}
    //         errorLogo={false}
    //       />
    //     )
    // }
  ]

  const extraButtonsOnTab = [
    {
      label: <span style={{ display: 'flex' }}>Share</span>,
      icon: <ShareIcon style={{ fontSize: '15px' }} />,
      onClick: handleShareDialog
      // name: 'share'
    }
  ]

  const changeTab = (e) => {
    setActiveTab(e)
  }

  const handleNavigateBack = async () => {
    setShowPDF(false)
  }

  return (
    <>
      {PDFData.status === 'completed' ? (
        <Box
          container
          className={page}
          ref={ref}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          {pdfWidth && toolbarWidth ? (
            <>
              <Resizable
                width={pdfWidth}
                axis="x"
                onResize={handleOnResize}
                resizeHandles={['e']}
              >
                <Box
                  sx={{
                    width: pdfWidth - 1 + 'px',
                    height: '100%',
                    borderRight: '1px solid #EEEEEE'
                  }}
                  className={webviewer}
                >
                  <Container>
                    <SectionFixed>
                      <Box className={borderBot}>
                        <div className="flex items-center gap-2 p-2 px-4 text-xxs">
                          {!hideBreadCrumbs && (
                            <>
                              <p
                                className="m-0 cursor-pointer"
                                onClick={() => handleNavigateBack()}
                              >
                                RFx
                              </p>
                              <span>{'>'}</span>
                            </>
                          )}
                          <p className="m-0 text-sm font-medium">{pdfName}</p>
                        </div>
                        <Box className={titleContainer}>
                          {shareType === 'private' && (
                            <Box className={shareFileIcon}>
                              <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleCloseShareDialog}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                style={{
                                  borderRadius: '8px'
                                }}
                              >
                                <DialogContent
                                  style={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    width: '380px'
                                  }}
                                >
                                  {linkLoading ? (
                                    <Grid
                                      container
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Loader
                                        loading={linkLoading}
                                        caption={
                                          'creating a custom link just for you!'
                                        }
                                      />
                                    </Grid>
                                  ) : (
                                    <>
                                      {shareableLink === '' ? (
                                        <>
                                          {selectedOption === 'custom' && (
                                            <Box
                                              className="text-sm"
                                              style={{
                                                fontSize: '18px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingBottom: '10px'
                                              }}
                                            >
                                              Number of days the link should be
                                              valid -
                                              <TextField
                                                variant="standard"
                                                label=""
                                                type="number"
                                                value={customValue}
                                                onChange={
                                                  handleCustomValueChange
                                                }
                                                style={{
                                                  marginLeft: '8px',
                                                  marginRight: '8px',
                                                  maxWidth: '40px',
                                                  fontSize: '12px'
                                                }}
                                                InputProps={{
                                                  inputProps: {
                                                    style: {
                                                      fontSize: '12px',
                                                      padding: '5px 0px'
                                                    }
                                                  }
                                                }}
                                              />
                                              days
                                            </Box>
                                          )}
                                          <Button2
                                            secondary
                                            onClick={handleCreateShareLink}
                                            style={{ float: 'right' }}
                                          >
                                            Create Link
                                          </Button2>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="text-sm"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <TextField
                                              fullWidth
                                              variant="outlined"
                                              value={shareableLink}
                                              InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                  style: {
                                                    fontSize: '12px',
                                                    padding: '5px 10px'
                                                  }
                                                }
                                              }}
                                              style={{ marginRight: '8px' }}
                                            />
                                            <Button2
                                              secondary
                                              onClick={copyToClipboard}
                                              startIcon={<FileCopyIcon />}
                                              style={{}}
                                            >
                                              {isCopied ? 'Copied!' : 'Copy'}
                                            </Button2>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </DialogContent>
                              </Popover>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </SectionFixed>
                    <Section overFlow>
                      <Viewer
                        plugins={[
                          toolbarPluginInstance,
                          defaultLayoutPluginInstance,
                          pageNavigationPluginInstance,
                          searchPluginInstance
                        ]}
                        fileUrl={url}
                        initialPage={pageNo - 1}
                        defaultScale={SpecialZoomLevel.PageFit}
                        onPageChange={(page) => {
                          setPdfCurrentPage(page?.currentPage)
                        }}
                      />
                    </Section>
                  </Container>
                </Box>
              </Resizable>
              <Box
                className={infoContainer}
                sx={{
                  width: toolbarWidth + 'px',
                  height: '100%'
                }}
              >
                <div className="flex items-center gap-2 p-3 border-b-1">
                  {pdfToolTab.map((tab, index) => (
                    <Button2
                      onClick={() => changeTab(index)}
                      key={index}
                      style={{
                        padding: '4px 8px',
                        fontSize: '11px',
                        borderRadius: '6px',
                        border:
                          activeTab === index
                            ? '1px solid var(--grey-400) !important'
                            : '1px solid var(--grey-200) !important',
                        color:
                          activeTab === index
                            ? 'var(--grey-700)'
                            : 'var(--grey-600)',
                        backgroundColor:
                          activeTab === index ? 'var(--grey-50)' : 'transparent'
                      }}
                    >
                      {tab.label}
                    </Button2>
                  ))}
                  <div
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    {shareType === 'private' && (
                      <Button2
                        secondary
                        className="icon-btn"
                        onClick={handleShareDialog}
                        style={{
                          padding: '7px 8px',
                          fontSize: '10px'
                          // borderRadius: '100px'
                        }}
                      >
                        <ShareIcon className="size-4" />
                      </Button2>
                    )}
                  </div>
                  <MUIToolTip title="Chat with file">
                    <button
                      className="relative rounded-full icon-btn"
                      style={{
                        padding: '6px'
                      }}
                      onClick={(e) => {
                        dispatch(
                          addDocumentToChat(
                            {
                              document_name: PDFData?.document_name,
                              document_type: 'rfx',
                              document_id: PDFData?.id
                            },
                            'rfx-document-preview'
                          )
                        )
                      }}
                    >
                      <div className={classes.iconAiFab}>
                        <SparkIcon2 className="size-4" strokeWidth="1.7" />
                      </div>
                    </button>
                  </MUIToolTip>
                </div>
                <div
                  style={{
                    height: 'calc(100% - 50px)',
                    overflow: 'auto'
                  }}
                >
                  {pdfToolTab[activeTab].children}
                </div>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <Grid container className={page}>
          <Grid item xs md lg className={webviewer}>
            <Container>
              <SectionFixed>
                <Box className={borderBot}>
                  <Box className={titleContainer}>
                    {window?.history?.state && (
                      <Box className={iconButton}>
                        <IconButton onClick={() => setShowPDF(false)}>
                          <SvgIcon className={icon}>
                            <BackImg />
                          </SvgIcon>
                        </IconButton>
                      </Box>
                    )}
                    <Box className={pdfTitle}>{pdfName}</Box>
                  </Box>
                </Box>
              </SectionFixed>
              <Section overFlow>
                <Viewer
                  plugins={[
                    toolbarPluginInstance,
                    defaultLayoutPluginInstance,
                    pageNavigationPluginInstance
                  ]}
                  fileUrl={url}
                  initialPage={pageNo - 1}
                  defaultScale={SpecialZoomLevel.PageFit}
                  onPageChange={(page) => {
                    setPdfCurrentPage(page?.currentPage)
                  }}
                />
              </Section>
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PDFViewer
