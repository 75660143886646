import React, { useEffect, useState, useRef } from 'react'
import {
  getSimilarImages,
  getAsset,
  hideAsset,
  postAssets,
  upscaleAsset,
  replaceAsset,
  deleteMultiAsset
} from '../../store/api'
import {
  Box,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Link,
  CircularProgress,
  Skeleton,
  SvgIcon,
  Divider
} from '@mui/material'
import Annotator from '../../components/react-image-annotate/Annotator'
import moment from 'moment'
import colors from '../../components/react-image-annotate/colors'
import VideoPlayer from '../../components/VideoPlayer'
import {
  Loader,
  Button,
  Dialog,
  InputTags,
  ContentEditable
} from '../../components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SimilarityViewer from '../AssetViewSidePane/SimilarityViewer'
import { Container, Section, SectionFixed } from '../../components/Container'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import axios from 'axios'
import { checkExpiry, extractDimensions } from '../../utils/AWS'
import _, { set } from 'lodash'
import { useStyles } from './assetstyles'
import { toast } from 'react-toastify'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Checkbox from '@mui/material/Checkbox'
import clsx from 'clsx'
import DeleteIcon from '@mui/icons-material/Delete'
import HideImages from '../AssetViewSidePane/HideImages'
import { ReactComponent as UpscaleIcon } from '../../assets/svg/Upscale.svg'
import { ReactCompareSlider } from 'react-compare-slider'
import RefreshIcon from '@mui/icons-material/Refresh'
import TagCenterUpdate from '../TagCenterUpdate'
import CloseIcon from '@mui/icons-material/Close'
import StyleIcon from '@mui/icons-material/Style'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import WebStoriesIcon from '@mui/icons-material/WebStories'
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined'
import TheatersIcon from '@mui/icons-material/Theaters'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import ImageIcon from '@mui/icons-material/Image'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ButtonGroup from '@mui/material/ButtonGroup'

const ShowMoreLess = ({ text }) => {
  const [expanded, setExpanded] = useState(false)
  const { markerText } = useStyles()
  return (
    <Box className={markerText}>
      {expanded ? (
        <p>
          {text}
          <Box
            onClick={() => setExpanded(!expanded)}
            component="span"
            style={{ paddingLeft: '5px', color: 'blue' }}
          >
            show less
          </Box>
        </p>
      ) : text.length > 100 ? (
        <p>
          {text.substring(0, 100)}
          <Box
            onClick={() => setExpanded(!expanded)}
            component="span"
            style={{ paddingLeft: '5px', color: 'blue' }}
          >
            ...show more
          </Box>
        </p>
      ) : (
        <p>{text}</p>
      )}
    </Box>
  )
}

const reformatTime = (time) => {
  const [hours, minutes, seconds, milliseconds] = time.split(':')
  if (hours !== '00') {
    return hours + ':' + minutes + ':' + seconds
  } else {
    return minutes + ':' + seconds
  }
}

const convertToSeconds = (time) => {
  if (time) {
    const [hours, minutes, seconds, milliseconds] = time.split(':')
    return Math.floor(
      parseInt(hours) * 60 * 60 +
        parseInt(minutes) * 60 +
        parseInt(seconds) +
        milliseconds / 100
    )
  }
  return 'N/A'
}

const Asset = ({
  s3Obj,
  asset,
  playerRef,
  view,
  assetType,
  setAsset = () => {}
}) => {
  const {
    boundingRegions = [],
    boundingRegionsCls = [],
    markers = [],
    filename,
    displayUrl: rawFile
  } = asset
  const classes = useStyles()
  if (assetType?.includes('video')) {
    return (
      rawFile && (
        <VideoPlayer
          playerRef={playerRef}
          url={rawFile}
          type={assetType}
          markers={markers}
        />
      )
    )
  } else {
    return (
      <Container>
        <Section overFlow>
          {view === 'annotator' ? (
            <Annotator
              hideHeader
              labelImages
              regionClsList={boundingRegionsCls}
              images={[
                {
                  src: rawFile,
                  name: filename,
                  regions: boundingRegions
                }
              ]}
            />
          ) : (
            <Box className={classes.assetWrapper}>
              <LazyLoadImage effect="blur" src={rawFile} />
            </Box>
          )}
        </Section>
      </Container>
    )
  }
}

const DeleteButton = ({ data, onDelete = () => {} }) => {
  const [showdialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleAssetDelete = async () => {
    const id = data?.id
    if (id) {
      setLoading(true)
      setShowDialog(false)
      const req = {
        list_of_asset_ids: [id]
      }
      const res = await deleteMultiAsset(req)
      if (res.status === 200) {
        onDelete()
        setShowDialog(false)
        setLoading(false)
        toast.success('Asset deleted successfully.')
      } else {
        toast.error('Failed to delete asset.')
      }
    }
  }

  return (
    <>
      <Tooltip title={'Delete Asset'}>
        <IconButton
          disableRipple
          disabled={loading}
          onClick={() => setShowDialog(true)}
        >
          {loading ? <CircularProgress /> : <DeleteIcon />}
        </IconButton>
      </Tooltip>
      <Dialog
        open={showdialog}
        handleSubmit={handleAssetDelete}
        handleClose={() => setShowDialog(false)}
        dialogTitle={'Are you sure you want to delete this asset?'}
        submitButtonText={'Yes, delete it'}
      />
    </>
  )
}

const HideButton = ({
  data = {},
  signedImages = [],
  setData = () => {},
  onDeleteCallback = () => {}
}) => {
  const [showdialog, setShowDialog] = useState(false)
  const [checked, setChecked] = useState(false)
  const [hidden, setHidden] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data && ![true, false].includes(hidden)) {
      setHidden(data?.hidden_from_search)
    }
  }, [data])

  const handleSubmit = async () => {
    setLoading(true)
    setShowDialog(false)
    const ids = [data?.id]
    if (checked) {
      signedImages.forEach((image) => {
        ids.push(image?.id)
      })
    }

    const res = await hideAsset({ asset_id: ids, hide_from_search: !hidden })
    if (res.status === 200) {
      setData({ ...data, hidden_from_search: !hidden })
      setHidden(!hidden)
      setLoading(false)
      onDeleteCallback(ids)
      toast.success(`Asset ${!hidden ? 'hidden' : 'unhidden'} successfully.`)
    } else {
      toast.error('Failed to hide asset.')
    }
  }

  return (
    <>
      <Tooltip
        title={hidden ? 'Show asset on search' : 'Hide asset from search'}
      >
        <IconButton
          disableRipple
          disabled={loading}
          onClick={() => setShowDialog(true)}
        >
          {loading ? (
            <CircularProgress />
          ) : hidden ? (
            <VisibilityIcon />
          ) : (
            <VisibilityOffIcon />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={showdialog}
        handleSubmit={handleSubmit}
        handleClose={() => setShowDialog(false)}
        dialogTitle={`Are you sure you want to ${
          hidden ? 'show' : 'hide'
        } this asset?`}
        submitButtonText={`Yes, ${hidden ? 'show' : 'hide'} it`}
        dialogContent={
          signedImages?.length > 0 && (
            <Box onClick={() => setChecked(!checked)}>
              <Checkbox checked={checked} />
              {hidden ? 'Show' : 'Hide'} Similar Assets
            </Box>
          )
        }
      />
    </>
  )
}

const AssetViewer = (props) => {
  const {
    id,
    s3Obj = {},
    src,
    assetData,
    searchKey = '',
    isSuperUser = false,
    isAdmin = false,
    onClose = () => {},
    onDeleteCallback = () => {},
    setFile = () => {},
    activeCollection = null,
    onUpdateCallback = () => {}
  } = props

  const [asset, setAsset] = useState({})
  const [loading, setLoading] = useState(true)
  const { asset_type } = assetData
  const [similarImages, setSimilarImages] = useState([])
  const [similarImagesAspectRatios, setSimilarImagesAspectRatios] = useState([])
  const playerRef = useRef(null)
  const [loadingSimilarImages, setLoadingSimilarImages] = useState(true)
  const [showHideModel, setShowHideModel] = useState(false)
  const [listWidth, setListWidth] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const { width, height, ref } = useResizeDetector()
  const [sidePane, setSidePane] = useState('')
  const [view, setView] = useState('image')

  useEffect(() => {
    if (width > 900) {
      handleOnResize()
    } else if (!listWidth) {
      setListWidth(350)
    } else {
      setListWidth(listWidth)
    }
  }, [width])

  const handleOnResize = (event, obj) => {
    if (obj) {
      if (obj.size && obj.size.width < width - 500 && obj.size.width > 350) {
        setListWidth(obj.size.width)
      }
    } else {
      setListWidth(350)
    }
  }

  const CustomResizeHandle = React.forwardRef((props, ref2) => {
    const { handleAxis, ...restProps } = props
    return (
      sidePane && (
        <Box
          className={classes.customResizeHandler}
          style={{ background: isResizing ? '#cdcdcd' : 'inherit' }}
          ref={ref2}
          {...restProps}
        >
          <span
            className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
          ></span>
        </Box>
      )
    )
  })

  const toggleSidePane = (pane) => {
    if (pane === sidePane) {
      setSidePane('')
    } else {
      setSidePane(pane)
    }
    if (pane === 'imagetools') {
      setView('image')
    }
  }

  const [upscaleAbortController, setUpscaleAbortController] = useState(null)

  const handleReplaceUpscaled = async () => {
    if (upscaleAbortController) {
      upscaleAbortController.abort()
    }
    const newController = new AbortController()
    setUpscaleAbortController(newController)
    const { id, fileSource, aiTools } = asset
    const { upscaling } = aiTools || {}
    const {
      upscaled_image_url,
      signedUpscaled,
      signedOrignal,
      original_image_url,
      isUsingUpscaled
    } = upscaling || {}
    const setUpscaled = !isUsingUpscaled
    const req = {
      image_id: id,
      image_type: fileSource === 'asset' ? 'asset' : 'image',
      upscaled_image_url,
      revert: !setUpscaled
    }
    const prevData = { ...asset }
    let newDisplayUrl = ''
    if (setUpscaled) {
      if (signedUpscaled) {
        newDisplayUrl = signedUpscaled
      } else {
        newDisplayUrl = await checkExpiry(upscaled_image_url, s3Obj)
      }
    } else {
      if (signedOrignal) {
        newDisplayUrl = signedOrignal
      } else {
        newDisplayUrl = await checkExpiry(original_image_url, s3Obj)
      }
    }
    setAsset({
      ...asset,
      aiTools: {
        ...asset.aiTools,
        upscaling: {
          ...upscaling,
          isUsingUpscaled: setUpscaled
        }
      },
      displayUrl: newDisplayUrl
    })
    const res = await replaceAsset(req, { signal: newController.signal })
    if (res.code !== 'ERR_CANCELED' && res.status !== 200) {
      toast.error('Failed to replace image.')
      setAsset(prevData)
    }
  }

  const handleUpscaling = async (setUpscaled) => {
    setAsset({
      ...asset,
      aiTools: {
        ...asset.aiTools,
        loading: true
      }
    })
    const { aiTools } = asset || {}
    const { upscaling } = aiTools || {}
    const {
      original_image_url,
      upscaled_image_url,
      signedUpscaled,
      signedOrignal
    } = upscaling
    if (setUpscaled) {
      if (signedUpscaled) {
        setAsset({
          ...asset,
          aiTools: {
            ...asset.aiTools,
            displayUrl: signedUpscaled,
            displayUrlType: 'upscaled',
            loading: false
          }
        })
      } else if (upscaled_image_url) {
        const url = await checkExpiry(upscaled_image_url, s3Obj)
        setAsset({
          ...asset,
          aiTools: {
            ...asset.aiTools,
            upscaling: {
              ...upscaling,
              signedUpscaled: url
            },
            displayUrl: url,
            displayUrlType: 'upscaled',
            loading: false
          }
        })
      } else {
        const { id, fileSource } = asset
        const req = {
          image_id: id,
          image_type: fileSource === 'asset' ? 'asset' : 'image'
        }
        const res = await upscaleAsset(req)
        if (res.status === 200) {
          const { upscaled_image_path } = res.data
          const url = await checkExpiry(upscaled_image_path, s3Obj)
          setAsset({
            ...asset,
            aiTools: {
              ...asset.aiTools,
              upscaling: {
                ...upscaling,
                signedUpscaled: url,
                upscaled_image_url: upscaled_image_path
              },
              displayUrl: url,
              displayUrlType: 'upscaled',
              loading: false
            }
          })
        } else {
          try {
            const text = JSON.parse(res.response.data.data.replace(/'/g, '"'))
              .errors[0]
            // this is to get the number of pixels received in the error message
            const match = text.match(/received\s(\d+)/)
            const number = match ? match[1] : ''

            toast.error(
              number < 1024
                ? 'Image too small to upscale'
                : 'Image too large to upscale'
            )
          } catch (e) {
            toast.error('Image is either small or large to upscale')
          }
        }
      }
    } else {
      if (signedOrignal) {
        setAsset({
          ...asset,
          aiTools: {
            ...asset.aiTools,
            displayUrl: signedOrignal,
            displayUrlType: 'original',
            loading: false
          }
        })
      } else {
        const url = await checkExpiry(original_image_url, s3Obj)
        setAsset({
          ...asset,
          aiTools: {
            ...asset.aiTools,
            upscaling: {
              ...upscaling,
              signedOrignal: url
            },
            displayUrl: url,
            displayUrlType: 'original',
            loading: false
          }
        })
      }
    }
  }

  const renderImageToolBase = () => {
    const { aiTools } = asset || {}
    const { displayUrl, displayUrlType, loading } = aiTools || {}
    return loading ? (
      <Loader
        loading={loading}
        caption={`Loading ${
          displayUrlType !== 'upscaled' ? 'upscaled' : 'original'
        } image`}
        flex
      />
    ) : (
      <Box className={classes.assetWrapper}>
        <LazyLoadImage effect="blur" src={displayUrl} />
      </Box>
    )
  }
  const [expandedAiAccordian, setExpandedAiAccordian] = useState(false)

  const handleChangeAiAccordian = (panel) => (event, isExpanded) => {
    setExpandedAiAccordian(isExpanded ? panel : false)
  }

  const renderSidepane = () => {
    switch (sidePane) {
      case 'info':
        return <Box className={classes.sidepaneWrapper}>{renderDetails()}</Box>
      case 'imagetools': {
        const { aiTools } = asset || {}
        const { upscaling, displayUrlType } = aiTools || {}
        const { isUsingUpscaled, upscaled_image_url } = upscaling
        return (
          <Box className={classes.sidepaneWrapper}>
            <Box className={classes.imageToolsWrapper}>
              <Accordion
                expanded={expandedAiAccordian === 'upscale'}
                onChange={handleChangeAiAccordian('upscale')}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <SvgIcon>
                      <UpscaleIcon />
                    </SvgIcon>
                    Upscale Image
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.imageToolsOptionWrapper}>
                    View Image
                    <ButtonGroup
                      sx={{ width: '100%', justifyContent: 'center' }}
                    >
                      <Button
                        sx={{ marginTop: '10px' }}
                        variant={
                          displayUrlType === 'upscaled'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => handleUpscaling(true)}
                      >
                        {'Upscaled'}
                      </Button>
                      <Button
                        sx={{ marginTop: '10px' }}
                        variant={
                          displayUrlType === 'original'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => handleUpscaling(false)}
                      >
                        {'Original'}
                      </Button>
                    </ButtonGroup>
                  </Box>
                  {(isUsingUpscaled || upscaled_image_url) && (
                    <Box className={classes.imageToolsOptionWrapper}>
                      Set Default Image
                      <ButtonGroup
                        sx={{ width: '100%', justifyContent: 'center' }}
                      >
                        <Button
                          sx={{ marginTop: '10px' }}
                          variant={isUsingUpscaled ? 'contained' : 'outlined'}
                          onClick={() => handleReplaceUpscaled()}
                        >
                          {'Upscaled'}
                        </Button>
                        <Button
                          sx={{ marginTop: '10px' }}
                          variant={!isUsingUpscaled ? 'contained' : 'outlined'}
                          onClick={() => handleReplaceUpscaled()}
                        >
                          {'Original'}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        )
      }
      case 'tags':
        return (
          <Box className={classes.sidepaneWrapper}>
            <TagCenterUpdate
              id={id}
              document_type={
                asset_type
                  ? asset_type.includes('video')
                    ? 'video'
                    : 'image'
                  : 'proposalimages'
              }
              filterTags="document"
              onUpdateCallback={onUpdateCallback}
            />
          </Box>
        )
      case 'similar':
        return (
          <Box
            className={classes.sidepaneWrapper}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start'
            }}
          >
            <SectionFixed>
              <Box sx={{ padding: '15px', fontWeight: 'bold' }}>
                Related Images
              </Box>
            </SectionFixed>
            <Section overFlow>
              {loadingSimilarImages ? (
                <Box sx={{ padding: '10px' }}>
                  <Skeleton variant="rectangular" height={200} />
                </Box>
              ) : similarImages?.length > 0 ? (
                <Box>
                  <SimilarityViewer
                    handleImageClick={(e) => openSimilarImage(e)}
                    signedImages={similarImages || []}
                    aspectRatio={similarImagesAspectRatios || []}
                  />
                </Box>
              ) : (
                <Box sx={{ padding: '10px' }}>No similar images found</Box>
              )}
            </Section>
          </Box>
        )
      case 'frames':
        return <Box className={classes.sidepaneWrapper}>{renderChapters()}</Box>
      case 'superuser':
        return (
          <Box className={classes.sidepaneWrapper}>
            <Box>
              <Button onClick={() => setShowHideModel(true)}>
                Show all similar images
              </Button>
            </Box>
          </Box>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    async function initalizeData() {
      setLoadingSimilarImages(true)
      if (id && src && s3Obj && ['image', 'proposal image'].includes(src)) {
        const similarityType = src === 'image' ? 'asset' : 'image'
        const req = {
          id,
          content_type: similarityType
        }
        const res = await getSimilarImages(req, source.token)
        if (res.status === 200) {
          const responseImages = res?.data?.data
          const aspectArray = []
          const signedImages = []
          await Promise.all(
            responseImages?.map(async (img, index) => {
              const { asset_id, content_id } = img
              if (!(id === asset_id || id === content_id)) {
                const url = img?.image_thumbnail_url
                  ? img?.image_thumbnail_url
                  : img?.image_url
                const signedSrc = await checkExpiry(url, s3Obj)
                const signedOrignal = await checkExpiry(
                  img?.image_url ? img?.image_url : img?.image_thumbnail_url,
                  s3Obj
                )
                const image_data = await extractDimensions(
                  img,
                  signedSrc,
                  'image'
                )
                const { width = 215, height = 145 } = image_data
                let aspectRatio = width / height
                aspectRatio = aspectRatio || 1
                aspectArray.push(aspectRatio)
                signedImages.push({
                  ...img,
                  signedSrc,
                  signedOrignal,
                  id: asset_id || content_id
                })
              }
            })
          )
          setSimilarImagesAspectRatios(aspectArray)
          setSimilarImages(signedImages)
        }
      }
      setLoadingSimilarImages(false)
    }
    if (id && s3Obj && !_.isEmpty(s3Obj)) {
      initalizeData()
    }
    return () => {
      source.cancel('Request canceled')
    }
  }, [id, s3Obj, src])

  const fetchAsset = async (id) => {
    setLoading(true)
    const res = await getAsset(id)
    if (res.status === 200) {
      const { data } = res
      processData(data)
    }
  }

  const processData = async (data) => {
    let assetData = {
      assetType: asset_type
    }
    if (src === 'proposal image') {
      const {
        src,
        location,
        name,
        metadata_,
        hidden_from_search,
        proposal_id,
        created_by_user,
        created_at,
        page_number,
        proposal_name,
        proposal_tags
      } = data || {}
      const {
        caption = '',
        scanned_text = '',
        labels = '',
        metadata: imageMetaData = {},
        image_url = '',
        identified_as = '',
        upscaled_image_url = '',
        original_image_url = '',
        location_origin
      } = metadata_ || {}
      const displayUrl = await checkExpiry(image_url, s3Obj)

      assetData = {
        ...assetData,
        editableData: {
          name,
          caption,
          scanned_text
        },
        fileMetadata: imageMetaData,
        infoData: {
          proposal: {
            proposal_name,
            proposal_id,
            page_number,
            proposal_tags
          },
          identified_as,
          labels,
          uploaded_on: moment(created_at).format('ll'),
          uploaded_by: created_by_user
        },
        fileSource: src,
        hidden_from_search,
        id,
        aiTools: {
          upscaling: {
            upscaled_image_url,
            original_image_url: original_image_url || image_url,
            locationOrigin: location_origin,
            isUsingUpscaled: location_origin === 'upscaled',
            signedOrignal: location_origin === 'upscaled' ? '' : displayUrl,
            signedUpscaled: location_origin === 'upscaled' ? displayUrl : ''
          },
          displayUrl,
          displayUrlType:
            location_origin === 'upscaled' ? 'upscaled' : 'original'
        },
        displayUrl
      }
    } else {
      if (asset_type?.includes('video')) {
        const {
          analysed_data,
          location,
          name,
          tags,
          metadata_,
          hidden_from_search,
          src,
          created_by_user,
          created_at
        } = data || {}
        const { caption = '' } = metadata_ || {}
        const displayUrl = await checkExpiry(location, s3Obj)
        const markers = []
        let allLabels = []
        analysed_data &&
          analysed_data.forEach((el, idx) => {
            const { labels, texts, start_time } = el
            let highlight = false
            if (searchKey) {
              let keyword = searchKey.toLowerCase()
              keyword = keyword.split(' ')
              let textTags = [...texts, ...labels]
              textTags = textTags.toString().toLowerCase()
              for (const key of keyword) {
                if (!highlight && searchKey && textTags.includes(key)) {
                  highlight = true
                  break
                }
              }
            }
            allLabels = [...allLabels, ...labels]
            markers.push({
              index: idx,
              time: highlight ? convertToSeconds(start_time) : 'N/A',
              formatted_time: reformatTime(start_time.replace(';', ':')),
              text: _.capitalize(texts.toString()),
              label: _.capitalize(labels.toString()),
              start_time: start_time.replace(';', ':'),
              highlight
            })
          })
        allLabels = _.uniq(allLabels)
        assetData = {
          ...assetData,
          filename: name,
          markers,
          fileMetadata: metadata_,
          editableData: {
            tags,
            caption,
            name
          },
          infoData: {
            uploaded_on: moment(created_at).format('ll'),
            uploaded_by: created_by_user,
            labels: allLabels.toString()
          },
          hidden_from_search,
          fileSource: src,
          id,
          displayUrl
        }
      } else {
        const {
          name,
          location,
          analysed_data,
          tags,
          metadata_,
          hidden_from_search,
          src,
          created_by_user,
          created_at
        } = data || {}
        const {
          labels_bounding = [],
          caption = '',
          scanned_text = '',
          labels,
          texts = ''
        } = analysed_data?.[0] || {}
        const {
          upscaled_image_url = '',
          original_image_url = '',
          location_origin = ''
        } = metadata_ || {}
        const regions = []
        const displayUrl = await checkExpiry(location, s3Obj)
        let regionsCls = []
        const colorIndex = {}
        labels_bounding.forEach((obj, index) => {
          regionsCls.push(obj[0])
        })
        regionsCls = _.uniq(_.compact(regionsCls))
        regionsCls.forEach((reg, index) => {
          colorIndex[reg] = colors[index % colors.length]
        })
        labels_bounding.forEach((obj, index) => {
          const newObj = {
            cls: obj[0],
            color: colorIndex[obj[0]],
            editingLabels: false,
            type: 'box',
            id: index,
            h: obj[1][2],
            w: obj[1][3],
            x: obj[1][1],
            y: obj[1][0]
          }
          regions.push(newObj)
        })
        assetData = {
          ...assetData,
          filename: name,
          boundingRegions: regions,
          boundingRegionsCls: regionsCls,
          fileMetadata: metadata_,
          editableData: {
            tags,
            name,
            caption,
            texts
          },
          infoData: {
            labels,
            uploaded_on: moment(created_at).format('ll'),
            uploaded_by: created_by_user
          },
          aiTools: {
            upscaling: {
              upscaled_image_url,
              original_image_url: original_image_url || location,
              locationOrigin: location_origin,
              isUsingUpscaled: location_origin === 'upscaled',
              signedOrignal: location_origin === 'upscaled' ? '' : displayUrl,
              signedUpscaled: location_origin === 'upscaled' ? displayUrl : ''
            },
            displayUrl,
            displayUrlType:
              location_origin === 'upscaled' ? 'upscaled' : 'original'
          },
          hidden_from_search,
          fileSource: src,
          id,
          displayUrl
        }
      }
    }

    setAsset(assetData)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchAsset(id)
    }
  }, [id])

  const handleSave = async (value, key, initialValue) => {
    if (initialValue === value) {
      return
    }
    const { id, fileSource, assetType } = asset
    const saveData = {
      id,
      src: fileSource,
      type: assetType?.includes('video') ? 'video' : 'image',
      [key]: value
    }
    if (key === 'name') {
      onUpdateCallback(id, 'loading', true)
    }
    const res = await postAssets(saveData)
    if (res.status === 200) {
      toast.success('Asset Updated Successfully')
      if (key === 'name') {
        onUpdateCallback(id, 'file_name', value)
      }
    } else {
      toast.error('Failed to update asset.')
    }
    if (key === 'name') {
      onUpdateCallback(id, 'loading', false)
    }
  }

  const OpenProposalPdf = (proposal) => {
    const { proposal_id, page_number = 0 } = proposal
    const newPath = `/view/pdf/${proposal_id}/${page_number}`
    window.open(newPath, '_blank')
  }

  const handleChangeValue = (value, type) => {
    setAsset({
      ...asset,
      editableData: {
        ...asset.editableData,
        [type]: value
      }
    })
  }

  const classes = useStyles()

  const {
    infoData,
    dataclass,
    keyclass,
    timeStampContainer,
    markerText,
    dataContainer,
    searchWrapper,
    chapterDataContainer,
    markerKeyTime,
    markerTime,
    markerKeyText,
    listPointer,
    liPointer,
    markerLabel
  } = classes

  const renderDetails = () => {
    const {
      src,
      infoData: assetInfoData = {},
      editableData: assetEditableData = {}
    } = asset
    const keysToReplace = {
      texts: 'Scanned Text'
    }

    console.log('assetEditableData', assetEditableData, assetInfoData)
    return (
      <Box className={infoData}>
        {Object.keys(assetEditableData || {}).map(
          (key, indexData) =>
            key !== 'tags' && (
              <Box key={indexData} className={dataContainer}>
                <p className="m-0 text-xs text-grey-600">
                  {keysToReplace?.[key] || _.startCase(key)}
                </p>
                <ContentEditable
                  className={dataclass}
                  onBlur={(e) =>
                    handleSave(
                      e.currentTarget.innerText,
                      key,
                      assetEditableData?.[key]
                    )
                  }
                  onChange={(e) =>
                    handleChangeValue(e.currentTarget.innerText, key)
                  }
                  html={
                    assetEditableData?.[key] ? assetEditableData?.[key] : '-'
                  }
                />
              </Box>
            )
        )}
        {Object.keys(assetInfoData || {}).map((key, indexData) =>
          key === 'proposal' ? (
            <>
              {assetInfoData?.[key].proposal_id && (
                <Box key={indexData} className={dataContainer}>
                  <Box className={classes.readOnlyContent}>
                    {_.startCase(key)}
                  </Box>
                  <Box
                    className={classes.readOnlyContent}
                    sx={{
                      cursor: 'pointer',
                      '& :hover': { textDecoration: 'underline' }
                    }}
                    onClick={() => OpenProposalPdf(assetInfoData[key])}
                  >
                    <Box component={'span'}>
                      <Box component={'span'}>
                        {assetInfoData?.[key].proposal_name}
                      </Box>
                      {assetInfoData?.[key].page_number && (
                        <Box component={'span'}>
                          &nbsp;(Page {assetInfoData?.[key].page_number})
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          ) : key === 'labels' ? (
            <InputTags
              description={'Labels'}
              tags={assetInfoData?.[key]?.split(',')}
              showTagOnly={true}
              showLabel
              label={'Labels'}
              labelStyle={keyclass}
            />
          ) : (
            <Box key={indexData} className={dataContainer}>
              <Box className={classes.readOnlyContent}>{_.startCase(key)}</Box>
              <Box className={classes.readOnlyContent}>
                {assetInfoData?.[key] ? assetInfoData?.[key] : '-'}
              </Box>
            </Box>
          )
        )}
      </Box>
    )
  }

  const handleVideoSeek = (mark) => {
    const player = playerRef.current
    const [hours, minutes, seconds, milliseconds] = mark.start_time.split(':')
    const seek_seconds =
      parseInt(hours) * 60 * 60 +
      parseInt(minutes) * 60 +
      parseInt(seconds) +
      milliseconds / 100
    player.currentTime(seek_seconds)
  }

  const renderChapters = () => {
    const { markers = [] } = asset
    return (
      markers.length > 0 && (
        <Box className={timeStampContainer}>
          <Box sx={{ fontWeight: 'bold', marginBottom: '10px' }}>Timeline</Box>
          {markers.map((mark, idx) => {
            let { formatted_time, text, label, highlight, index } = mark
            label = label.replaceAll(',', ' ')
            text = text.replaceAll(',', ' ')
            let markerWrapper = chapterDataContainer
            if (highlight) {
              markerWrapper = clsx(searchWrapper, chapterDataContainer)
            }
            return (
              <Box className={markerWrapper} key={idx} id={'chapter_' + index}>
                <Box className={markerKeyTime}>
                  <Link
                    underline="none"
                    className={markerTime}
                    onClick={(e) => handleVideoSeek(mark)}
                  >
                    {formatted_time}
                  </Link>
                </Box>
                <Box className={markerKeyText}>
                  <ul className={listPointer}>
                    <li
                      className={liPointer}
                      onClick={() => handleVideoSeek(mark)}
                    >
                      <Box className={markerLabel}>{_.startCase(label)}</Box>
                    </li>
                    <ShowMoreLess text={_.startCase(text)} />
                  </ul>
                </Box>
              </Box>
            )
          })}
        </Box>
      )
    )
  }

  const openSimilarImage = (image) => {
    const {
      id,
      image_name,
      asset_id,
      image_thumbnail_url,
      image_url,
      created_by_user,
      created_at
    } = image
    const obj = {
      id,
      file_type: asset_id ? 'asset' : 'proposal image',
      file_name: image_name,
      created_at,
      created_by_user,
      other_data: {
        raw_location: image_url,
        thumbnail_location: image_thumbnail_url,
        asset_type: 'image'
      }
    }
    setFile(obj)
  }

  const { name = '' } = asset?.editableData || {}

  const handleToggleView = () => {
    if (sidePane === 'imagetools' && view === 'image') {
      setSidePane('')
    }
    setView(view === 'annotator' ? 'image' : 'annotator')
  }

  const handleDownload = async () => {
    console.log('asset - ', asset)
    const download_location =
      asset?.aiTools?.upscaling?.original_image_url || asset?.displayUrl
    const file_name = asset?.editableData?.name || asset?.filename
    const file_extension = download_location.split('.').pop()

    let updatedFileName = file_name

    if (file_extension) {
      if (!file_name.endsWith(`.${file_extension}`)) {
        updatedFileName = `${file_name}.${file_extension}`
      }
    }
    if (download_location && updatedFileName) {
      initalizeDownload(download_location, updatedFileName, s3Obj)
    }
  }

  return loading ? (
    <Loader
      loading={loading}
      caption={
        asset_type?.includes('video')
          ? 'opening your video'
          : 'opening your image'
      }
      flex
    />
  ) : (
    <Container parentref={ref}>
      <Box className={classes.pdfContainer}>
        <SectionFixed className={classes.headerContainer}>
          <Box className={classes.editableHeader}>
            <Box>
              {name}
              <Tooltip title="Download">
                <IconButton disableRipple onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {activeCollection?.serverType === 'collection' && (
              <Box className={classes.collectionName}>
                {activeCollection?.name}
              </Box>
            )}
          </Box>
          {onClose && (
            <Box className={classes.closeIconWrapper}>
              <Tooltip title="Close">
                <IconButton disableRipple onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </SectionFixed>
        <Section overFlow={true}>
          <Box className={classes.containerDiv}>
            <Resizable
              width={listWidth}
              axis="x"
              onResize={handleOnResize}
              resizeHandles={['w']}
              onResizeStart={() => {
                setIsResizing(true)
              }}
              onResizeStop={() => {
                setIsResizing(false)
              }}
              handle={<CustomResizeHandle />}
            >
              <Box
                sx={{
                  width: sidePane ? `calc(100% - ${listWidth + 50}px)` : '100%'
                }}
                className={classes.mainContainer}
              >
                {sidePane === 'imagetools' ? (
                  renderImageToolBase()
                ) : (
                  <Asset
                    asset={asset}
                    assetType={asset_type}
                    playerRef={playerRef}
                    setAsset={setAsset}
                    s3Obj={s3Obj}
                    view={view}
                  />
                )}
              </Box>
            </Resizable>
            {sidePane && (
              <Box
                className={classes.sidePaneContainer}
                style={{
                  width: listWidth - 1 + 'px'
                }}
              >
                <Box sx={{ height: '100%', width: '100%' }}>
                  <Container>
                    <Section overFlow={true}>{renderSidepane()}</Section>
                  </Container>
                </Box>
              </Box>
            )}
            <Box className={classes.sidepane}>
              <Box className={classes.sidepaneIconGroup}>
                <Tooltip title="Info">
                  <IconButton
                    disableRipple
                    onClick={() => toggleSidePane('info')}
                  >
                    {sidePane === 'info' ? <InfoIcon /> : <InfoOutlinedIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Tags">
                  <IconButton
                    disableRipple
                    onClick={() => toggleSidePane('tags')}
                  >
                    {sidePane === 'tags' ? (
                      <StyleIcon />
                    ) : (
                      <StyleOutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {asset_type?.includes('video') ? (
                  <Tooltip title="Timeline">
                    <IconButton
                      disableRipple
                      onClick={() => toggleSidePane('frames')}
                    >
                      {sidePane === 'frames' ? (
                        <TheatersIcon />
                      ) : (
                        <TheatersOutlinedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title="Similar Images">
                      <IconButton
                        disableRipple
                        onClick={() => toggleSidePane('similar')}
                      >
                        {sidePane === 'similar' ? (
                          <WebStoriesIcon />
                        ) : (
                          <WebStoriesOutlinedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Image Tools">
                      <IconButton
                        disableRipple
                        onClick={() => toggleSidePane('imagetools')}
                      >
                        {sidePane === 'imagetools' ? (
                          <AutoFixHighIcon />
                        ) : (
                          <AutoFixHighOutlinedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    {isSuperUser && (
                      <Tooltip title="Superuser">
                        <IconButton
                          disableRipple
                          onClick={() => toggleSidePane('superuser')}
                        >
                          {sidePane === 'superuser' ? (
                            <LocalPoliceIcon />
                          ) : (
                            <LocalPoliceOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {asset_type?.includes('image') && (
                  <Tooltip
                    title={
                      view === 'annotator' ? 'View Image' : 'View Annotator'
                    }
                  >
                    <IconButton
                      disableRipple
                      onClick={() => handleToggleView()}
                    >
                      {view === 'annotator' ? (
                        <ImageIcon />
                      ) : (
                        <PhotoSizeSelectLargeIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {sidePane && (
                  <Tooltip title="Close SidePane">
                    <IconButton disableRipple onClick={() => setSidePane('')}>
                      <KeyboardTabIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </Section>
      </Box>
      <HideImages
        show={showHideModel}
        onClose={() => setShowHideModel(false)}
        parentImage={asset || {}}
        s3Obj={s3Obj}
      />
    </Container>
  )
}

export default AssetViewer
